import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductsStoreAddress } from '../../types/types';

export interface StoreState {
  storeToEdit: ProductsStoreAddress | null;
}

const initialState: StoreState = {
  storeToEdit: null,
};

const StoreSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreToEdit: (state, action: PayloadAction<ProductsStoreAddress | null>) => {
      state.storeToEdit = action.payload;
    },
  },
});

export const { setStoreToEdit } = StoreSlice.actions;
export default StoreSlice.reducer;

import './AdminProduct.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { addChoosenStore } from '../../../../general/redux/reducers/Product-Reducer';
import { ProductCategoriesService } from '../../../service/produt-services/ProductsCategoriesService';
import { ProductService } from '../../../service/produt-services/ProductService';
import AdditionalCharacteristic from './AdditionalCharacteristics';
import Batch from './batch/Batch';
import UploadImages from './drag&drop/UploadImages';
import MainCharacteristics from './main-characteristics/MainCharacteristics';
import MarketSelector from './MarketSelector';

const AdminProduct = ({ isEdit }) => {
  const stores = useSelector((state) => state.product.choosenStores);
  const local = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isPreloader, setIsPreloader] = useState(false);

  // Categories
  const [categories, setCategories] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  //Batch
  const [date, onChange] = useState(new Date());

  const getCategories = async () => {
    const response = await ProductCategoriesService.getProductsCategories();
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    } else {
      setCategories(response);
    }
  };

  const [isEditProduct, setIsEditProduct] = useState({ id: '' });

  const changecategory = (e) => {
    setCurrentCategory(categories.find((item) => item.id === e.target.value));
  };

  const getEditProduct = async () => {
    const categoryList = await ProductCategoriesService.getProductsCategories();
    if (categoryList.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: categoryList.message,
          r: categoryList.hasOwnProperty('response')
            ? {
                d: categoryList.response.data,
                s: categoryList.response.statusText,
              }
            : '',
        }),
      );
    } else {
      setCategories(categoryList);
    }

    const response = await ProductService.getEditProduct(id);

    response.stores.forEach((store) => {
      if (!stores.some((chosenStore) => chosenStore.id === store.id)) {
        dispatch(addChoosenStore(store));
      }
    });

    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    } else {
      setIsEditProduct(response);
      setCurrentCategory(categoryList.find((item) => item.id === response.categoryId));
      onChange(response?.batch);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getEditProduct();
    } else {
      getCategories();
    }
  }, [local.key]);

  // UPDATE PRODUCT
  const filesArray = [];
  const dragedImgs = [];

  const changeInAdditionalCharacteristics = [];
  const removedAdditionalCharacteristics = [];

  const removedImages = [];

  const updateImages = async () => {
    //Delete

    if (removedImages.length > 0) {
      removedImages.forEach((id) => {
        ProductService.deleteProductImg(id);
      });
    }

    const updatedImagesArray = filesArray.map((image) => {
      // Найти targetIndex для текущего url
      const found = dragedImgs.find((item) => item.goal === image.url);
      // Если targetIndex найден, обновить order, иначе оставить текущий order
      return {
        id: image.id,
        order: found ? found.targetIndex + 1 : image.order,
      };
    });

    const newUpdatedImagesArray = updatedImagesArray.sort((a, b) => a.order - b.order);

    if (updatedImagesArray) {
      await ProductService.updateImagesOredr({
        productId: id,
        images: newUpdatedImagesArray,
      });
    }

    if (filesArray.length > 0) {
      const formData = new FormData();

      filesArray.forEach((file, index) => {
        formData.append(`Images`, file);
      });

      await ProductService.postImagesProduct(id, formData);
    }
  };

  const updateAdditionalCharacteristics = async () => {
    const ArraylCharacteristics = document.querySelectorAll(
      '.additional-characteristic__block__input-block',
    );

    // Change

    ArraylCharacteristics.forEach((block) => {
      if (changeInAdditionalCharacteristics.indexOf(block.dataset.id) !== -1) {
        // Change
        const inputs = block.querySelectorAll('input');
        ProductService.putProductCharacteristics({
          id: block.dataset.id,
          productId: id,
          name: inputs[1].value,
          value: inputs[2].value,
          order: inputs[0].value,
        });
      } else if (!isEditProduct?.characteristics?.some((item) => item.id === block.dataset.id)) {
        // Add new
        const inputs = block.querySelectorAll('input');
        ProductService.postAdditionalCharacteristic({
          productId: id,
          name: inputs[1].value,
          value: inputs[2].value,
          order: inputs[0].value,
        });
      }
    });

    // Delete
    if (removedAdditionalCharacteristics.length > 0) {
      removedAdditionalCharacteristics.forEach((id) => {
        ProductService.deleteProductCharacteristics(id);
      });
    }
  };

  const updateMainMiningCharacteristics = () => {
    const characteristicBlock = document.querySelector(
      '.page-section__characteristics__characteristic',
    );
    const characteristicInputs = characteristicBlock.querySelectorAll('input');
    const hasrateSelect = characteristicBlock.querySelector('.hashrate-select');
    const hasrateType = hasrateSelect.options[hasrateSelect.selectedIndex].value;

    const result = {
      productId: id,
      manufacturer: characteristicInputs[0].value,
      condition: characteristicInputs[1].value,
      algorithm: characteristicInputs[2].value,
      hashrate: `${characteristicInputs[3].value} ${hasrateType}`,
      energyConsumption: characteristicInputs[4].value,
      energyEfficiency: characteristicInputs[5].value,
      countryLocation: characteristicInputs[6].value,
      stores: stores.map((store) => store.id),
    };

    return result;
  };

  const updateMainGlobalCharacteristic = async () => {
    const block = document.querySelector('.page-section__characteristics__characteristic');
    const inputs = block.querySelectorAll('input');

    inputs.forEach((input) => {
      const response = ProductService.putProductMainGeneralOfProduct({
        generalMainCharacteristicId: input.dataset.id,
        value: input?.value?.trim(),
      });
      if (response.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: response.message,
            r: Object.prototype.hasOwnProperty.call(response, 'response')
              ? { d: response.response.data, s: response.response.statusText }
              : '',
          }),
        );
      }
    });
  };

  const updateMarket = async () => {
    const market = document.querySelector(
      '.market-payment-status__current-options__name',
    ).innerHTML;

    const result = {
      productId: id,
      isRetail: market === 'Retail' ? true : false,
      isWholesale: market === 'Wholesale' ? true : false,
    };

    await ProductService.putProductMarket(result);
  };

  const updateMainInformation = () => {
    const mainInfoBlock = document.querySelector('.product-content-section__content__body');
    const mainInfoInputs = mainInfoBlock.querySelectorAll("input[name='default']");
    const mainInfoTextarea = mainInfoBlock.querySelector('textarea');

    const isActiveBlock = document.querySelector('.product-content-section__side-bar__active__btn');
    const isActiveBtn = isActiveBlock.querySelector('input[name=status]:checked').value;

    const result = {
      id: id,
      batch: date,
      name: mainInfoInputs[0].value,
      description: mainInfoTextarea.value,
      price: mainInfoInputs[1].value,
      minimalOrderQuantity: mainInfoInputs[3].value,
      rating: mainInfoInputs[5].value,
      amount: mainInfoInputs[4].value,
      isActive: isActiveBtn === 'Active' ? true : false,
      percentageDiscount: mainInfoInputs[2].value,
    };

    return result;
  };

  const updatedProduct = async (e) => {
    setIsPreloader(true);
    let response;
    try {
      const info = updateMainInformation();
      response = await ProductService.putProductMainInformation(info);

      if (currentCategory.areMiningCharacteristics) {
        const characteristics = updateMainMiningCharacteristics();
        response = await ProductService.putProductMainMiningCharacteristics(characteristics);
      } else {
        await updateMainGlobalCharacteristic();
      }

      if (response.name === 'AxiosError') {
        throw new Error();
      }

      await updateMarket();
      await updateAdditionalCharacteristics();
      await updateImages();
      navigate('/admin/products');
    } catch (error) {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    }

    setIsPreloader(false);
  };

  // CREATE PRODUCT

  const loadMainGlobalCharacteristic = (id) => {
    const block = document.querySelector('.page-section__characteristics__characteristic');
    const inputs = block.querySelectorAll('input');

    const arr = [];

    inputs.forEach((input) => {
      arr.push({
        mainGeneralCharacteristicDefinitionId: input.dataset.id,
        value: input.value.trim(),
      });
    });

    return arr;
  };

  const createdefaultJSONProduct = () => {
    const mainInfoBlock = document.querySelector('.product-content-section__content__body');
    const mainInfoInputs = mainInfoBlock.querySelectorAll("input[name='default']");
    const mainInfoTextarea = mainInfoBlock.querySelector('textarea');
    // const mainInfoSelect = mainInfoBlock.querySelector('select');
    // const price = mainInfoInputs[1].value - (mainInfoInputs[1].value * (mainInfoInputs[2].value / 100))

    const isActiveBlock = document.querySelector('.product-content-section__side-bar__active__btn');
    const isActiveBtn = isActiveBlock.querySelector('input[name=status]:checked').value;

    const market = document.querySelector(
      '.market-payment-status__current-options__name',
    ).innerHTML;

    if (currentCategory.areMiningCharacteristics) {
      const characteristicBlock = document.querySelector(
        '.page-section__characteristics__characteristic',
      );
      const characteristicInputs = characteristicBlock.querySelectorAll('input');
      const hasrateSelect = characteristicBlock.querySelector('.hashrate-select');
      const hasrateType = hasrateSelect.options[hasrateSelect.selectedIndex].value;

      const result = {
        name: mainInfoInputs[0].value,
        batch: date,
        description: mainInfoTextarea.value,
        price: mainInfoInputs[1].value,
        minimalOrderQuantity: mainInfoInputs[3].value,
        amount: mainInfoInputs[4].value,
        manufacturer: characteristicInputs[0].value,
        condition: characteristicInputs[1].value,
        algorithm: characteristicInputs[2].value,
        hashrate: `${characteristicInputs[3].value} ${hasrateType}`,
        energyConsumption: characteristicInputs[4].value,
        energyEfficiency: characteristicInputs[5].value,
        countryLocation: characteristicInputs[6].value,
        isRetail: market === 'Retail' ? true : false,
        isWholesale: market === 'Wholesale' ? true : false,
        isActive: isActiveBtn === 'Active' ? true : false,
        percentageDiscount: mainInfoInputs[2].value,
        rating: mainInfoInputs[5].value,
        categoryId: currentCategory.id,
        mainGeneralCharacteristics: null,
      };

      return result;
    } else {
      const result = {
        name: mainInfoInputs[0].value,
        batch: date,
        description: mainInfoTextarea.value,
        price: mainInfoInputs[1].value,
        minimalOrderQuantity: mainInfoInputs[3].value,
        amount: mainInfoInputs[4].value,
        manufacturer: null,
        condition: null,
        algorithm: null,
        hashrate: null,
        energyConsumption: null,
        energyEfficiency: null,
        countryLocation: null,
        isRetail: market === 'Retail' ? true : false,
        isWholesale: market === 'Wholesale' ? true : false,
        isActive: isActiveBtn === 'Active' ? true : false,
        percentageDiscount: mainInfoInputs[2].value,
        rating: mainInfoInputs[5].value,
        categoryId: currentCategory.id,
        mainGeneralCharacteristics: loadMainGlobalCharacteristic(),
      };

      return result;
    }
  };

  const loadAdditionalCharacteristicProduct = async (id) => {
    const ArraylCharacteristics = document.querySelectorAll(
      '.additional-characteristic__block__input-block',
    );
    ArraylCharacteristics.forEach((block) => {
      const inputs = block.querySelectorAll('input');

      ProductService.postAdditionalCharacteristic({
        productId: id,
        name: inputs[1].value,
        value: inputs[2].value,
        order: inputs[0].value,
      });
    });
  };

  const loadImagesProduct = async (id) => {
    const formData = new FormData();

    filesArray.forEach((file, index) => {
      formData.append(`Images`, file);
    });

    await ProductService.postImagesProduct(id, formData);
  };

  const createProuct = async (e) => {
    createdefaultJSONProduct();
    const response = await ProductService.postProduct(createdefaultJSONProduct());
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    } else {
      await loadAdditionalCharacteristicProduct(response);
      await loadImagesProduct(response);
      navigate('/admin/products');
    }
  };

  const copyProduct = async (e) => {
    setIsPreloader(true);
    const response = await ProductService.postCopyProducts(isEditProduct?.id);
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    }
    setIsPreloader(false);
    setIsEditProduct({ id: '' });
    navigate(`/admin/products/product/${response}`);
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">PRODUCT CREATION</div>\
        {isEdit && (
          <div className="page-section__buttons">
            <button onClick={copyProduct}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
                <path
                  d="M7.63989 0.859863V16.1399"
                  stroke="#FFF9F9"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
              <span>Copy</span>
            </button>
          </div>
        )}
      </div>
      {isEdit === true && isEditProduct?.id === '' ? (
        <div className="preloader-block">
          <Preloader />
        </div>
      ) : (
        <div className="page-section__content">
          {isPreloader && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          {categories === null ? (
            <div className="preloader-block">
              <Preloader />
            </div>
          ) : (
            <>
              <div className="product-content-section">
                <div className="product-content-section__content__main">
                  <div className="product-content-section__content">
                    <div className="product-content-section__content__header">
                      <span>Main information</span>
                    </div>
                    <div className="product-content-section__content__body">
                      <div className="product-content-section__content__name-batch ">
                        <div className="product-content-section__content__name product__input">
                          <label>
                            Item name <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.name : ''}
                          />
                        </div>
                        <div className="product-content-section__content__batch ">
                          <label>Batch</label>
                          <Batch date={date} onChange={onChange} />
                        </div>
                      </div>
                      <div className="product-content-section__content__description product__input">
                        <label>Description</label>
                        <textarea
                          type="text"
                          defaultValue={isEdit === true ? isEditProduct?.description : ''}
                        />
                      </div>
                      <div className="product-content-section__content__info">
                        <div className="content__info__block product__input ">
                          <label>
                            Price $ <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.price : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Discount % <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.percentageDiscount : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            MOQ <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.minimalOrderQuantity : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Count <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.amount : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Rating <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.rating : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Categories <span>*</span>
                          </label>
                          <div className="content__info__block__select">
                            <select
                              className="content__info__block"
                              defaultValue={isEdit === true ? isEditProduct?.categoryId : ''}
                              onChange={changecategory}
                              disabled={isEdit}
                            >
                              <option value="" disabled hidden data-id="">
                                Select a category
                              </option>
                              {categories.map((category) => (
                                <option value={category.id} data-id={category.id} key={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page-section__characteristics">
                    <MainCharacteristics
                      category={currentCategory}
                      isEdit={isEdit}
                      isEditProduct={isEditProduct}
                    />
                  </div>
                  <div className="page-section__characteristics">
                    <AdditionalCharacteristic
                      characteristics={
                        isEdit === true ? isEditProduct?.characteristics : [{ id: 1 }]
                      }
                      removeArray={isEdit === true ? removedAdditionalCharacteristics : [{ id: 1 }]}
                      changeArray={isEdit === true ? changeInAdditionalCharacteristics : null}
                    />
                  </div>
                </div>
                <div className="product-content-section__side-bar">
                  <div className="product-content-section__side-bar__active">
                    <div className="product-content-section__side-bar__active__title">
                      <span>Status</span>
                    </div>
                    <div className="product-content-section__side-bar__active__btn">
                      <div>
                        <input
                          type="radio"
                          id="product_active"
                          name="status"
                          value="Active"
                          defaultChecked={isEdit === false ? false : isEditProduct?.isActive}
                        />
                        <label htmlFor="product_active">Active</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="product_disabled"
                          name="status"
                          value="Disabled"
                          defaultChecked={isEdit === false ? true : !isEditProduct?.isActive}
                        />
                        <label htmlFor="product_disabled">Disabled</label>
                      </div>
                    </div>
                  </div>
                  <MarketSelector
                    currentStatus={isEdit === false ? '-' : isEditProduct?.isRetail}
                  />
                  <div className="product-content-section__side-bar__image">
                    <div className="product-content-section__side-bar__image-titel">Image</div>
                    <div className="product-content-section__side-bar__image-loaders">
                      <UploadImages
                        filesArray={filesArray}
                        dragedImgs={dragedImgs}
                        editImages={isEdit === true ? isEditProduct?.images : []}
                        removeArray={isEdit === true ? removedImages : []}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-proudct-section">
                <button
                  className="section-btn-gradient"
                  onClick={isEdit === true ? updatedProduct : createProuct}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminProduct;

import React from 'react';

const EnergyEfficiency: React.FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_817_117)">
      <path
        d="M12.7996 3.06589H4.20626C3.40361 3.06589 2.75293 3.71657 2.75293 4.51922V13.1126C2.75293 13.9152 3.40361 14.5659 4.20626 14.5659H12.7996C13.6023 14.5659 14.2529 13.9152 14.2529 13.1126V4.51922C14.2529 3.71657 13.6023 3.06589 12.7996 3.06589Z"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.4133 5.61923H6.58664C5.87972 5.61923 5.30664 6.19231 5.30664 6.89923V10.7259C5.30664 11.4328 5.87972 12.0059 6.58664 12.0059H10.4133C11.1202 12.0059 11.6933 11.4328 11.6933 10.7259V6.89923C11.6933 6.19231 11.1202 5.61923 10.4133 5.61923Z"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path d="M5.30664 1.14589V2.42589" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M8.5 1.14589V2.42589" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M11.6934 1.14589V2.42589" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M5.30664 15.1992V16.4792" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M8.5 15.1992V16.4792" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M11.6934 15.1992V16.4792" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path
        d="M0.833008 12.0059H2.11301"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M0.833008 8.81256H2.11301"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M0.833008 5.61923H2.11301"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path d="M14.8867 12.0059H16.1667" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M14.8867 8.81256H16.1667" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M14.8867 5.61923H16.1667" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_817_117">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.812561)" />
      </clipPath>
    </defs>
  </svg>
);

export default EnergyEfficiency;

import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UsePaginationReturn {
  page: number;
  pageSize: number;
  searchQuery: string;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchQuery: (query: string) => void;
}

const usePagination = (defaultPageSize = 10, uniqueKey = 'default'): UsePaginationReturn => {
  const [searchParams, setSearchParams] = useSearchParams({
    [`page_${uniqueKey}`]: '1',
    [`pageSize_${uniqueKey}`]: defaultPageSize.toString(),
  });

  const page = useMemo(
    () => parseInt(searchParams.get(`page_${uniqueKey}`) || '1', 10),
    [searchParams, uniqueKey],
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.get(`pageSize_${uniqueKey}`) || defaultPageSize.toString(), 10),
    [searchParams, defaultPageSize, uniqueKey],
  );
  const searchQuery = useMemo(
    () => searchParams.get(`search_${uniqueKey}`) || '',
    [searchParams, uniqueKey],
  );

  const setPage = useCallback(
    (newPage: number) => {
      searchParams.set(`page_${uniqueKey}`, newPage.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, uniqueKey],
  );

  const setPageSize = useCallback(
    (newPageSize: number) => {
      searchParams.set(`pageSize_${uniqueKey}`, newPageSize.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, uniqueKey],
  );

  const setSearchQuery = useCallback(
    (query: string) => {
      searchParams.set(`search_${uniqueKey}`, query);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, uniqueKey],
  );

  return { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery };
};

export default usePagination;

import axios, { AxiosInstance } from 'axios';

import { enviroment, enviroments, urls } from '../../StaticData';
import { addAuthInterceptor, addInterceptor } from './axiosConfig';

export const url =
  enviroment === enviroments.DEV_SITE || enviroment === enviroments.PROD
    ? urls.prodOrTestDev
    : urls.dev;

export const adminInstance: AxiosInstance = axios.create({
  baseURL: url,
});

export const clientInstance: AxiosInstance = axios.create({
  baseURL: url,
});

// Сначала добавляем интерцептор для обновления токена
addInterceptor(adminInstance, 'admin');
addInterceptor(clientInstance, 'client');

// Затем добавляем интерцептор для авторизации
addAuthInterceptor(adminInstance, 'a4ad');
addAuthInterceptor(clientInstance, 'clp');

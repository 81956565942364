import './AdminProduct.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';

const markets = ['Retail', 'Wholesale'];

interface MarketSelectorProps {
  currentStatus: string | boolean;
}

const MarketSelector: React.FC<MarketSelectorProps> = ({ currentStatus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(
    currentStatus === '-' ? '-' : currentStatus ? 'Retail' : 'Wholesale',
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Select an option
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Close dropdown on click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="product-content-section__side-bar__market">
      <div className="product-content-section__side-bar__market__title">
        <span>Market</span>
      </div>
      <div className="product-content-section__side-bar__market__btn">
        <div className="market-status" ref={dropdownRef}>
          <div className="market-status-select" onClick={() => setIsOpen(!isOpen)}>
            <div className="market-status__current-options">
              <div className="market-payment-status__current-options__name">{selectedOption}</div>
              <DropDownIcon />
            </div>
          </div>
          {isOpen && (
            <div className="market-status__list-options">
              <ul>
                {markets.map((option, index) => (
                  <li
                    key={index}
                    className="market-status__list-options__option"
                    onClick={() => handleOptionClick(option)}
                    style={{
                      backgroundColor: selectedOption === option ? '#0F1928' : '',
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketSelector;

import React from 'react';

const HomeIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9333 3.37743C17.3923 2.92146 16.7075 2.67139 16 2.67139C15.2925 2.67139 14.6077 2.92146 14.0667 3.37743L5.06667 10.9708C4.73261 11.2523 4.46408 11.6034 4.27984 11.9995C4.09559 12.3956 4.00009 12.8272 4 13.2641V25.6708C4 26.9588 5.04533 28.0041 6.33333 28.0041H10.3333C10.9522 28.0041 11.5457 27.7583 11.9832 27.3207C12.4208 26.8831 12.6667 26.2896 12.6667 25.6708V20.3334C12.6667 19.4268 13.3893 18.6908 14.2893 18.6668H17.7107C18.145 18.6782 18.5577 18.8589 18.8608 19.1702C19.1639 19.4815 19.3335 19.8989 19.3333 20.3334V25.6708C19.3333 26.9588 20.3787 28.0041 21.6667 28.0041H25.6667C26.2855 28.0041 26.879 27.7583 27.3166 27.3207C27.7542 26.8831 28 26.2896 28 25.6708V13.2628C27.9999 12.8259 27.9044 12.3943 27.7202 11.9982C27.5359 11.6021 27.2674 11.251 26.9333 10.9694L17.9333 3.37743Z"
      fill="#25A8CF"
    />
  </svg>
);

export default HomeIcon;

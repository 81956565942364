import React from 'react';

const LinkedInStaffIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0703 18.7V12.48C10.069 12.0405 10.1544 11.6052 10.3217 11.1988C10.489 10.7924 10.7348 10.423 11.045 10.1118C11.3553 9.80059 11.724 9.55368 12.1299 9.3852C12.5357 9.21672 12.9709 9.13 13.4103 9.13C14.2988 9.13 15.1509 9.48295 15.7791 10.1112C16.4074 10.7394 16.7603 11.5915 16.7603 12.48V18.7"
      stroke="#19151E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M6.24023 8.16998V18.7" stroke="#19151E" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M6.24027 7.21999C6.77047 7.21999 7.20027 6.79018 7.20027 6.25999C7.20027 5.72979 6.77047 5.29999 6.24027 5.29999C5.71008 5.29999 5.28027 5.72979 5.28027 6.25999C5.28027 6.79018 5.71008 7.21999 6.24027 7.21999Z"
      fill="#19151E"
    />
    <path d="M10.0703 8.16998V12.96" stroke="#19151E" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default LinkedInStaffIcon;

import './EmptyPage.scss';

import React from 'react';
import { Link } from 'react-router-dom';

const EmptyPage: React.FC = () => {
  return (
    <div className="empty-page page-container">
      <p className="empty-page__404">404</p>
      <p className="empty-page__text">Page Not Found</p>
      <Link to="/">
        <button className="empty-page__btn section-btn-gradient">Back</button>
      </Link>
    </div>
  );
};

export default EmptyPage;

import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const AdminHelpCenter: React.FC = () => {
  return (
    <div className="page-section">
      <div className="page-section__top-tabs">
        <NavLink
          end
          to="/admin/help-center"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          FAQ
        </NavLink>
        <NavLink
          end
          to="/admin/help-center/qa"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Q&A
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminHelpCenter;

import './ShippingAndLocations.scss';

import React from 'react';

import HostingLocations from './hostingLocations/HostingLocations';
import Locations from './prodLocations/ProductsLocations';
import Stores from './productStores/Stores';
import ShippingCost from './shippingCost/ShippingCost';

const ShippingInfo = () => {
  return (
    <div className="page-section__shipping-info ">
      <div className="page-section__top">
        <h1 className="page-section__top__title">Shipping & Location info</h1>
      </div>
      <div className="page-section__content">
        <div className="shipping-price__block">
          <ShippingCost />
        </div>
        <div className="available-location__block">
          <Locations />
        </div>
        <div className="stores">
          <Stores />
        </div>
        <div className="hosting-locations">
          <HostingLocations />
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;

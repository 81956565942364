import React from 'react';

interface TelegramLinkFooterIconProps {
  color: string;
}

const TelegramLinkFooterIcon: React.FC<TelegramLinkFooterIconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 3.42993L2.5 11.0499L18.69 20.5699L22.5 3.42993Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M13.21 17.3399L9.17004 20.5699L8.54004 14.5999L21.55 4.37988"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default TelegramLinkFooterIcon;

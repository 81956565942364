import React from 'react';

const OrdersIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9732 27.4666H9.02656C8.1183 27.4694 7.23867 27.149 6.54494 26.5628C5.85122 25.9765 5.38863 25.1626 5.2399 24.2666L3.22656 12.1333H28.7732L26.7599 24.2666C26.6112 25.1626 26.1486 25.9765 25.4548 26.5628C24.7611 27.149 23.8815 27.4694 22.9732 27.4666Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M31.3332 12.1333H0.666504" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M9.61328 14.6933V12.1333L13.4399 3.19995"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.3867 14.6933V12.1333L18.5601 3.19995"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M16 17.2534V23.6401" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M10.8936 17.2534V23.6401" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M21.1064 17.2534V23.6401" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default OrdersIcon;

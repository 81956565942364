import { adminInstance } from '../../general/services/main/axiosInstances';

export const OrdersServices = {
  // GET
  async getAllOrders(query: string, pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/Order?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getOrder(id: string) {
    try {
      const response = await adminInstance.get(`/Order/byId?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getOrdersCount(status: string) {
    try {
      const response = await adminInstance.get(`/Order/ordersWithStatusCount?Status=${status}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putOrderStatus(data: any) {
    try {
      const response = await adminInstance.put(`/Order/status`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putOrderPaymeentStatus(data: any) {
    try {
      const response = await adminInstance.put(`/Order/paymentStatus`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putOrderClientInfo(data: any) {
    try {
      const response = await adminInstance.put(`/Order/userInfo`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putQuickOrderClientInfo(data: any) {
    try {
      const response = await adminInstance.put(`/Order/quickOrderUserInfo`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

import React from 'react';

const LinkedInFooterIcon: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.667 26.2231V14.6685C10.6646 13.8521 10.8207 13.0433 11.1262 12.2884C11.4318 11.5335 11.8809 10.8473 12.4478 10.2692C13.0146 9.69104 13.6881 9.23235 14.4296 8.91938C15.1712 8.60641 15.9661 8.44531 16.769 8.44531C18.3922 8.44531 19.9489 9.10096 21.0966 10.268C22.2444 11.4351 22.8892 13.018 22.8892 14.6685V26.2231"
      stroke="white"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
    />
    <path d="M4 7.33398V26.2229" stroke="white" strokeWidth="1.66667" strokeMiterlimit="10" />
    <path
      d="M4.55631 5.11068C5.47679 5.11068 6.22298 4.36449 6.22298 3.44401C6.22298 2.52354 5.47679 1.77734 4.55631 1.77734C3.63584 1.77734 2.88965 2.52354 2.88965 3.44401C2.88965 4.36449 3.63584 5.11068 4.55631 5.11068Z"
      fill="white"
    />
    <path d="M10.667 7.33398V16.2229" stroke="white" strokeWidth="1.66667" strokeMiterlimit="10" />
  </svg>
);

export default LinkedInFooterIcon;

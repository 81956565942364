import './AdminVideo.scss';

import React, { ChangeEvent, DragEvent, useRef } from 'react';

import CloseSmall from '../../../../general/assets/svg/CloseSmall';
import FileIcon from '../../../../general/assets/svg/FileIcon';

interface DragAndDropProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ file, setFile }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    processFiles(e.target.files);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    processFiles(e.dataTransfer.files);
  };

  const processFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === 'video/mp4') {
        setFile(file);
      }
    }
  };

  return (
    <>
      {file ? (
        <div className="imported__drag-and-drop">
          <div className="imported__drag-and-drop__icon">
            <FileIcon />
          </div>
          <div className="imported__drag-and-drop__file-name">
            <span>{file.name}</span>
          </div>
          <div className="imported__drag-and-drop__remove">
            <button onClick={() => setFile(null)}>
              <CloseSmall />
            </button>
          </div>
        </div>
      ) : (
        <div
          id="drop-area"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
          className="import__drag-and-drop"
        >
          <p>
            Drag & Drop your file here <span>video/.mp4 only</span>
          </p>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept="video/mp4"
          />
        </div>
      )}
    </>
  );
};

export default DragAndDrop;

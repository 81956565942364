import { AxiosInstance } from 'axios';

interface CryptosService {
  getCryptosList: () => Promise<any>;
}

const createCryptosService = (instance: AxiosInstance): CryptosService => ({
  async getCryptosList() {
    try {
      const response = await instance.get(`/Cryptocurrency/list`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createCryptosService;

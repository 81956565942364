import React, { Component } from 'react';
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from '@draft-js-plugins/buttons';
import '../Styles/editorStyles.scss';
import '../Styles/buttonStyles.scss';

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () => this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      // style={{ display: "flex", flexDirection: "row" }}
      <div>
        {buttons.map((Button, i) => (
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

const HeadlinesButton = ({ onOverrideContent }) => {
  const onMouseDown = (event) => event.preventDefault();

  const onClick = () => onOverrideContent(HeadlinesPicker);

  return (
    <div onMouseDown={onMouseDown} className="inlineButtonWrapper">
      <button
        onClick={onClick}
        style={{ fontWeight: 'bold', fontSize: '16px' }}
        className="inlineButton"
      >
        H
      </button>
    </div>
  );
};

export default HeadlinesButton;

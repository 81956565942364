import React from 'react';

const ReloadIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.48 12.5001C1.48202 9.99079 2.38176 7.56502 4.01655 5.66134C5.65134 3.75766 7.91328 2.50169 10.3935 2.12051C12.8736 1.73933 15.4083 2.2581 17.5393 3.58302C19.6703 4.90793 21.257 6.95157 22.0124 9.34445C22.7678 11.7373 22.6422 14.3215 21.6582 16.6298C20.6743 18.9382 18.8969 20.8183 16.6474 21.9302C14.398 23.0422 11.8249 23.3127 9.3934 22.6927C6.9619 22.0728 4.83243 20.6034 3.39 18.5501"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M3.39086 24V18.26H9.13086" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default ReloadIcon;

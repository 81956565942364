import React, { MouseEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ConsultancyIcon from '../../../general/assets/svg/ConsultancyIcon';
import FacebookLinkIcon from '../../../general/assets/svg/FacebookLinkIcon';
import TelegramLinkIcon from '../../../general/assets/svg/TelegramLinkIcon';
import XLinkIcon from '../../../general/assets/svg/XLinkIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { queryKeys } from '../../../general/queryKeys';
import { routesClient } from '../../../general/routes';
import createContactsService from '../../../general/services/contacts';
import createHomePageService from '../../../general/services/homePage';
import { clientInstance } from '../../../general/services/main/axiosInstances';

// import { HomePageServices } from '../../services/HomePageServices';
interface InfoBlock {
  id: string;
  title: string;
  content: string;
  imageUrl: string;
  buttonIconUrl: string;
  buttonText: string;
}

interface ContactLinks {
  telegramLink: string;
  facebookLink: string;
  xLink: string;
}

const SomeInfoSlider: React.FC = () => {
  const [activeBtn, setActiveBtn] = useState<InfoBlock | null>(null);
  const scrollTo = useScrollTo();
  const navigate = useNavigate();

  const { data: infoBlocks, isLoading: isLoadingInfoBlocks } = useCustomQuery<InfoBlock[]>(
    queryKeys.INFO_BLOCK,
    () => createHomePageService(clientInstance).getInfoBlocks(),
  );

  useEffect(() => {
    if (infoBlocks) {
      setActiveBtn(infoBlocks[0]);
    }
  }, [infoBlocks]);

  const { data: contacts } = useCustomQuery<ContactLinks>(
    queryKeys.LINKS_SOME_INFO,
    () => createContactsService(clientInstance).getContacts(),
    (data) => {
      const address = {
        telegramLink: data.telegramLink,
        facebookLink: data.facebookLink,
        xLink: data.xLink,
      };
      return address;
    },
  );

  const changeBlock = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget as HTMLButtonElement;
    const id = target.dataset.id;

    if (id && !target.classList.contains('active')) {
      const buttons = document.querySelectorAll('.introduction-section__slider__btn-button');
      buttons.forEach((btn) => btn.classList.remove('active'));
      target.classList.add('active');

      const index = parseInt(id, 10);
      if (infoBlocks && !isNaN(index) && infoBlocks[index]) {
        setActiveBtn(infoBlocks[index]);
      } else {
        setActiveBtn(null);
      }

      e.stopPropagation();
    }
  };

  const handleClick = () => {
    if (infoBlocks?.findIndex((item) => item.id === activeBtn?.id) === 0) {
      scrollTo('showcase');
    } else if (infoBlocks?.findIndex((item) => item.id === activeBtn?.id) === 1) {
      navigate(routesClient.pool.path);
    } else if (infoBlocks?.findIndex((item) => item.id === activeBtn?.id) === 2) {
      navigate(routesClient.hosting.path);
    }
  };

  return (
    <>
      <div className="introduction-section__slider__social-media ">
        <Link to={contacts?.telegramLink || '#'} target="_blank">
          <TelegramLinkIcon width="16" height="16" viewBox="0 0 16 16" />
        </Link>
        <Link to={contacts?.facebookLink || '#'} target="_blank">
          <FacebookLinkIcon width="16" height="16" viewBox="0 0 16 16" />
        </Link>
        <Link to={contacts?.xLink || '#'} target="_blank">
          <XLinkIcon width="20" height="21" viewBox="0 0 20 21" />
        </Link>
      </div>
      <div className="introduction-section__slider__content">
        <div className="introduction-section__slider__content__text">
          {activeBtn !== null && (
            <div>
              <h3>{activeBtn?.title}</h3>
              {activeBtn?.content}
            </div>
          )}
        </div>
        <div>
          <ConsultancyIcon />
        </div>
      </div>
      <div className="introduction-section__slider__btn">
        {infoBlocks &&
          infoBlocks.map((block, index) => (
            <button
              data-id={index}
              key={index}
              onClick={changeBlock}
              className={
                'introduction-section__slider__btn-button ' + (index === 0 ? 'active' : '')
              }
            >
              <div
                className="btn-icon"
                style={{ backgroundImage: `url(${block.buttonIconUrl})` }}
              ></div>
              {block.buttonText}
            </button>
          ))}
      </div>
      <div className="introduction-section__slider__img">
        {isLoadingInfoBlocks || !infoBlocks ? (
          <Preloader />
        ) : (
          <>
            {activeBtn !== null && activeBtn !== undefined && (
              <div
                onClick={handleClick}
                className="info-block"
                style={{ backgroundImage: `url(${activeBtn?.imageUrl})` }}
              ></div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SomeInfoSlider;

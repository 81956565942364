import { adminInstance } from '../../general/services/main/axiosInstances';

export const AdminServices = {
  // GET
  async getAllAdmins(query: string, pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/Admin/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getAdmin(id: string) {
    try {
      const response = await adminInstance.get(`/Admin/byId?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getAdminRoles(id: string) {
    try {
      const response = await adminInstance.get(`/Admin/adminRoles?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getAllRoles() {
    try {
      const response = await adminInstance.get(`/Admin/roles`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postNewAdmin(data: any) {
    try {
      const response = await adminInstance.post(`/Admin/createAdmin`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putAdmin(data: any) {
    try {
      const response = await adminInstance.put(`/Admin/adminInfoByOwner`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async resetPasswordByOwner(id: string) {
    try {
      const response = await adminInstance.put(`/Admin/resetPasswordByOwner`, {
        adminId: id,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putAdminInfo(data: any) {
    try {
      const response = await adminInstance.put(`/Admin/adminInfo`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putAdminPassword(data: any) {
    try {
      const response = await adminInstance.put(`/Admin/changeAdminPassword`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteAdmin(id: string, adminId: string) {
    const data = {
      id: id,
      authorizedAdminId: adminId,
    };

    try {
      const response = await adminInstance.delete(`/Admin`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export const AdminEmailsServices = {
  // GET
  async getAllEmails(query: string, pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/AdminEmail?Name=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postAdminEmail(data: any) {
    try {
      const response = await adminInstance.post(`/AdminEmail`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putAdminEmail(data: any) {
    try {
      const response = await adminInstance.put(`/AdminEmail`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteAdminEmail(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await adminInstance.delete(`/AdminEmail`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

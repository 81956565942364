import React from 'react';

const AdminGreenSuccesIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2607 1L8.76086 17.4999L1.26074 9.99997"
      stroke="#4CAF50"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AdminGreenSuccesIcon;

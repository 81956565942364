import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientProfileState {
  cp2: string;
  cpd: Record<string, any>;
  wl: any[];
  wlc: number;
}

const initialState: ClientProfileState = {
  cp2: '',
  cpd: {},
  wl: [],
  wlc: 0,
};

const ClientProfileSlice = createSlice({
  name: 'clps',
  initialState,
  reducers: {
    clientLogin: (state, action: PayloadAction<string>) => {
      state.cp2 = action.payload;
    },
    clientWishlist: (state, action: PayloadAction<any[]>) => {
      state.wl = action.payload;
      state.wlc = action.payload.length;
    },
    clientLogout: (state) => {
      state.cp2 = '';
      state.cpd = {};
      state.wl = [];
      state.wlc = 0;
    },
  },
});

export const { clientLogin, clientWishlist, clientLogout } = ClientProfileSlice.actions;
export default ClientProfileSlice.reducer;

import React from 'react';

const SuccessIcon: React.FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 62C35.9404 62.0051 39.843 61.2315 43.4834 59.7235C47.1239 58.2155 50.4304 56.003 53.213 53.213C56.003 50.4304 58.2155 47.1239 59.7235 43.4834C61.2315 39.843 62.0051 35.9404 62 32C62.005 28.0596 61.2313 24.1571 59.7234 20.5167C58.2154 16.8762 56.0029 13.5697 53.213 10.787C50.4304 7.99707 47.1239 5.78452 43.4834 4.27655C39.843 2.76858 35.9404 1.99492 32 2.00003C28.0596 1.995 24.1571 2.7687 20.5167 4.27667C16.8762 5.78463 13.5697 7.99713 10.787 10.787C7.99713 13.5697 5.78463 16.8762 4.27667 20.5167C2.7687 24.1571 1.995 28.0596 2.00003 32C1.99492 35.9404 2.76858 39.843 4.27655 43.4834C5.78452 47.1239 7.99707 50.4304 10.787 53.213C13.5697 56.0029 16.8762 58.2154 20.5167 59.7234C24.1571 61.2313 28.0596 62.005 32 62Z"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M20 32L29 41L47 23"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIcon;

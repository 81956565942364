import 'react-date-picker/dist/DatePicker.css';

import React from 'react';
import DatePicker from 'react-date-picker';

import CalendarEmptyIcon from '../../../../../general/assets/svg/CalendarEmptyIcon';
import CloseModalCrossIconWhite from '../../../../../general/assets/svg/CloseModalCrossIconWhite';

const getClearIcon = () => {
  return <CloseModalCrossIconWhite />;
};

const getCalendarIcon = () => {
  return <CalendarEmptyIcon />;
};

interface BatchProps {
  date: Date | null;
  onChange: (date: Date | Date[] | null) => void;
}

const Batch: React.FC<BatchProps> = ({ date, onChange }) => {
  return (
    <>
      <DatePicker
        className={'prodcut-batch react-date-picker__wrapper'}
        onChange={(value) => onChange(value as Date | Date[] | null)}
        value={date}
        format="MMMM y"
        monthPlaceholder=""
        yearPlaceholder=""
        clearIcon={getClearIcon}
        calendarIcon={getCalendarIcon}
        maxDetail="year"
        autoFocus={true}
      />
    </>
  );
};

export default Batch;

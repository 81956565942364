import React from 'react';

interface XLinkIconProps {
  width?: string | number;
  height?: string | number;
  viewBox?: string;
}

const TelegramLinkIcon: React.FC<XLinkIconProps> = ({ width, height, viewBox }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_135_1468)">
      <path
        d="M15 2.28665L1.66663 7.36665L12.46 13.7133L15 2.28665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.80669 11.56L6.11336 13.7133L5.69336 9.73335L14.3667 2.92001"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_1468">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TelegramLinkIcon;

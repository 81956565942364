import React from 'react';

const ArticleCloseIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 8L8 30" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M8 8L30 30" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default ArticleCloseIcon;

import React, { useEffect, useRef, useState } from 'react';

import { Category } from '../../../general/types/types';

interface CompareSelectinoListProps {
  categories: Category[];
  currentCategory: string;
  setCurrentCategory: (categoryId: string) => void;
}

const CompareSelectinoList: React.FC<CompareSelectinoListProps> = ({
  categories,
  currentCategory,
  setCurrentCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    categories.find((item) => item.id === currentCategory.toString())?.name || '',
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Выбор опции
  const handleOptionClick = (option: Category) => {
    setCurrentCategory(option.id);
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  // Закрыть выпадающий список при клике вне его
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="compare-select" ref={dropdownRef}>
      <div className="compare-select__current" onClick={() => setIsOpen(!isOpen)}>
        <div className="compare-select__current-options">
          <div className="compare-select__current-options__name">{selectedOption}</div>
          <svg
            width="18"
            height="11"
            viewBox="0 0 18 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.2891 1.05948L9.28906 9.05948L1.28906 1.05948"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="compare-select__list-options">
          <ul>
            {categories?.map((option, index) => (
              <li
                key={index}
                className="compare-select__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor: selectedOption === option.name ? '#241C2D' : '',
                }}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CompareSelectinoList;

import './AdminClient.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CloseModalCrossIconWhite from '../../../../general/assets/svg/CloseModalCrossIconWhite';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { AdminClientServices } from '../../../service/ClientServices';

interface RemoveClientModalProps {
  isOpen: (isOpen: boolean) => void;
}

const RemoveClientModal: React.FC<RemoveClientModalProps> = ({ isOpen }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeUser = async () => {
    const response = await AdminClientServices.deleteClient({ id: id });

    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    } else {
      navigate('/admin/clients');
    }

    isOpen(false);
  };

  return (
    <div className="warning-modal-window">
      <div className="warning-modal-window__overlay" onClick={() => isOpen(false)} />
      <div className="warning-modal-window__content">
        <button className="warning-modal-window__content__close-btn" onClick={() => isOpen(false)}>
          <CloseModalCrossIconWhite />
        </button>
        <div className="warning-modal-window__content__title">Warning</div>
        <div className="warning-modal-window__content__text">
          You are about to delete a user.
          <br /> His orders will be deleted along with him.
        </div>
        <div className="warning-modal-window__content__action">
          <button className="section-btn-gradient" onClick={removeUser}>
            Delete client permanently
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveClientModal;

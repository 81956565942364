import './AdminOrders.scss';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import EditIconAction from '../../../general/assets/svg/EditIconAction';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { queryKeys } from '../../../general/queryKeys';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import createOrdersService from '../../../general/services/orders';
import { Order, PaggingObj } from '../../../general/types/types';
import AdminSearch from '../../components/search/Search';
import CustomPagination from '../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../components/tables/custom-table/CustomTable';

interface ActionButtonsProps {
  item: Order;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item }) => {
  return (
    <>
      <Link data-id={item.orderNumber} to={`${item.id}`}>
        <EditIconAction />
      </Link>
    </>
  );
};

const AdminOrders: React.FC = () => {
  const actionButtons = (item: Order) => <ActionButtons item={item} />;
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const {
    data: orders,
    isLoading: ordersLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Order>>(queryKeys.ORDERS_ADMIN, () =>
    createOrdersService(adminInstance).getAllOrders(searchQuery, pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">ORDERS</div>
      </div>
      <div className="page-section__content">
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by id, client (email, phone, firstname, lastname, refferal code)'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {ordersLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={orders ? orders.items : []}
              headers={['number', 'order', 'date', 'total-price', 'client', 'order-status']}
              actionComponent={actionButtons}
            />
          </div>
          {orders && orders?.pageSize < orders?.totalCount && (
            <CustomPagination
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              isNextPage={orders.hasNextPage}
              isPrevPage={orders.hasPreviousPage}
              total={orders.totalCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;

import './ProductTable.scss';

import React, { useEffect } from 'react';

import TableHeadArrowButtomIcon from '../../../general/assets/svg/TableHeadArrowButtomIcon';
import TableHeadeArrowTopIcon from '../../../general/assets/svg/TableHeadeArrowTopIcon';

interface Header {
  name: string;
  atr: string;
}

interface THeadProps {
  headers: Header[];
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const THead: React.FC<THeadProps> = ({ headers, searchParams, setSearchParams }) => {
  const sortTable = (e: React.MouseEvent<HTMLButtonElement>) => {
    const last = searchParams.get('sortColumn')?.split('-')[0] || 'null';

    if (last !== 'null') {
      document
        .querySelector(`[data-col="${last}"]`)
        ?.querySelectorAll<HTMLElement>('svg')
        .forEach((element) => {
          element.style.display = 'block';
        });
    }

    const sortColumn = e.currentTarget.dataset.col;

    if (!sortColumn) return;

    const newParams = new URLSearchParams(searchParams.toString());
    const query = 'sortColumn';
    const params = newParams.get(query);

    if (params) {
      if (params === sortColumn) {
        newParams.set(query, `${sortColumn}-desc`);
        const sortIconUp = e.currentTarget.querySelector<HTMLElement>('.sort-icon-up');
        const sortIconDown = e.currentTarget.querySelector<HTMLElement>('.sort-icon-down');
        if (sortIconUp) sortIconUp.style.display = 'none';
        if (sortIconDown) sortIconDown.style.display = 'block';
      } else {
        if (params.split('-')[1] === 'desc' && params.split('-')[0] === sortColumn) {
          newParams.set(query, `null`);
          const sortIconUp = e.currentTarget.querySelector<HTMLElement>('.sort-icon-up');
          const sortIconDown = e.currentTarget.querySelector<HTMLElement>('.sort-icon-down');
          if (sortIconUp) sortIconUp.style.display = 'block';
          if (sortIconDown) sortIconDown.style.display = 'block';
        } else {
          newParams.set(query, sortColumn);
          const sortIconDown = e.currentTarget.querySelector<HTMLElement>('.sort-icon-down');
          if (sortIconDown) sortIconDown.style.display = 'none';
        }
      }

      if (sortColumn === undefined) {
        newParams.delete(query);
      }
    } else {
      newParams.set(query, sortColumn);
      const sortIconDown = e.currentTarget.querySelector<HTMLElement>('.sort-icon-down');
      if (sortIconDown) sortIconDown.style.display = 'none';
    }

    setSearchParams(newParams);
  };

  useEffect(() => {
    const sortColumnParam = searchParams.get('sortColumn');
    if (sortColumnParam) {
      const arr = sortColumnParam.split('-');
      const col = document.querySelector(`[data-col="${arr[0]}"]`);
      if (col) {
        const sortIconUp = col.querySelector<HTMLElement>('.sort-icon-up');
        const sortIconDown = col.querySelector<HTMLElement>('.sort-icon-down');
        if (arr.includes('desc')) {
          if (sortIconUp) sortIconUp.style.display = 'none';
        } else if (!arr.includes('null') && !arr.includes('desc')) {
          if (sortIconDown) sortIconDown.style.display = 'none';
        }
      }
    }
  }, [searchParams]);

  return (
    <thead className="producst__table__thead">
      <tr>
        {headers.map((header, index) => (
          <td key={index}>
            <button className="thead__td__header" data-col={header.atr} onClick={sortTable}>
              {header.name}
              <div className="thead__header__btns">
                <TableHeadeArrowTopIcon className="sort-icon-up" />
                <TableHeadArrowButtomIcon className="sort-icon-down" />
              </div>
            </button>
          </td>
        ))}
      </tr>
    </thead>
  );
};

export default THead;

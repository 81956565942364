import './LinksToSocialMedia.scss';

import React from 'react';
import { Tooltip } from 'react-tooltip';

import { Link } from '../../../../../general/types/types';

interface LinkProps {
  item: Link;
  owner: string;
  isActive?: boolean;
}

const CustomLink: React.FC<LinkProps> = ({ item, owner, isActive }) => {
  //const dispatch = useDispatch();
  //const [isActiveLink, setIsSubscribed] = useReducer((temp) => !temp, isActive);

  // const handleCheck = () => {
  //     setIsSubscribed();

  //     const { nickname, socialName, linkUrl, isActive } = action.payload;
  //     const data = {
  //         nickname: owner,
  //         socialName: item.name,
  //         linkUrl: item.link,
  //         isActive: !isActiveLink,
  //     };

  //     dispatch(updateLinkActive(data));
  // };

  return (
    <>
      <span>{item.name}:</span>
      {/* <div className="check-box__container">
                <input type="checkbox" id={`is_subscribed${item.id}`} checked={isActiveLink} onChange={handleCheck} />
                <label htmlFor={`is_subscribed${item.id}`} />
            </div> */}
      <span className={`link${item.affiliateId}`}>
        {/* style={{ color: `${isActiveLink ? '#65E279' : '#F66060'}` }} */}
        {item.link}
      </span>
      <Tooltip anchorSelect={`.link${item.affiliateId}`} place="top">
        {item.link}
      </Tooltip>
    </>
  );
};

interface LinksToSocialMediaProps {
  socialLinks: Link[];
  owner: string;
}

const LinksToSocialMedia: React.FC<LinksToSocialMediaProps> = ({ socialLinks, owner }) => {
  // const capitalize = (word: string): string => {
  //   if (!word) return word;
  //   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  // };

  // TODO need to use checkbox

  return (
    <>
      {socialLinks.map((item, index) => (
        <div key={index} className="links-table">
          <div className="links-table__link">
            <CustomLink item={item} owner={owner} isActive={item.isActive ?? true} />
          </div>
        </div>
      ))}
    </>
  );
};

export default LinksToSocialMedia;

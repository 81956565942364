import { adminInstance } from '../../general/services/main/axiosInstances';

export const ArticleServices = {
  // GET
  async getAllArticles(query: string, pageSize: number, page: number) {
    const response = await adminInstance.get(
      `/Article/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
    );
    return response.data;
  },

  // GET
  async getEditArticle(id: string) {
    try {
      const response = await adminInstance.get(`Article/byId?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postArticles(title: string, description: string, content: string, status: boolean) {
    const data = {
      title: title,
      description: description,
      content: content,
      isActive: status,
    };

    const response = await adminInstance.post(`/Article`, data);
    return response.data;
  },

  // POST
  async postArticleContentImage(image: any) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await adminInstance.post('/Article/contentImage', data, {
      headers,
    });

    return response.data;
  },

  //POST/PUT
  async post_putArticlesImg(id: string, image: any, isEdit: boolean) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await adminInstance.post(
      isEdit ? `/Article/updateImage?ArticleId=${id}` : `/Article/addImage?ArticleId=${id}`,
      data,
      { headers },
    );
    return response.data;
  },

  // PUT
  async putArticle(id: string, title: string, description: string, content: string) {
    const data = {
      id: id,
      title: title,
      description: description,
      content: content,
    };
    const response = await adminInstance.put(`/Article`, data);

    return response.data;
  },

  async putArticleActivity(id: string, isActive: boolean) {
    const data = {
      id: id,
      isActive: isActive,
    };

    const response = await adminInstance.put(`/Article/activity`, data);

    return response.data;
  },

  // DELETE
  async deleteArticle(id: string) {
    const data = {
      id: id,
    };
    const response = await adminInstance.delete(`/Article`, { data });
    return response.data;
  },
};

import './AdminShowcase.scss';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import EditIcon from '../../../../general/assets/svg/EditIconAction';
import LockIcon from '../../../../general/assets/svg/LockIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import { ProductMax } from '../../../../general/types/types';
import ShowcaseModal from './ShowCaseEditor';

const AdminShowcase: React.FC = () => {
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
  };

  const { data: products, isLoading: productsLoading } = useCustomQuery<ProductMax[]>(
    queryKeys.SHOW_CASE,
    () => createHomePageService(adminInstance).getMainPageProducts(),
  );

  return (
    <>
      {isModal && products && <ShowcaseModal isOpen={setIsModal} showcase={products} />}
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <LockIcon />
          <span>Showcase</span>
        </div>
        <button className="home-container__header__button" onClick={openModal}>
          <EditIcon />
        </button>
      </div>
      <div className="home-container__showcase">
        {productsLoading ? (
          <div className="home-container__showcas__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {!products || products?.length === 0 ? (
              <div className="home-container__showcase__empty">Showcase is empty</div>
            ) : (
              <div className="home-container__showcase__products">
                <ul>
                  {products?.map((product, index) => (
                    <li key={index}>
                      <Link to={`products/product/${product.id}`}>
                        <div
                          className="selected-img"
                          style={{
                            backgroundImage: `url(${
                              product?.image?.url === null || product?.image?.url === undefined
                                ? images.defaultImg
                                : product?.image?.url
                            })`,
                            margin: '0 10px',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '90px',
                            height: '90px',
                          }}
                        />
                        <span>{product?.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminShowcase;

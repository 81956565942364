import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ImagesSlider from '../../../../general/components/imagesSlider/ImagesSlider';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import {
  hostingToDelete,
  setHostingToEdit,
} from '../../../../general/redux/reducers/HostingLocations-Reducer';
import { RootState } from '../../../../general/redux/store';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import {
  HostingLocationStates,
  IHostingLocation,
  PaggingObj,
} from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import HostingLocationEditor from './HostingLocationEditor';

const HostingLocations: React.FC = () => {
  const dispatch = useDispatch();
  const hostingToRemove = useSelector((state: RootState) => state.hosting.hostingToDelete);

  const [deleteLocationAsk, setDeleteLocationAsk] = useState(false);
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination(
    10,
    'hosting_locations',
  );
  const [isModal, setIsModal] = useState<boolean>(false);

  const {
    data: locations,
    isLoading: locationsLoading,
    refetch: getLocations,
  } = useCustomQuery<PaggingObj<IHostingLocation>>(queryKeys.HOSTING_LOCATIONS, () =>
    createShippingService(adminInstance).getHostingLocations(pageSize, page, searchQuery),
  );

  const { data: states } = useCustomQuery<HostingLocationStates>(
    queryKeys.HOSTING_LOCATION_STATES,
    () => createShippingService(adminInstance).getHostingLocationStates(),
  );

  useEffect(() => {
    getLocations();
  }, [page, pageSize, searchQuery, getLocations]);

  const changeQuerry = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const { mutate: removeHosting } = useCustomMutation(
    createShippingService(adminInstance).deteHostingLocation,
    undefined,
    {
      onSuccess: () => {
        getLocations();
      },
    },
  );

  const openEditorToEdit = (location: IHostingLocation) => {
    setIsModal(true);
    dispatch(setHostingToEdit(location));
  };

  const openEditorToAddNew = () => {
    setIsModal(true);
    dispatch(setHostingToEdit(null));
  };

  return (
    <>
      {isModal && <HostingLocationEditor isOpen={setIsModal} refresh={getLocations} />}
      {locationsLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="top-section">
            <span className="block-header">Available hosting locations</span>
            <input
              type="text"
              placeholder="Search store by address, country or type (active, inactive)"
              className="input_symbols_panel"
              value={searchQuery}
              onChange={changeQuerry}
            />
          </div>
          <div className="hosting-locations-table">
            {locations &&
              locations.items.map((location, index) => (
                <React.Fragment key={location.id + index}>
                  <div className="hosting-location-card">
                    <div className="card-info">
                      <div className="hosting-location-images">
                        {location.images && location.images.length > 0 ? (
                          <ImagesSlider images={location.images} />
                        ) : (
                          <div className="no-images-container">
                            <span>No images</span>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          borderBottomColor:
                            location.state === states?.Available
                              ? 'green'
                              : location.state === states?.ComingSoon
                                ? 'orange'
                                : 'red',
                        }}
                        className="hosting-location-base_info"
                      >
                        <span>{location.locationName}</span>
                        <span
                          style={{
                            color:
                              location.state === states?.Available
                                ? 'green'
                                : location.state === states?.ComingSoon
                                  ? 'orange'
                                  : 'red',
                          }}
                        >
                          {location.state}
                        </span>
                      </div>
                      <div className="hosting-location-characteristics">
                        {location.characteristics && location.characteristics.length > 0 ? (
                          location.characteristics.map((characteristic) => (
                            <div key={characteristic.id}>
                              <span>{characteristic.key}</span>
                              <span className="dots"></span>
                              <span>{characteristic.value}</span>
                            </div>
                          ))
                        ) : (
                          <span>No characteristics</span>
                        )}
                      </div>
                    </div>
                    <div className="actionButtons">
                      <button
                        className="edit"
                        onClick={() => {
                          openEditorToEdit(location);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="delete"
                        onClick={() => {
                          setDeleteLocationAsk(true);
                          dispatch(hostingToDelete(location));
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  {deleteLocationAsk && (
                    <Confirm
                      title="Delete confirm"
                      text={'Are you shure want delete this hosting location?'}
                      handler={setDeleteLocationAsk}
                      clickEvent={() => {
                        if (hostingToRemove) {
                          removeHosting(hostingToRemove?.id);
                        }
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
          </div>
          <button className="section-btn-gradient add-new" onClick={openEditorToAddNew}>
            Add new hosting Location
          </button>
          {locations && locations?.pageSize < locations?.totalCount && (
            <CustomPagination
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              isNextPage={locations.hasNextPage}
              isPrevPage={locations.hasPreviousPage}
              total={locations.totalCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default HostingLocations;

import { adminInstance } from '../../general/services/main/axiosInstances';

export const AdminClientServices = {
  // GET
  async getAllCLients(query: string, pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/User/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getCLient(id: string) {
    try {
      const response = await adminInstance.get(`/User/adminUser?UserId=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getCLientOrders(id: string) {
    try {
      const response = await adminInstance.get(`/Order/adminUser?UserId=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getSubscribers(pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(`/Subscriptions?PageSize=${pageSize}&Page=${page}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  //PUT
  async changeStatusSubscriberByID(id: string, isActivate: boolean) {
    const data = {
      ids: [id],
    };

    try {
      const response = isActivate
        ? await adminInstance.put('/Subscriptions/activateByIds', data)
        : await adminInstance.put('/Subscriptions/deactivateByIds', data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  //PUT
  async changeStatusSubscribers(isActivate: boolean) {
    try {
      const response = isActivate
        ? await adminInstance.put('/Subscriptions/activateAll', {})
        : await adminInstance.put('/Subscriptions/deactivateAll', {});
      return response.data;
    } catch (error) {
      return error;
    }
  },

  //POST
  async sendMailing(body: any) {
    try {
      const responce = await adminInstance.post('/Subscriptions/sendEmails', body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteClient(data: any) {
    try {
      const response = await adminInstance.delete(`/User`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

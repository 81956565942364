import React from 'react';

const StaffIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9999 17.3066C20.2193 17.3066 23.6399 13.8861 23.6399 9.66661C23.6399 5.44716 20.2193 2.02661 15.9999 2.02661C11.7804 2.02661 8.35986 5.44716 8.35986 9.66661C8.35986 13.8861 11.7804 17.3066 15.9999 17.3066Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2 31.3066L2.49333 28.5733C3.07613 25.4165 4.74631 22.5637 7.21375 20.5104C9.68118 18.457 12.7899 17.3329 16 17.3333C19.2139 17.334 22.3257 18.4619 24.7937 20.5206C27.2616 22.5793 28.9294 25.4383 29.5067 28.5999L30 31.3333"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default StaffIcon;

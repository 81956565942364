import React from 'react';

const NotCopiedIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4893 14.748H19.9893C21.0939 14.748 21.9893 13.8526 21.9893 12.748V5.74805C21.9893 4.64348 21.0939 3.74805 19.9893 3.74805H12.9893C11.8847 3.74805 10.9893 4.64348 10.9893 5.74805V7.24805"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9893 10.748H5.98926C4.88469 10.748 3.98926 11.6434 3.98926 12.748V19.748C3.98926 20.8526 4.88469 21.748 5.98926 21.748H12.9893C14.0939 21.748 14.9893 20.8526 14.9893 19.748V12.748C14.9893 11.6434 14.0939 10.748 12.9893 10.748Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotCopiedIcon;

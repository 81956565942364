import './productSlider-slick-theme.scss';
import './productSlider-slick.scss';

import React, { MouseEvent } from 'react';
import Slider from 'react-slick';

import { useResize } from '../../../../general/hooks/useResize';

export interface Image {
  url: string;
  description?: string;
}

interface ProductSliderProps {
  images: Image[] | undefined;
  setCurrent: (event: MouseEvent<HTMLImageElement>) => void;
}

const ProductSlider: React.FC<ProductSliderProps> = ({ images, setCurrent }) => {
  const { width } = useResize();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: width > 768,
    draggable: false,
    swipe: width <= 768,
  };

  const handleImageClick = (e: MouseEvent<HTMLDivElement>) => {
    const imageUrl = e.currentTarget.getAttribute('data-url');
    if (imageUrl) {
      const event = e as MouseEvent<HTMLImageElement>;
      setCurrent(event);
    }
  };

  return (
    <Slider {...settings}>
      {images &&
        images.map((img, index) => (
          <div
            key={index}
            onClick={handleImageClick}
            data-url={img.url}
            className="product-slider__image-block"
          >
            <img src={img.url} alt={img.description || `Product ${index + 1}`} />
          </div>
        ))}
    </Slider>
  );
};

export default ProductSlider;

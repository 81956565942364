import { useEffect } from 'react';

type KeyEventCallback = (event: KeyboardEvent) => void;

export const keys = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

const useKey = (callback: KeyEventCallback) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      callback(event);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [callback]);
};

export default useKey;

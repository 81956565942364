import './AdminProduct.scss';

import React, { ChangeEvent, MouseEvent, useState } from 'react';

import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import { AdditionalCharacteristicProd } from '../../../../general/types/types';

interface AdditionalCharacteristicProps {
  characteristics: AdditionalCharacteristicProd[];
  removeArray: number[];
  changeArray: number[] | null;
}

const AdditionalCharacteristic: React.FC<AdditionalCharacteristicProps> = ({
  characteristics,
  removeArray,
  changeArray,
}) => {
  const [additionalCharacteristic, setAdditionalCharacteristic] =
    useState<AdditionalCharacteristicProd[]>(characteristics);

  const addComponent = () => {
    const id = additionalCharacteristic.length + 1;
    setAdditionalCharacteristic((prevComponents) => [...prevComponents, { id }]);
  };

  const removeComponent = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    removeArray.push(id);
    setAdditionalCharacteristic((prevComponents) =>
      prevComponents.filter((component) => component.id !== id),
    );
    event.stopPropagation();
  };

  const changed = (e: ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(e.target.dataset.id || '', 10);
    if (changeArray === null || !characteristics.some((item) => item.id === id)) {
      return;
    }

    if (!changeArray.includes(id)) {
      changeArray.push(id);
    }
  };

  return (
    <div className="page-section__characteristics__content">
      <div className="page-section__characteristics__title">
        Additional characteristics
        <button onClick={addComponent} className="page-section__characteristics__title__btn">
          <PlusIcon />
        </button>
      </div>
      <div
        id="additional__characteristics__characteristic"
        className="page-section__characteristics__characteristic"
      >
        {additionalCharacteristic?.map((component) => (
          <div className="additional-characteristic__block" key={component.id}>
            <div className="additional-characteristic__block__input-block" data-id={component.id}>
              <div className="additional-characteristic__block__input-block__order">
                <label>Order</label>
                <input
                  type="number"
                  name="o-name"
                  data-id={component.id}
                  defaultValue={component.order || component.id}
                  onChange={changed}
                />
              </div>
              <div>
                <label>Name</label>
                <input
                  name="a-name"
                  data-id={component.id}
                  defaultValue={component.name}
                  onChange={changed}
                />
              </div>
              <div>
                <label>Value</label>
                <input
                  name="a-value"
                  data-id={component.id}
                  defaultValue={component.value}
                  onChange={changed}
                />
              </div>
            </div>
            <button
              onClick={(event) => removeComponent(event, component.id)}
              className="additional-characteristic__block__delete"
            >
              <DeleteActionIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalCharacteristic;

import localforage from 'localforage';

export const CACHE_CLEAN_FLAG = 'cacheCleaned';

// Настройка localforage
localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE], // Порядок предпочтения
  name: 'myApp',
  version: 1.0,
  storeName: 'keyvaluepairs', // Имя хранилища
  description: 'some description',
});

// Пример функции для сохранения данных с типизацией
export const saveDataForage = async (key: string, value: any): Promise<void> => {
  try {
    await localforage.setItem(key, value);
  } catch (err) {
    console.error('Error saving data:', err);
  }
};

// Пример функции для чтения данных с типизацией
export const getDataForage = async <T>(key: string): Promise<T | null> => {
  try {
    const value = await localforage.getItem<T>(key);
    return value;
  } catch (err) {
    console.error('Error retrieving data:', err);
    return null;
  }
};

// Пример функции для удаления данных с типизацией
export const removeDataForage = async (key: string): Promise<void> => {
  try {
    await localforage.removeItem(key);
  } catch (err) {
    console.error('Error removing data:', err);
  }
};

// Пример функции для очистки хранилища с типизацией
export const clearStorageForage = async (): Promise<void> => {
  try {
    await localforage.clear();
  } catch (err) {
    console.error('Error clearing storage:', err);
  }
};

export const cleanCacheForage = async (): Promise<void> => {
  const cacheCleaned = localStorage.getItem(CACHE_CLEAN_FLAG);

  if (!cacheCleaned) {
    try {
      await localforage.clear();
      console.log('Local storage successfully cleaned');
      localStorage.setItem(CACHE_CLEAN_FLAG, 'true');
      window.location.reload();
    } catch (error) {
      console.error('Error cleaning local storage:', error);
    }
  }
};

import { useEffect, useRef, useState } from 'react';

const useScrollToBottom = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const sticky = container.querySelector('.sticky') as HTMLDivElement;

        if (!sticky) return;

        const containerTop = container.getBoundingClientRect().top + window.scrollY;
        const containerBottom = container.getBoundingClientRect().bottom + window.scrollY;
        const stickyHeight = sticky.clientHeight;
        const containerHeight = container.clientHeight;
        const offset = 0; // Отступ для нижней границы

        if (containerHeight > stickyHeight) {
          if (
            window.scrollY >= containerTop &&
            window.scrollY + stickyHeight + offset <= containerBottom
          ) {
            setIsFixed(true);
            setIsBottom(false);
          } else if (window.scrollY + stickyHeight + offset > containerBottom) {
            setIsFixed(false);
            setIsBottom(true);
          } else {
            setIsFixed(false);
            setIsBottom(false);
          }
        } else {
          setIsFixed(false);
          setIsBottom(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { containerRef, isFixed, isBottom, setIsBottom, setIsFixed };
};

export default useScrollToBottom;

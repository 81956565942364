import React from 'react';

const AdminRedCrossIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.26074 1L25.2607 25" stroke="#D53636" strokeWidth="2" strokeLinecap="round" />
    <path d="M1.26074 25L25.2607 1" stroke="#D53636" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default AdminRedCrossIcon;

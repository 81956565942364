import React from 'react';

const ResumeVideoIcon: React.FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45.7053 31.1255C46.3914 31.5065 46.3914 32.4931 45.7053 32.874L25.8585 43.8949C25.192 44.265 24.373 43.7831 24.373 43.0207V20.9789C24.373 20.2165 25.192 19.7345 25.8585 20.1047L45.7053 31.1255Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ResumeVideoIcon;

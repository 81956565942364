import './ClientTracking.scss';

import React, { useEffect } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createRefferalService from '../../../../general/services/refferal';
import { PaggingObj } from '../../../../general/types/types';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';

interface Client {
  id: number;
  fullName: string;
  registrationDate: string;
  referralId: number;
  // Add other properties as needed
}

const ClientTracking: React.FC = () => {
  const headers = ['client id', 'full name', 'registration date', 'referral id'];
  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: clients,
    isLoading: clientsLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Client>>(queryKeys.REFFERAL_CLIENT, () =>
    createRefferalService(adminInstance).getClientsTracking(page, pageSize, ''),
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  return (
    <>
      {clientsLoading && (
        <div className="page-update-overlay">
          <Preloader />
        </div>
      )}
      <div className="tracking">
        <CustomTable
          data={clients ? clients?.items : []}
          headers={headers}
          isAction={false}
          color={'#3E5960'}
        />
      </div>
      {clients && clients.pageSize < clients.totalCount && (
        <CustomPagination
          currentPage={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          isNextPage={clients.hasNextPage}
          isPrevPage={clients.hasPreviousPage}
          total={clients.totalCount}
        />
      )}
    </>
  );
};

export default ClientTracking;

import { AxiosInstance } from 'axios';

interface ProductsService {
  getCharacteristicsMainGeneralOfProduct: (productId: string) => Promise<any>;
  getProductByURL: (url: string) => Promise<any>;
  getAllProducts: (search: string, pageSize: number, page: number) => Promise<any>;
  getProductsCategories: () => Promise<any>;
  getFiltersMainMining: (categoryId: string) => Promise<any>;
  getFiltersMainGeneralOfCategory: (categoryId: string) => Promise<any>;
  getProducts: (
    categoryId: string,
    isRetail: boolean,
    pageSize: number,
    page: number,
    queryStr: any,
    price: any,
    sort: any,
  ) => Promise<any>;
  getMainGeneralOfProduct: (id: string) => Promise<any>;
  getMainGeneralNames: (id: string) => Promise<any>;
  getEditProduct: (id: string) => Promise<any>;
}

const createProductsService = (instance: AxiosInstance): ProductsService => ({
  async getEditProduct(id: string) {
    try {
      const response = await instance.get(`Product/byIdAdmin?ProductId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMainGeneralNames(id: string) {
    try {
      const response = await instance.get(`/Characteristics/mainGeneralNames?CategoryId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMainGeneralOfProduct(id: string) {
    try {
      const response = await instance.get(`/Characteristics/mainGeneralOfProduct?ProductId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCharacteristicsMainGeneralOfProduct(productId) {
    try {
      const response = await instance.get(
        `/Characteristics/mainGeneralOfProduct?ProductId=${productId}`,
      );

      return response;
    } catch (error) {
      return error;
    }
  },

  async getProductByURL(url) {
    try {
      const response = await instance.get(`/Product/byUrl?Url=${url}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getProducts(categoryId, isRetail, pageSize, page, queryStr, price, sort) {
    try {
      if (!Array.isArray(queryStr)) {
        throw new Error('queryStr должен быть массивом');
      }

      const params = new URLSearchParams();

      if (categoryId) params.append('CategoryId', categoryId);
      if (typeof isRetail === 'boolean') params.append('IsRetail', isRetail.toString());
      if (pageSize) params.append('PageSize', pageSize.toString());
      if (page) params.append('Page', page.toString());
      if (queryStr.length > 0) params.append('QueryString', encodeURIComponent(queryStr.join('&')));
      if (price && price.hasOwnProperty('PriceFrom'))
        params.append('PriceFrom', price.PriceFrom.toString());
      if (price && price.hasOwnProperty('PriceTo'))
        params.append('PriceTo', price.PriceTo.toString());
      if (sort && sort.hasOwnProperty('SortColumn')) params.append('SortColumn', sort.SortColumn);
      if (sort && sort.hasOwnProperty('SortOrder')) params.append('SortOrder', sort.SortOrder);

      const queryString = params.toString();

      const response = await instance.get(`/Product/byQuery?${queryString}`);

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getProductsCategories() {
    try {
      const response = await instance.get(`/Category`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllProducts(search: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Product/all?Name=${encodeURIComponent(search)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getFiltersMainMining(categoryId) {
    try {
      const response = await instance.get(`/Characteristics/mainMining?CategoryId=${categoryId}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getFiltersMainGeneralOfCategory(categoryId) {
    try {
      const response = await instance.get(
        `/Characteristics/mainGeneralOfCategory?CategoryId=${categoryId}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createProductsService;

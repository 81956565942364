import './AdminTestimonials.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import { TestimonialsService } from '../../../service/TestimonialsServices';
import RatingSelector from './RatingSelector';

interface ModalTestimonialProps {
  isOpen: (isOpen: boolean) => void;
  id: string | null;
}

const ModalTestimonial: React.FC<ModalTestimonialProps> = ({ isOpen, id }) => {
  const closeModal = () => {
    isOpen(false);
  };

  const dispatch = useDispatch();
  const [testimonial, setTestimonial] = useState<any>(null);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isDeleteImg, setIsDeleteImg] = useState<string | null>('');
  const [currentIMG, setCurrentIMG] = useState<string | null>('');

  const getData = useCallback(
    async (testimonialIdToEdit: string) => {
      setIsPreloader(true);
      const response: any =
        await createHomePageService(adminInstance).getTestimonealById(testimonialIdToEdit);
      if (response.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: response.message,
            r: Object.prototype.hasOwnProperty.call(response, 'response')
              ? { d: response.response.data, s: response.response.statusText }
              : '',
          }),
        );
        setTestimonial(null);
      } else {
        setTestimonial(response.data);
        setCurrentIMG(response?.data.imageUrl || '');
      }
      setIsPreloader(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getData(id);
    }
  }, [getData, id]);

  const addTestimonial = async () => {
    setIsPreloader(true);
    const inputBlock = document.querySelector(
      '.modal-window-action__modal__testimonials__b',
    ) as HTMLElement;
    const inputs = inputBlock.querySelectorAll('input');
    const textarea = inputBlock.querySelector('textarea') as HTMLTextAreaElement;
    const rating = (document.querySelector('.order-status__current-options__name') as HTMLElement)
      .dataset.option;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      if (input_img.files) {
        await TestimonialsService.postTestimoneal(
          inputs[0].value,
          inputs[1].value,
          textarea.value,
          Number(rating),
          input_img.files[0],
        );
      }

      closeModal();
      setIsPreloader(false);
    }
  };

  const updateTestimonial = async () => {
    setIsPreloader(true);
    const inputBlock = document.querySelector(
      '.modal-window-action__modal__testimonials__b',
    ) as HTMLElement;
    const inputs = inputBlock.querySelectorAll('input');
    const textarea = inputBlock.querySelector('textarea') as HTMLTextAreaElement;
    const rating = (document.querySelector('.order-status__current-options__name') as HTMLElement)
      .dataset.option;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      const result = {
        id: id,
        username: inputs[0].value,
        country: inputs[1].value,
        content: textarea.value,
        rating: rating,
      };
      await TestimonialsService.putTestimoneal(result);

      if (isDeleteImg !== '') {
        await TestimonialsService.deleteTestimonealImage({
          imageId: isDeleteImg,
        });
      }

      if (input_img.files && input_img.files[0] !== undefined) {
        await TestimonialsService.putTestimonealImage(id!, input_img.files[0]);
      }

      closeModal();
      setIsPreloader(false);
    }
  };

  const deletePartner = async () => {
    setIsPreloader(true);
    await TestimonialsService.deleteTestimoneal(testimonial?.id);
    closeModal();
    setIsPreloader(false);
  };

  const clearImg = (e: React.MouseEvent<HTMLImageElement>) => {
    setCurrentIMG(null);
    setIsDeleteImg(e.currentTarget.dataset.id || null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCurrentIMG(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <AdminModalWindow mWidth="" isOpen={isOpen} title={'Testimonials'}>
      {isPreloader && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__testimonials">
        <div className="modal-window-action__modal__testimonials__a">
          <div className="modal-window-action__modal__testimonials__img">
            {currentIMG === '' ? (
              <label htmlFor="upload-photo" className="img-loader">
                +
              </label>
            ) : (
              <div
                className="selected-img"
                data-id={testimonial?.imageId}
                onClick={clearImg}
                style={{
                  backgroundImage: `url(${currentIMG || images.defaultImg})`,
                  margin: '0 10px',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '150px',
                }}
              />
            )}

            <input id="upload-photo" type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <RatingSelector
            currentRating={testimonial?.rating === undefined ? 5 : testimonial?.rating}
          />
        </div>
        <div className="modal-window-action__modal__testimonials__b">
          <div className="modal-window-action__modal__testimonials__inputs inputs">
            <input type="text" placeholder="name" defaultValue={testimonial?.username || ''} />
            <input type="text" placeholder="location" defaultValue={testimonial?.country || ''} />
          </div>
          <textarea placeholder="description" defaultValue={testimonial?.content || ''} />
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        {testimonial !== null && (
          <button className="delete-btn" onClick={deletePartner}>
            Delete
          </button>
        )}
        <button
          className="section-btn-gradient"
          onClick={id === null ? addTestimonial : updateTestimonial}
        >
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default ModalTestimonial;

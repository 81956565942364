import React from 'react';

const ShareIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.1869 9.62667C28.2929 9.62667 30.0002 7.91938 30.0002 5.81333C30.0002 3.70729 28.2929 2 26.1869 2C24.0808 2 22.3735 3.70729 22.3735 5.81333C22.3735 7.91938 24.0808 9.62667 26.1869 9.62667Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.81333 19.8134C7.91938 19.8134 9.62667 18.1061 9.62667 16.0001C9.62667 13.8941 7.91938 12.1868 5.81333 12.1868C3.70729 12.1868 2 13.8941 2 16.0001C2 18.1061 3.70729 19.8134 5.81333 19.8134Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M26.1869 30C28.2929 30 30.0002 28.2927 30.0002 26.1866C30.0002 24.0806 28.2929 22.3733 26.1869 22.3733C24.0808 22.3733 22.3735 24.0806 22.3735 26.1866C22.3735 28.2927 24.0808 30 26.1869 30Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.7732 7.52002L9.22656 14.2934"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.7732 24.4801L9.22656 17.7068"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ShareIcon;

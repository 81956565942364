import './animation.css';

import React from 'react';

export type PreloaderStyles = 'dark' | 'default';

export const preloaderStyles = {
  DARK: 'dark' as PreloaderStyles,
  DEFAULT: 'default' as PreloaderStyles,
};

interface PreloaderProps {
  style?: PreloaderStyles;
}

const Preloader: React.FC<PreloaderProps> = ({ style = preloaderStyles.DEFAULT }) => {
  return (
    <div className={style === 'default' ? 'page-preloader' : 'page-preloader-dark'}>
      <div className="animation1"></div>
      <div className="animation2"></div>
      <div className="animation3"></div>
    </div>
  );
};

export default Preloader;

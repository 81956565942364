import './NewsletterWorkshop.scss';

import React, { useEffect, useRef, useState } from 'react';

import useRequired from '../../../../../general/hooks/useRequired';
import { MailingServices } from '../../../../service/MailingServices';
import TextEditor, { sendImgSettingsConstatns } from '../../../articles/article/Editor/TextEditor';

//import useAxiosChangeNewsLetter from '../../../../components/general/hooks/useAxioUpdatePost';
const addUnsubsribeButton = (baseUrl) => {
  const link = document.createElement('a');

  link.textContent = 'Unsubscribe';
  link.style.padding = '4px 20px';
  link.style.textDecoration = 'none';
  link.style.fontSize = '16px';
  link.style.cursor = 'pointer';
  link.style.border = 'none';
  link.style.borderRadius = '60px';
  link.style.background = `var( --gradient-10,linear-gradient(94deg, #25a8cf -23.33%, #8540bb 154.23%))`;
  link.style.color = 'white';
  link.id = 'unsubscribe';
  link.href = `${baseUrl}/unsubscribe`;

  return link;
};

const NewsletterWorkshop = () => {
  const baseUrl = window.location.origin;
  const editor = useRef(null);

  const [title, setTitle] = useState('');
  const [descriptionPost, setDescriptionPost] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);

  const { errors, setError } = useRequired();

  //const changeNewsletter = useAxiosChangeNewsLetter();

  useEffect(() => {
    MailingServices.getNewsLetter().then((newsletter) => {
      if (newsletter.items.length > 0) {
        const currentNewsletter = newsletter.items[0];

        setTitle(currentNewsletter.title);

        var foundDiv = findUnsubscribe(currentNewsletter.content);
        editor.current.fromHTML(foundDiv.querySelector('div').innerHTML);

        if (!currentNewsletter.wasPosted) setPostToEdit(currentNewsletter);
      }
    });
  }, []);

  const incertUnsubscribe = (content) => {
    const generalDiv = document.createElement('div');
    generalDiv.style.display = 'flex';
    generalDiv.style.flexDirection = 'column';
    generalDiv.style.width = '100%';
    generalDiv.style.overflow = 'auto';

    const temp = document.createElement('div');
    temp.id = 'with_unsubscribe';
    temp.style.width = '50%';
    temp.style.textAlign = 'justify';
    temp.style.margin = '0 auto';

    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = content;

    const unsubscribeDiv = document.createElement('div');
    unsubscribeDiv.style.clear = 'both';
    unsubscribeDiv.style.marginTop = '15px';
    unsubscribeDiv.innerHTML += addUnsubsribeButton(baseUrl).outerHTML;

    temp.appendChild(wrapperDiv);
    temp.appendChild(unsubscribeDiv);
    generalDiv.appendChild(temp);

    return generalDiv.outerHTML;
  };

  const findUnsubscribe = (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    return tempDiv.querySelector('#with_unsubscribe');
  };

  const handleSaveDraft = () => {
    // if no title
    if (title === '' || title === undefined) {
      setError('name', 'Title is required');
      return;
    }

    const parser = new DOMParser();
    const doc = editor.current.toHTML();
    const parsedDoc = parser.parseFromString(doc, 'text/html');

    let content = parsedDoc.body.innerHTML.replace(/percent/gi, '%');
    content = incertUnsubscribe(content);

    const textContent = parsedDoc.body.textContent;
    // if no context
    if (textContent === '' || textContent === undefined) {
      setError('description', 'Description is required');
      return;
    }

    setIsLoading(true);

    if (postToEdit !== null) {
      MailingServices.updateNewsLetter({
        id: postToEdit.id,
        title: title,
        content: content,
        timeToPost: postToEdit.timeToPost,
      }).then((responce) => alert('Draft succesfully changed'));
    } else {
      MailingServices.createNewsLetter({
        title: title,
        content: content,
      }).then((responce) => alert('Draft succesfully added'));
    }
  };

  return (
    <div className="container">
      <div className="item">
        <label className="input-header">Title</label>
        <input
          placeholder={errors.name ? errors.name : ''}
          className={errors.name ? 'error_required' : ''}
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          id="post_name"
          type="text"
          required
        />
      </div>
      <div className="item">
        <TextEditor
          ref={editor}
          setDescriptionPost={setDescriptionPost}
          errorDescriptionRequired={errors.description}
          sendImgSettings={sendImgSettingsConstatns.mailing}
          isNeedVideo={false}
        />
      </div>
      <div className="item">
        <button onClick={handleSaveDraft} className="button">
          Save draft
        </button>
      </div>
    </div>
  );
};

export default NewsletterWorkshop;

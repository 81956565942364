import React from 'react';

const BucketIcon: React.FC = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1318 25H5.86797C5.25596 25.0007 4.667 24.7877 4.22271 24.4049C3.77842 24.0221 3.51274 23.4987 3.48053 22.9428L2.3999 5.36569H21.5999L20.5193 22.9428C20.4871 23.4987 20.2214 24.0221 19.7771 24.4049C19.3328 24.7877 18.7438 25.0007 18.1318 25Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M0 5.36569H24" stroke="#746A81" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M9.6002 1H14.4002C15.0367 1 15.6472 1.22998 16.0973 1.63934C16.5473 2.04871 16.8002 2.60393 16.8002 3.18286V5.36571H7.2002V3.18286C7.2002 2.60393 7.45305 2.04871 7.90314 1.63934C8.35323 1.22998 8.96368 1 9.6002 1Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M12 8.63431V21.7314" stroke="#746A81" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M16.7998 8.63431V21.7314" stroke="#746A81" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M7.2002 8.63431V21.7314" stroke="#746A81" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default BucketIcon;

import './ProfileWishlist.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import { images } from '../../../../general/StaticData';
import { ProductMax } from '../../../../general/types/types';
import WishlistItem from './WisthListItem';

const ProfileWishlist: React.FC = () => {
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.clps.cp2);

  useEffect(() => {
    if (!userId) navigate(routesClient.home.path);
  }, [navigate, userId]);

  const userWishList = useSelector((state: RootState) => state.clps.wl);

  const [wishlist, setWishlist] = useState<ProductMax[] | null>(null);

  useEffect(() => {
    setWishlist(userWishList);
  }, [userWishList]);

  return (
    <div className="profile-wishlist-page">
      <div className="profile-wishlist-page__title-section">Wish list</div>
      <div className="profile-wishlist-page__product-list">
        {wishlist !== null ? (
          <>
            {wishlist.length !== 0 ? (
              <div className="profile-wishlist-page__product-list__section">
                <ul>
                  {wishlist &&
                    wishlist.map((product, index) => (
                      <WishlistItem key={index} userId={userId} product={product} />
                    ))}
                </ul>
              </div>
            ) : (
              <div className="profile-wishlist-page__product-list__empty">
                <div className="profile-wishlist-page__product-list__empty__img">
                  <img src={images.noGoods} alt="empty-list" />
                </div>
                <div className="profile-wishlist-page__product-list__empty__text">
                  Oops! Your wish list is empty
                  <span>Fill it with goods</span>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="profile-wishlist-page__product-list__empty">
            <Preloader />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileWishlist;

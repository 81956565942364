import './ModalWindow.scss';

import React from 'react';

import CloseModalCrossIcon from '../../../../general/assets/svg/CloseModalCrossIcon';
import EmailAuthorization from '../email-authorization/EmailAuthorization';
import GoogleAuth from '../google-authorization/GoogleAuth';

interface ModalWindowProps {
  isActive: (isOpen: boolean) => void;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ isActive }) => {
  return (
    <>
      <div
        className="overlay"
        onClick={() => {
          isActive(false);
        }}
      ></div>
      <div className="modal-window ">
        <div className="modal-window-section">
          <div
            className="modal-window-close"
            onClick={() => {
              isActive(false);
            }}
          >
            <CloseModalCrossIcon />
          </div>
          <div className="modal-window-section__top">
            <div className="modal-window-section__top-title">Personal account</div>
          </div>
          <div className="modal-window-section__bottom"></div>
          <EmailAuthorization isOpenModal={isActive} />
          {/* <span className="forgot-link">forgot password</span> */}
          {/* <PhoneAuthorization isOpenModal={isActive}/> */}
          <GoogleAuth isOpenModal={isActive} />
        </div>
      </div>
    </>
  );
};

export default ModalWindow;

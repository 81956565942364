import './AdminTestimonials.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';
import StarIcon from '../../../../general/assets/svg/StarIcon';

interface RatingSelectorProps {
  currentRating: number;
}

const RatingSelector: React.FC<RatingSelectorProps> = ({ currentRating }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(currentRating);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: number) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Закрыть выпадающий список при клике вне его
  useEffect(() => {
    setSelectedOption(currentRating);
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, currentRating]);

  return (
    <div className="order-status" ref={dropdownRef}>
      <div className="order-status-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="order-status__current-options">
          <div className="order-status__current-options__name" data-option={selectedOption}>
            {[...Array(selectedOption).keys()].map((star, index) => (
              <React.Fragment key={index}>
                <StarIcon />
              </React.Fragment>
            ))}
          </div>
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="order-status__list-options">
          <ul>
            {[1, 2, 3, 4, 5].map((option, index) => (
              <li
                key={index}
                data-rating={index + 1}
                className="order-status__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor: selectedOption === option ? '#0F1928' : '',
                }}
              >
                {[...Array(index + 1).keys()].map((star, index) => (
                  <React.Fragment key={index}>
                    <StarIcon />
                  </React.Fragment>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RatingSelector;

import React from 'react';

const WishListProdIcon: React.FC<{ isWishList: boolean }> = ({ isWishList }) => (
  <svg
    className={isWishList ? 'active-svg' : ''}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="37" height="37" rx="18.5" />
    <path
      d="M23.77 9.45001C22.7961 9.47093 21.8444 9.74463 21.0081 10.2443C20.1719 10.7439 19.4799 11.4523 19 12.3C18.5201 11.4523 17.8281 10.7439 16.9919 10.2443C16.1556 9.74463 15.2039 9.47093 14.23 9.45001C11.06 9.45001 8.5 12.3 8.5 15.82C8.5 22.18 19 28.55 19 28.55C19 28.55 29.5 22.18 29.5 15.82C29.5 12.3 26.94 9.45001 23.77 9.45001Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default WishListProdIcon;

import './AdminOrder.scss';

import React, { useState } from 'react';

import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createOrdersService from '../../../../general/services/orders';

interface Order {
  id: string;
  isQuickOrder: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

interface ClientEditModalProps {
  isOpen: (open: boolean) => void;
  Order: Order;
}

const ClientEditModal: React.FC<ClientEditModalProps> = ({ isOpen, Order }) => {
  const [isPreloader, setIsPreloader] = useState(false);

  const closeModal = () => {
    isOpen(false);
  };

  const { mutate: putQuickOrderClientInfo } = useCustomMutation(
    createOrdersService(adminInstance).putQuickOrderClientInfo,
  );

  const { mutate: putOrderClientInfo } = useCustomMutation(
    createOrdersService(adminInstance).putOrderClientInfo,
  );

  const saveChanges = async () => {
    setIsPreloader(true);

    const inputsBlock = document.querySelector('.modal-window-action__modal__inputs-block');

    if (!inputsBlock) {
      return;
    }

    const inputs = inputsBlock.querySelectorAll('input');

    if (Order.isQuickOrder) {
      putQuickOrderClientInfo({
        orderId: Order.id,
        name: inputs[0].value,
        phoneNumber: inputs[1].value,
        email: inputs[2].value,
      });
    } else {
      putOrderClientInfo({
        orderId: Order.id,
        firstName: inputs[0].value,
        lastName: inputs[1].value,
        phoneNumber: inputs[2].value,
        email: inputs[3].value,
      });
    }

    closeModal();
    setIsPreloader(false);
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__edit-client" style={{ width: 650 }}>
        <div className="modal-window-action__modal__title">
          <span>Client</span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__inputs-block">
          {Order?.isQuickOrder ? (
            <div className="modal-window-action__modal__input">
              <label>First Name</label>
              <input type="text" placeholder="Name" defaultValue={Order?.firstName} />
            </div>
          ) : (
            <div className="modal-window-action__modal__inputs-user-name">
              <div className="modal-window-action__modal__input">
                <label>First Name</label>
                <input type="text" placeholder="Name" defaultValue={Order?.firstName} />
              </div>
              <div className="modal-window-action__modal__input">
                <label>Last Name</label>
                <input type="text" placeholder="Surname" defaultValue={Order?.lastName} />
              </div>
            </div>
          )}

          <div className="modal-window-action__modal__input">
            <label>Phone</label>
            <input type="number" placeholder="107500000" defaultValue={Order?.phoneNumber} />
          </div>
          <div className="modal-window-action__modal__input">
            <label>Email</label>
            <input type="text" placeholder="Email" defaultValue={Order?.email} />
          </div>
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={saveChanges}>
            {isPreloader ? <Preloader style={preloaderStyles.DARK} /> : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientEditModal;

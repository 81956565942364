import './AdminOrder.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';

const paymentOptions = ['Not paid', 'Paid'];

interface OrderPaymentStatusProps {
  currentStatus: boolean;
}

const OrderPaymentStatus: React.FC<OrderPaymentStatusProps> = ({ currentStatus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(currentStatus ? 'Paid' : 'Not paid');
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle option selection
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Закрыть выпадающий список при клике вне его
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="order-status" ref={dropdownRef}>
      <div
        className="order-status-select"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          background: selectedOption === 'Paid' ? 'rgb(20 110 51)' : '#72100A',
        }}
      >
        <div className="order-status__current-options">
          <div className="order-payment-status__current-options__name">{selectedOption}</div>
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="order-status__list-options">
          <ul
            style={{
              border: '1px solid #0F1928',
              borderTop: 'none',
              borderRadius: 16,
            }}
          >
            {paymentOptions.map((option, index) => (
              <li
                key={index}
                className="order-status__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor: selectedOption === option ? '#0F1928' : '',
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderPaymentStatus;

import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { ifServerError } from '../error/CustomErorr';
import { setInfo, setType, types } from '../redux/reducers/Error-Reducer';

type ServiceMethod<T, V> = (variables: V) => Promise<{ data: T } | any>;
type PreReturnCallback<T> = (data: T) => void;

const useCustomMutation = <T, V>(
  serviceMethod: ServiceMethod<T, V>,
  preReturnCallback?: PreReturnCallback<T>,
  options?: UseMutationOptions<T | undefined, unknown, V, unknown>,
): UseMutationResult<T | undefined, unknown, V, unknown> => {
  const dispatch = useDispatch();

  const mutationFn = async (variables: V): Promise<T | undefined> => {
    try {
      const response = await serviceMethod(variables);

      if (
        !ifServerError(response, (func: any) => {
          dispatch(setType(types.ERROR));
          dispatch(func);
        })
      ) {
        const data = response.data;
        let modifiedData = data;
        if (preReturnCallback) {
          modifiedData = preReturnCallback(data);
        }
        return modifiedData;
      } else {
        return response;
      }
    } catch (error: any) {
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: error },
        }),
      );
      return error;
    }
  };

  const mutationOptions = {
    ...options,
    onError: (error: any) => {
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: error },
        }),
      );
    },
  };

  return useMutation<T | undefined, unknown, V, unknown>(mutationFn, mutationOptions);
};

export default useCustomMutation;

import React, { useReducer, useState } from 'react';

import DeleteActionIcon from '../../../../../general/assets/svg/DeleteActionIcon';
import DropDownIcon from '../../../../../general/assets/svg/DropDownIcon';
import useKey, { keys } from '../../../../../general/hooks/useKey';

interface Link {
  id: number;
  name: string;
  url: string;
}

interface Errors {
  affiliate?: string;
  firstLinkName?: string;
  firstLinkUrl?: string;
  notCorrectSocialLink?: string;
}

interface UpdateAction {
  type: 'update';
  id: number;
  field: keyof Link;
  value: string;
}

interface SwapAction {
  type: 'swap';
  payload: { from: number; to: number };
}

interface AddAction {
  type: 'add';
}

interface RemoveAction {
  type: 'remove';
  id: number;
}

type Action = UpdateAction | SwapAction | AddAction | RemoveAction;

const staticSocialLinks = ['Teleragm', 'Instagram', 'Viber', 'New name... just enter'];

interface SocialLinkProps {
  index: number;
  link: Link;
  linksLength: number;
  errors: Errors;
  dispatchLinks: React.Dispatch<Action>;
}

const SocialLink: React.FC<SocialLinkProps> = ({
  index,
  link,
  linksLength,
  errors,
  dispatchLinks,
}) => {
  const [socialLinkNameDropDown, setSocialLinkNameDropDown] = useReducer((temp) => !temp, false);
  const [focusedId, setFocusedId] = useState<number | null>(null);

  useKey((event: KeyboardEvent) => {
    if (event.key === keys.ESCAPE && focusedId !== null) setSocialLinkNameDropDown();
  });

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
        <button
          className={`input_symbols_panel drop-down-btn ${
            errors.affiliate ? 'error_required' : ''
          } ${errors.firstLinkName && index === 0 ? 'error_required' : ''}`}
          onClick={setSocialLinkNameDropDown}
        >
          {link.name || 'Choose social link name'}
          <DropDownIcon
            style={{
              transform: socialLinkNameDropDown ? 'rotateZ(180deg)' : '',
            }}
          />
        </button>
        {socialLinkNameDropDown && (
          <div className="drop-down__content">
            {staticSocialLinks.map((staticLink, index) => (
              <React.Fragment key={index}>
                {index === staticSocialLinks.length - 1 ? (
                  <input
                    style={{ flex: '1' }}
                    className="input_symbols_panel"
                    type="text"
                    value={focusedId === link.id ? link.name : ''}
                    onChange={(e) =>
                      dispatchLinks({
                        type: 'update',
                        id: link.id,
                        field: 'name',
                        value: e.target.value,
                      })
                    }
                    placeholder={
                      errors.firstLinkName && index === 0 ? errors.firstLinkName : staticLink
                    }
                    onFocus={() => setFocusedId(link.id)}
                    onBlur={() => setFocusedId(null)}
                  />
                ) : (
                  <div
                    style={{ flex: '1' }}
                    key={index}
                    className={`input_symbols_panel drop-down__ellement  ${
                      link.name === staticLink ? 'disabled-div' : ''
                    }`}
                    onClick={() => {
                      dispatchLinks({
                        type: 'update',
                        id: link.id,
                        field: 'name',
                        value: staticLink,
                      });
                      setSocialLinkNameDropDown();
                    }}
                  >
                    {staticLink}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <input
        className={`input_symbols_panel ${
          (errors.firstLinkUrl || errors.notCorrectSocialLink) && index === 0 && 'error_required'
        }`}
        style={{ flex: '1', height: 'fit-content' }}
        type="text"
        value={errors.notCorrectSocialLink ? '' : link.url}
        onChange={(e) =>
          dispatchLinks({
            type: 'update',
            id: link.id,
            field: 'url',
            value: e.target.value,
          })
        }
        placeholder={
          errors.firstLinkUrl && index === 0
            ? errors.firstLinkUrl
            : errors.notCorrectSocialLink && index === 0
              ? errors.notCorrectSocialLink
              : 'Link'
        }
      />
      <div className="arrows">
        {index > 0 && (
          <button
            className="button"
            onClick={() =>
              dispatchLinks({
                type: 'swap',
                payload: { from: index, to: index - 1 },
              })
            }
          >
            &#x25B2;
          </button>
        )}
        {index < linksLength - 1 && (
          <button
            className="button"
            onClick={() =>
              dispatchLinks({
                type: 'swap',
                payload: { from: index, to: index + 1 },
              })
            }
          >
            &#x25BC;
          </button>
        )}
      </div>
      {linksLength > 1 && (
        <button
          onClick={() => dispatchLinks({ type: 'remove', id: link.id })}
          className="button table-block__tbody__change-item__button"
        >
          <DeleteActionIcon />
        </button>
      )}
    </>
  );
};
export default SocialLink;

import React from 'react';
import TemplateModalWindow from './TemplateModalWindow';

interface NewSubscriberProps {
  isOpen: (isOpen: boolean) => void;
}

const NewSubscriber: React.FC<NewSubscriberProps> = ({ isOpen }) => {
  return (
    <TemplateModalWindow isOpen={isOpen} messageClass="">
      <div className="title">Hello new subscriber</div>
      <div className="text">Thanks for subscribing to our weekly email newsletter.</div>
      <div className="action">
        <button
          className="section-btn-gradient"
          onClick={() => {
            isOpen(false);
          }}
        >
          Ok
        </button>
      </div>
    </TemplateModalWindow>
  );
};

export default NewSubscriber;

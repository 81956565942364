import './Products.scss';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ChooseCategoriIcon from '../../../general/assets/svg/ChooseCategoriIcon';
import CLearFilterIcon from '../../../general/assets/svg/CLearFilterIcon';
import CloseFilterIcon from '../../../general/assets/svg/CloseFilterIcon';
import FilterIcon from '../../../general/assets/svg/FilterIcon';
import ReloadIcon from '../../../general/assets/svg/ReloadIcon';
import SadSmileIcon from '../../../general/assets/svg/SadSmileIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { Category, ProductMax } from '../../../general/types/types';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import Card from '../../components/card/Card';
import CustomFilter from '../../components/filters/custom-filter/CustomFilter';
import PriceFilter from '../../components/filters/price-filter/PriceFilter';
import Pagination from '../../components/pagination/Pagination';
import ProductsSorting from '../../components/sorting/products-sorting/ProductsSorting';

interface ProductsProps {
  isRetail: boolean;
}

const Products: React.FC<ProductsProps> = ({ isRetail }) => {
  const navigate = useNavigate();
  const { category } = useParams();
  const { width } = useResize();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page, pageSize, setPage } = usePagination(12);

  const [filters, setFilters] = useState<any>([]);
  const [isAuth, setIsAuth] = useState(false);
  const [products, setProducts] = useState<any>(null);
  const [isShowMore, setIsShowMore] = useState<number | null>(null);
  const [isSvgFilterColor, setIsSvgFilterColor] = useState(false);
  const [isMobileFilter, setIsMobileFilter] = useState(false);

  const [query, setQuery] = useState<string[]>([]);
  const [price, setPrice] = useState<{ [key: string]: string }>({});
  const [sort, setSort] = useState<{ [key: string]: string }>({});

  const { data: currentCategory } = useCustomQuery<Category>(
    queryKeys.PRODUCT_CATEGORY,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      return data?.find((item: any) => item.url === category);
    },
  );

  const { data: filtersMainMining } = useCustomQuery<any>(
    queryKeys.PRODUCT_FILTERS_MAIN_MINING,
    () => {
      if (!currentCategory) {
        throw new Error('category is undefined');
      }
      return createProductsService(clientInstance).getFiltersMainMining(currentCategory?.id);
    },
    undefined,
    undefined,
    {
      enabled: !!currentCategory,
    },
  );

  const { data: filtersMainGeneralOfCategory } = useCustomQuery<any>(
    queryKeys.PRODUCT_FILTERS_MAIN_GENERAL_OF_CATEGORY,
    () => {
      if (!currentCategory) {
        throw new Error('category is undefined');
      }
      return createProductsService(clientInstance).getFiltersMainGeneralOfCategory(
        currentCategory?.id,
      );
    },
    undefined,
    undefined,
    {
      enabled: !!currentCategory,
    },
  );

  const { isLoading: productsLoading, refetch: getProducts } = useCustomQuery(
    queryKeys.PRODUCTS_CLIENTS,
    () => {
      if (!currentCategory) {
        throw new Error('category is undefined');
      }
      return createProductsService(clientInstance).getProducts(
        currentCategory?.id,
        isRetail,
        pageSize,
        page,
        query,
        price,
        sort,
      );
    },
    undefined,
    undefined,
    {
      enabled: !!currentCategory,
      onSuccess: (data: any) => {
        if (isShowMore === null) {
          window.scrollTo(0, 0);
          setProducts(data);
        }
      },
    },
  );

  const setFilterColor = useCallback(() => {
    if (searchParams.size === 0) {
      setIsSvgFilterColor(false);
      return;
    }

    if (width <= 1024) {
      if (searchParams.size > 1 || searchParams.get('page') === null) {
        setIsSvgFilterColor(true);
      } else {
        setIsSvgFilterColor(false);
      }
    }
  }, [searchParams, width]);

  useEffect(() => {
    const query: string[] = [];
    const price: { [key: string]: string } = {};
    const sort: { [key: string]: string } = {};

    searchParams.forEach((values, key) => {
      if (key === 'PriceFrom') {
        price['PriceFrom'] = values;
      } else if (key === 'PriceTo') {
        price['PriceTo'] = values;
      } else if (key === 'sortColumn') {
        const vsp = values.split('-');
        sort['SortColumn'] = vsp[0];
        sort['SortOrder'] = vsp[1];
      } else {
        values.split(',').forEach((value) => {
          query.push(`${key}=${value}`);
        });
      }
    });

    setQuery(query);
    setPrice(price);
    setSort(sort);

    setFilterColor();
  }, [searchParams, setFilterColor]);

  useEffect(() => {
    if (currentCategory) {
      getProducts();
    }
  }, [getProducts, currentCategory, query, price, sort, page, isShowMore]);

  useEffect(() => {
    setFilters(filtersMainMining === undefined ? filtersMainGeneralOfCategory : filtersMainMining);
  }, [filtersMainMining, filtersMainGeneralOfCategory]);

  const changeView = () => {
    navigate('table');
  };

  const clearFilters = async () => {
    setSearchParams([]);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (width <= 1024) {
      mobileFilter();
    }
  };

  const showMore = async () => {
    const currentPage = page;
    if (isShowMore === null) {
      setIsShowMore(currentPage);
    }

    if (currentCategory) {
      const response = await createProductsService(clientInstance).getProducts(
        currentCategory?.id,
        isRetail,
        pageSize,
        currentPage + 1,
        query,
        price,
        sort,
      );

      setPage(currentPage + 1);

      setProducts((prevProducts: any) => {
        if (!prevProducts) return response;
        const newPartion = response.data;

        return {
          ...newPartion,
          items: [...prevProducts.items, ...newPartion.items],
        };
      });
    } else {
      if (!currentCategory) {
        throw new Error('currentCategory is undefined');
      }
    }
  };

  const mobileFilter = () => {
    setIsMobileFilter(!isMobileFilter);
    const filters = document.querySelector('.products-section-content__filters');

    if (filters) {
      if (filters.classList.contains('table-mobile-version')) {
        document.querySelector('body')?.classList.remove('overflow-hidden');
        filters.classList.remove('table-mobile-version');
      } else {
        document.querySelector('body')?.classList.add('overflow-hidden');
        filters.classList.add('table-mobile-version');
      }
    }
  };

  return (
    <>
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      <div className="products-section page-container">
        <div className="products-section-top">
          <div className="products-section-top__title">
            CATALOG
            <span className="products-section-top__title__sub-text">A large assortment</span>
          </div>
          <div className="products-section-top__product-list-action">
            <div className="products-section-top__sort">
              <span>Sort by </span>
              <ProductsSorting searchParams={searchParams} setSearchParams={setSearchParams} />
              <button className="products-section-top__sort__table-filter" onClick={mobileFilter}>
                <FilterIcon isSvgFilterColor={isSvgFilterColor} />
              </button>
            </div>
            {currentCategory?.areMiningCharacteristics && (
              <div className="products-section-top__table">
                <button className="products-section-top__table-btn" onClick={changeView}>
                  <ChooseCategoriIcon />
                </button>
              </div>
            )}
          </div>
        </div>
        {products && filters ? (
          <div className="products-section-content">
            {Object.keys(filters).length > 0 && (
              <div className="products-section-content__filters">
                <div className="products-section-content__filters__section">
                  <button
                    className="products-section-content__filters__modal_close"
                    onClick={mobileFilter}
                  >
                    <CloseFilterIcon />
                  </button>
                  {currentCategory?.areMiningCharacteristics ? (
                    <>
                      {filters?.countryLocations?.values?.length > 0 && (
                        <CustomFilter
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                          name="countryLocations"
                          query={filters?.countryLocations?.abbreviation}
                          data={filters?.countryLocations?.values}
                          setIsShowMore={setIsShowMore}
                        />
                      )}

                      {products?.items?.length > 0 && products?.maxPrice > 0 && (
                        <PriceFilter
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                          min={products?.minPrice}
                          max={products?.maxPrice}
                          setIsShowMore={setIsShowMore}
                        />
                      )}

                      {Object.keys(filters)?.map((filter, index) => {
                        return (
                          <Fragment key={index}>
                            {filter !== 'countryLocations' && filter !== 'energyConsumptions' && (
                              <CustomFilter
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                key={index}
                                name={filter}
                                query={filters[filter].abbreviation}
                                data={filters[filter].values}
                                setIsShowMore={setIsShowMore}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {products?.maxPrice && (
                        <PriceFilter
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                          min={products?.minPrice}
                          max={products?.maxPrice}
                          setIsShowMore={setIsShowMore}
                        />
                      )}

                      {filters?.map((filter: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            <CustomFilter
                              searchParams={searchParams}
                              setSearchParams={setSearchParams}
                              key={index}
                              name={filter.name}
                              query={filter.name}
                              data={filter.values}
                              isMinig={false}
                              setIsShowMore={setIsShowMore}
                            />
                          </Fragment>
                        );
                      })}
                    </>
                  )}

                  <button
                    className="products-section-content__filters-clear"
                    onClick={clearFilters}
                  >
                    <CLearFilterIcon />
                    Clear filters
                  </button>
                </div>
              </div>
            )}

            {!productsLoading ? (
              <>
                {products?.items?.length > 0 ? (
                  <div className="products-section-content__cards-pagination">
                    <div className="products-section-content__cards">
                      {currentCategory &&
                        products?.items?.map((product: ProductMax, index: number) => (
                          <Card
                            key={index}
                            product={product}
                            setIsAuth={setIsAuth}
                            categoryId={currentCategory?.id}
                            isMining={currentCategory?.areMiningCharacteristics}
                          />
                        ))}
                    </div>
                    <div className="pagination-section">
                      {products?.pageSize < products?.totalCount && products.hasNextPage && (
                        <div className="pagination-section__show-more">
                          <button className="show-more__button" onClick={showMore}>
                            <ReloadIcon />
                            <span>show more</span>
                          </button>
                        </div>
                      )}

                      {products && products.pageSize < products.totalCount && (
                        <Pagination
                          page={page}
                          setPage={setPage}
                          isNextPage={products.hasNextPage}
                          isPrevPage={products.hasPreviousPage}
                          pageSize={products.pageSize}
                          total={products.totalCount}
                          isShowMore={isShowMore}
                          setIsShowMore={setIsShowMore}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="products-section-content__empty-data">
                    <SadSmileIcon />
                    <span>There are no products here yet</span>
                  </div>
                )}
              </>
            ) : (
              <div className="products-section-content__empty-data">
                <Preloader />
              </div>
            )}
          </div>
        ) : (
          <div className="products-section-content__preloader">
            <Preloader />
          </div>
        )}
      </div>
    </>
  );
};

export default Products;

import './ContactForm.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MailIcon from '../../../general/assets/svg/MailIcon';
import { useResize } from '../../../general/hooks/useResize';
import {
  reasons,
  ReasonType,
  setIsModal,
  setIsOpened,
} from '../../../general/redux/reducers/GetInTouch-Reducer';
import { RootState } from '../../../general/redux/store';
import createContactMessagesService from '../../../general/services/contactMessages';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { regexp } from '../../../general/utils/Validations';
import MessageModal from '../modals-window/TemplateModalWindow';
import FormSection from './FormSection';
import NewMessage from './NewMessage';

const ContactForm: React.FC = () => {
  const resize = useResize();
  const dispatch = useDispatch();

  const isOpened = useSelector((state: RootState) => state.getInTouch.isOpened);
  const isModal = useSelector((state: RootState) => state.getInTouch.isModal);
  const reasonGetInTouch = useSelector((state: RootState) => state.getInTouch.reason);

  const [isPreloader, setIsPreloader] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);

  useEffect(() => {
    const sections = window.location.href.split('/');
    dispatch(setIsOpened(sections.includes('pool') || sections.includes('hosting')));
  }, [dispatch]);

  const setDefaultInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.style.border = '1px solid #25A8CF';
  };

  const sendMessage = async () => {
    setIsPreloader(true);
    const inputsBlock = document.getElementById('contact-form') as HTMLElement;
    const inputs = inputsBlock.querySelectorAll('input');
    const textarea = inputsBlock.querySelector('textarea') as HTMLTextAreaElement;

    const required: boolean[] = [];

    if (!regexp.string.test(inputs[0].value.trim())) {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (!regexp.email_regexp.test(inputs[1].value.trim())) {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (!regexp.string.test(inputs[2].value.trim())) {
      inputs[2].style.border = '1px solid red';
      required.push(false);
    }
    if (!regexp.phone_regexp.test(inputs[3].value.trim())) {
      inputs[3].style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      const result = {
        name: inputs[0].value,
        email: inputs[1].value,
        companyName: inputs[2].value,
        phoneNumber: inputs[3].value,
        reason: reasons.includes(reasonGetInTouch as ReasonType) ? reasonGetInTouch : '',
        message: textarea.value,
      };

      try {
        await createContactMessagesService(clientInstance).postMessage(result);
        setIsNewMessage(true);
        dispatch(setIsOpened(!isOpened));
        inputs.forEach((item) => {
          item.value = '';
        });
        textarea.value = '';
        inputsBlock.classList.remove('form-is-open');
        inputsBlock.classList.add('form-is-close');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
    setIsPreloader(false);
  };

  return (
    <>
      {isModal && resize.width <= 768 && (
        <MessageModal messageClass="" isOpen={() => dispatch(setIsModal(!isModal))}>
          <div id="get-in-touch" className="send-message-section page-container">
            <div className="send-message-section__content">
              <FormSection
                sendMessage={sendMessage}
                setDefaultInput={setDefaultInput}
                preloader={isPreloader}
              />
            </div>
          </div>
        </MessageModal>
      )}
      {isNewMessage && <NewMessage isOpen={setIsNewMessage} />}
      <section id="get-in-touch" className="send-message-section page-container">
        <div className="send-message-section__content">
          <div className="send-message-section__content-title">
            <h1 className="content-title__h1 section-h1">GET IN TOUCH NOW</h1>
            <p className="content-title__p section-p">Questions about our service?</p>
            <button
              className="content-title__button"
              onClick={() => dispatch(setIsOpened(!isOpened))}
            >
              <MailIcon />
              {isOpened && <span>Send Message</span>}
            </button>
          </div>
          <FormSection
            sendMessage={sendMessage}
            setDefaultInput={setDefaultInput}
            preloader={isPreloader}
          />
        </div>
      </section>
    </>
  );
};

export default ContactForm;

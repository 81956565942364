import React from 'react';

const MessagesIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 5.81323H2V26.1732H30V5.81323Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2 5.81323L16 19.8132L30 5.81323"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default MessagesIcon;

import React from 'react';

const ProfileIconEditName: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.567 24.5666H8.94693C2.95576 24.5666 0.395996 22.0069 0.395996 16.0159V9.39592C0.395996 3.40487 2.95576 0.845154 8.94693 0.845154H11.1536C11.606 0.845154 11.9811 1.22028 11.9811 1.67265C11.9811 2.12501 11.606 2.50014 11.1536 2.50014H8.94693C3.8605 2.50014 2.05102 4.30959 2.05102 9.39592V16.0159C2.05102 21.1022 3.8605 22.9116 8.94693 22.9116H15.567C20.6534 22.9116 22.4629 21.1022 22.4629 16.0159V13.8092C22.4629 13.3569 22.8381 12.9817 23.2904 12.9817C23.7428 12.9817 24.1179 13.3569 24.1179 13.8092V16.0159C24.1179 22.0069 21.5582 24.5666 15.567 24.5666Z"
      fill="white"
    />
    <path
      d="M8.39453 18.9839C7.72149 18.9839 7.10361 18.7412 6.65124 18.2999C6.1106 17.7593 5.8789 16.9759 6.00027 16.1484L6.47471 12.8274C6.56297 12.1875 6.98225 11.36 7.43462 10.9076L16.1289 2.21336C18.3246 0.0177462 20.5534 0.0177462 22.749 2.21336C23.9517 3.41599 24.4923 4.64068 24.382 5.86537C24.2827 6.85836 23.7531 7.82928 22.749 8.82228L14.0546 17.5165C13.6023 17.9689 12.7748 18.3881 12.1348 18.4764L8.8138 18.9508C8.67037 18.9839 8.52693 18.9839 8.39453 18.9839ZM17.2985 3.38289L8.60417 12.0771C8.39453 12.2868 8.15179 12.7722 8.10766 13.0591L7.63322 16.3801C7.58909 16.7001 7.65529 16.9649 7.82079 17.1304C7.98629 17.2959 8.2511 17.3621 8.57107 17.3179L11.8921 16.8435C12.179 16.7994 12.6755 16.5566 12.8741 16.347L21.5684 7.65275C22.2856 6.93559 22.6607 6.29566 22.7159 5.69987C22.7821 4.98271 22.407 4.22141 21.5684 3.37185C19.8031 1.60653 18.5894 2.10303 17.2985 3.38289Z"
      fill="white"
    />
    <path
      d="M20.9178 10.3115C20.8406 10.3115 20.7633 10.3004 20.6971 10.2784C17.7953 9.46189 15.4894 7.15595 14.6729 4.2542C14.5515 3.81287 14.8053 3.36051 15.2466 3.22811C15.688 3.10674 16.1403 3.36051 16.2617 3.80184C16.9237 6.15192 18.7884 8.01654 21.1385 8.67853C21.5798 8.7999 21.8336 9.2633 21.7122 9.70463C21.6129 10.0798 21.2819 10.3115 20.9178 10.3115Z"
      fill="white"
    />
  </svg>
);

export default ProfileIconEditName;

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import { ShippingCost } from '../../../../general/types/types';

const ShippingCostComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [shippingCost, setShippingCost] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const { data: shippingCostData } = useCustomQuery<ShippingCost>(
    queryKeys.SHIPPING_COST,
    () => createShippingService(adminInstance).getInfos(),
    (data: any) => ({ lastId: data[0].id, shippingCost: data[0].shippingCost }),
  );

  const { mutate: saveShippingCost } = useCustomMutation(
    createShippingService(adminInstance).setShippingCost,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Shipping cost successfully updated' },
          }),
        );
      },
    },
  );

  useEffect(() => {
    if (shippingCostData) {
      setShippingCost(shippingCostData.shippingCost);
    }
  }, [shippingCostData]);

  const handleShippingCost = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setShippingCost(value);

    // Проверка, содержит ли значение только цифры
    const valid = /^[0-9]*[.,]?[0-9]+$/.test(value);
    setIsValid(valid);
  };

  const handleSaveClick = async () => {
    if (isValid && shippingCost) {
      let cost = parseFloat(shippingCost);

      if (!Number.isInteger(cost)) {
        cost = parseFloat(cost.toFixed(2));
      }

      if (shippingCostData) {
        saveShippingCost({
          infosId: shippingCostData?.lastId,
          cost,
        });
      }
    } else {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'Must contains only numbers' },
        }),
      );
    }
  };

  return (
    <>
      <span className="block-header">Shipping price</span>
      <div>
        <input
          type="number"
          placeholder="Shipping price"
          value={shippingCost}
          onChange={handleShippingCost}
        />
        {shippingCost && (
          <button className="section-btn-gradient" onClick={handleSaveClick}>
            Save
          </button>
        )}
      </div>
    </>
  );
};

export default ShippingCostComponent;

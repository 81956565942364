import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import CartProdIcon from '../../../../general/assets/svg/CartProdIcon';
import CloseModalCrossIcon from '../../../../general/assets/svg/CloseModalCrossIcon';
import { addCartItem } from '../../../../general/redux/reducers/ClientCart-Reducer';
import { clientWishlist } from '../../../../general/redux/reducers/ClientProfile-Reducer';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import { ProductMax } from '../../../../general/types/types';

interface WishlistItemProps {
  userId: string;
  product: ProductMax;
}

const WishlistItem: React.FC<WishlistItemProps> = ({ userId, product }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarActive, setIsSnackbarActive] = useState(false);

  const showSnackbar = (message: SnackbarMessage, variant: VariantType) => {
    if (!isSnackbarActive) {
      setIsSnackbarActive(true);
      enqueueSnackbar(message, {
        variant,
        onClose: () => {
          setIsSnackbarActive(false);
        },
      });
    }
  };

  const BtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showSnackbar('Added to cart', 'success');
    dispatch(addCartItem({ p: product, c: 1, url: location.pathname }));
  };

  const updateClientWishList = async () => {
    const response = await createClientService(clientInstance).getClienWishList(userId);
    dispatch(clientWishlist(response.data));
  };

  const deleteItem = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await createClientService(clientInstance).removeFromClientWishlist({
      userId,
      productId: product.id,
    });
    updateClientWishList();
  };

  return (
    <li>
      <Link to={`/${product?.url}`}>
        <div className="wishlist-item-info">
          <div className="wishlist-item-info__img">
            <img src={product?.images[0]?.url || images.defaultImg} alt={product.name} />
          </div>
          <div className="wishlist-item-info__name">
            <span>{product.name}</span>
            <span>{product.manufacturer}</span>
          </div>
          <div className="wishlist-item-info__price">
            {product?.percentageDiscount === 0 ? (
              `${product?.price.toFixed(2)}$`
            ) : (
              <>
                <span className="wishlist__price-without-discount">{`${product?.price.toFixed(
                  2,
                )}$`}</span>
                <span className="wishlist__price-with-discount">{`${(
                  product?.price -
                  product?.price * (product?.percentageDiscount / 100)
                ).toFixed(2)}$`}</span>
              </>
            )}
          </div>
        </div>
        <div className="wishlist-item-action">
          <button className="wishlist-item-action__btn" onClick={BtnClick}>
            <CartProdIcon />
          </button>
          <button className="wishlist-item-action__delete" onClick={deleteItem}>
            <CloseModalCrossIcon />
          </button>
        </div>
      </Link>
    </li>
  );
};

export default WishlistItem;

import React from 'react';

const PriceFilterSeparatorIcon: React.FC = () => (
  <svg
    className="filter-price-svg"
    width="20"
    height="2"
    viewBox="0 0 20 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="1.14197" x2="20" y2="1.14197" stroke="#B8C6CC" />
  </svg>
);

export default PriceFilterSeparatorIcon;

import { AxiosInstance } from 'axios';

import { TransactionProcessAddress } from '../types/types';

interface ShippingService {
  addImageToHosting: (data: { hostingLocationId: string; file: File }) => Promise<any>;
  createImageForHosting: (file: File) => Promise<any>;
  removeImgFromHosting: (imgId: string) => Promise<any>;
  updateHostingLocation: (data: {
    id: string;
    locationName?: string;
    state?: string;
    characteristics?: {
      key: string;
      value: string;
    }[];
    mapPoinerLocationX: number;
    mapPoinerLocationY: number;
    isActive: boolean;
  }) => Promise<any>;
  createHostingLocation: (data: {
    locationName: string;
    state: string;
    characteristics: {
      key: string;
      value: string;
    }[];
    imagesIds: string[];
    mapPoinerLocationX: number;
    mapPoinerLocationY: number;
    isActive: boolean;
  }) => Promise<any>;
  deteHostingLocation: (id: string) => Promise<any>;
  getHostingLocationStates: () => Promise<any>;
  getHostingLocations: (pageSize: number, page: number, searchQuery: string) => Promise<any>;
  ValidateAddress: (body: any) => Promise<any>;
  GetShippingRates: (body: any) => Promise<any>;
  CreateTransaction: (body: any) => Promise<any>;
  RunTracking: (rate: string, trackingNumber: string) => Promise<any>;
  getInfos: () => Promise<any>;
  setShippingCost: (body: { infosId: string; cost: number }) => Promise<any>;
  saveNewProdLocation: (country: string) => Promise<any>;
  getAvailableProdLocations: () => Promise<any>;
  removeAvailableProdLocation: (id: string) => Promise<any>;
  getProductsStores: (pageSize: number, page: number, searchQuery: string) => Promise<any>;
  deleteProductsStore: (id: string) => Promise<any>;
  createPickUpTransaction: (data: {
    locations: string[];
    billingAddress: { address: TransactionProcessAddress };
  }) => Promise<any>;
  createProductsStore: (data: {
    country: string;
    address: string;
    status: boolean;
    mapFrame: string;
  }) => Promise<any>;
  updateProductsStore: (data: {
    id: string;
    country: string;
    address: string;
    status: boolean;
    mapFrame: string;
  }) => Promise<any>;
}

const transformCharacteristics = (
  characteristics: {
    key: string;
    value: string;
  }[],
) => {
  let transformedCharacteristics = characteristics.reduce(
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>,
  );

  return transformedCharacteristics;
};

const createShippingService = (instance: AxiosInstance): ShippingService => ({
  async removeImgFromHosting(imgId: string) {
    try {
      const response = await instance.delete(`/HostingLocations/delete-image?ImgId=${imgId}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async createImageForHosting(file: File) {
    const formData = new FormData();
    formData.append('Image', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(`/HostingLocations/add-image/freedom`, formData, {
        headers: headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async addImageToHosting(data: { hostingLocationId: string; file: File }) {
    const formData = new FormData();
    formData.append('HostingLocationId', data.hostingLocationId);
    formData.append('Image', data.file);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(`/HostingLocations/add-image`, formData, {
        headers: headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async updateHostingLocation(data: {
    id: string;
    locationName?: string;
    state?: string;
    characteristics?: {
      key: string;
      value: string;
    }[];
    mapPoinerLocationX?: number;
    mapPoinerLocationY?: number;
    isActive: boolean;
  }) {
    let transformedCharacteristics;

    if (data.characteristics) {
      transformedCharacteristics = transformCharacteristics(data.characteristics);
    }

    const transformedData: Record<string, any> = {};
    transformedData.id = data.id;
    transformedData.isActive = data.isActive;

    if (data.locationName !== undefined) {
      transformedData.locationName = data.locationName;
    }

    if (data.state !== undefined) {
      transformedData.state = data.state;
    }

    if (data.mapPoinerLocationX !== undefined && data.mapPoinerLocationY !== undefined) {
      transformedData.mapPoinerLocationX = data.mapPoinerLocationX;
      transformedData.mapPoinerLocationY = data.mapPoinerLocationY;
    }

    if (transformedCharacteristics !== undefined) {
      transformedData.characteristics = transformedCharacteristics;
    }

    try {
      const response = await instance.put(`/HostingLocations`, transformedData);
      return response;
    } catch (error) {
      return error;
    }
  },

  async createHostingLocation(data: {
    locationName: string;
    state: string;
    characteristics: {
      key: string;
      value: string;
    }[];
    imagesIds: string[];
    mapPoinerLocationX: number;
    mapPoinerLocationY: number;
    isActive: boolean;
  }) {
    const transformedCharacteristics = transformCharacteristics(data.characteristics);
    const transformedData: {
      locationName: string;
      state: string;
      characteristics: Record<string, string>;
      imagesIds?: string[];
      mapPoinerLocationX: number;
      mapPoinerLocationY: number;
      isActive: boolean;
    } = {
      locationName: data.locationName,
      state: data.state,
      characteristics: transformedCharacteristics,
      mapPoinerLocationX: data.mapPoinerLocationX,
      mapPoinerLocationY: data.mapPoinerLocationY,
      isActive: data.isActive,
    };

    if (data.imagesIds.length > 0) {
      transformedData.imagesIds = data.imagesIds;
    }

    try {
      const response = await instance.post(`/HostingLocations`, transformedData);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deteHostingLocation(id: string) {
    try {
      const response = await instance.delete(`/HostingLocations?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getHostingLocationStates() {
    try {
      const response = await instance.get(`/HostingLocations/states`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getHostingLocations(pageSize: number, page: number, searchQuery: string) {
    try {
      const response = await instance.get(
        `/HostingLocations?SearchQuery=${searchQuery}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async createPickUpTransaction(data: {
    locations: string[];
    billingAddress: { address: TransactionProcessAddress };
  }) {
    try {
      const response = await instance.post(`/GoShippo/createPickUpTransaction`, {
        productsStoresTransactions: data.locations,
        address: data.billingAddress.address,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async updateProductsStore(data: {
    id: string;
    country: string;
    address: string;
    status: boolean;
    mapFrame: string;
  }) {
    try {
      const response = await instance.put(`/ProductStores`, {
        id: data.id,
        country: data.country,
        address: data.address,
        status: data.status,
        frame: data.mapFrame,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async createProductsStore(data: {
    country: string;
    address: string;
    status: boolean;
    mapFrame: string;
  }) {
    try {
      const response = await instance.post(`/ProductStores`, {
        country: data.country,
        address: data.address,
        status: data.status,
        frame: data.mapFrame,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteProductsStore(id: string) {
    try {
      const response = await instance.delete(`/ProductStores?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getProductsStores(pageSize: number, page: number, searchQuery?: string) {
    try {
      const response = await instance.get(
        `/ProductStores?PageSize=${pageSize}&Page=${page}&SearchQuery=${searchQuery}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async removeAvailableProdLocation(id: string) {
    try {
      const response = await instance.delete(`/AvailableLocations?LocationId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAvailableProdLocations() {
    try {
      const response = await instance.get(`/AvailableLocations`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async saveNewProdLocation(country: string) {
    try {
      const response = await instance.post(`/AvailableLocations`, {
        country: country,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getInfos() {
    try {
      const response = await instance.get(`/StaticPaymentInfos/getInfos`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async setShippingCost(body: { infosId: string; cost: number }) {
    try {
      const response = await instance.post(`/StaticPaymentInfos/setShippingCost`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async ValidateAddress(body: any) {
    try {
      const responce = await instance.post('/GoShippo/valideAddress', body);
      return responce;
    } catch (error) {
      return error;
    }
  },

  async GetShippingRates(body: any) {
    try {
      const responce = await instance.post('/GoShippo/getRates', body);
      return responce;
    } catch (error) {
      return error;
    }
  },

  async CreateTransaction(body: any) {
    try {
      const responce = await instance.post('/GoShippo/makeTransaction', body);
      return responce;
    } catch (error) {
      return error;
    }
  },

  // not use
  async RunTracking(rate: string, trackingNumber: string) {
    try {
      const responce = await instance.get(
        `/GoShippo/runTracking?reate=${rate}&trackingNumber=${trackingNumber}`,
      );

      return responce;
    } catch (error) {
      return error;
    }
  },
});

export default createShippingService;

import { AxiosInstance } from 'axios';

interface contactMessagesService {
  postMessage: (data: any) => Promise<any>;
  postSimpleMessage: (data: any) => Promise<any>;
}

const createContactMessagesService = (instance: AxiosInstance): contactMessagesService => ({
  async postMessage(data: any) {
    try {
      const response = await instance.post(`/ContactMessage`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async postSimpleMessage(data: any) {
    try {
      const response = await instance.post(`/ContactMessage/simple`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
});

export default createContactMessagesService;

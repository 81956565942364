import './AdminList.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createAdminsService from '../../../../general/services/admins';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { getDataForage } from '../../../../localforage';
import AdminSearch from '../../../components/search/Search';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import ActionButtons from './ActionButtons';
import AdminModal from './AdminModal';

const AdminList: React.FC = () => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [adminId, setAdminId] = useState<string>('');
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const actionButtons = (item: any, deleteMethod: any, refreshMethod: any) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} refreshMethod={refreshMethod} />
  );

  const {
    data: admins,
    isLoading: adminsLoading,
    refetch: getData,
  } = useCustomQuery<any>(queryKeys.ADMINS, () =>
    createAdminsService(adminInstance).getAllAdmins(searchQuery, pageSize, page),
  );

  const { mutate: deleteAdmin } = useCustomMutation(
    createAdminsService(adminInstance).deleteAdmin,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Admin sucessfully removed' },
          }),
        );

        getData();
      },
    },
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const data = await getDataForage<any>('a4ad');
        const parsedData = JSON.parse(data || '{}');
        const userId = parsedData?.a4d || '';
        setAdminId(userId);
      } catch (err) {
        console.error('Error parsing user data:', err);
        return null;
      }
    };

    fetchUserId();
  }, []);

  const openModal = () => {
    setIsModal(true);
  };

  const deleteItem = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;
    if (id) {
      deleteAdmin({
        id,
        authorizedAdminId: adminId,
      });
    } else {
      console.error('ID is not found in the dataset');
    }
  };

  return (
    <>
      {isModal && <AdminModal isOpen={setIsModal} refreshMethod={getData} />}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={openModal}>
            <AddIcon color="white" />
            <span>Add admin</span>
          </button>
        </div>
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by name'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {adminsLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={admins ? admins?.items : []}
              headers={['login', 'user-name', 'email']}
              deleteMethod={deleteItem}
              actionComponent={actionButtons}
              isAction={true}
              refreshMethod={getData}
            />
          </div>
        </div>
        {admins && admins?.pageSize < admins?.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={admins.hasNextPage}
            isPrevPage={admins.hasPreviousPage}
            total={admins.totalCount}
          />
        )}
      </div>
    </>
  );
};

export default AdminList;

import './AdminClientList.scss';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import InfoIconAction from '../../../../general/assets/svg/InfoIconAction';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import createClientService from '../../../../general/services/client';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { Client, PaggingObj } from '../../../../general/types/types';
import AdminSearch from '../../../components/search/Search';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';

interface ActionButtonsProps {
  item: Client;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item }) => {
  return (
    <>
      <Link to={`/admin/clients/${item.id}`} style={{ width: 'auto' }}>
        <InfoIconAction />
      </Link>
    </>
  );
};

const AdminClientList: React.FC = () => {
  const actionButtons = (item: Client) => <ActionButtons item={item} />;
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const {
    data: clients,
    isLoading: clientsLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Client>>(queryKeys.ADMIN_CLIENTS, () =>
    createClientService(adminInstance).getAllCLients(searchQuery, pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  return (
    <div className="page-section__content">
      <div className="page-section__buttons"></div>
      <div>
        <AdminSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={'Search by firstname, lastname, phone, email'}
        />
      </div>
      <div className="page-section__content__list-section">
        <div className="list-section__content">
          {clientsLoading && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          <CustomTable
            data={clients ? clients?.items : []}
            headers={['user-name', 'phone', 'email']}
            actionComponent={actionButtons}
          />
        </div>
      </div>
      {clients && clients?.pageSize < clients?.totalCount && (
        <CustomPagination
          currentPage={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          isNextPage={clients.hasNextPage}
          isPrevPage={clients.hasPreviousPage}
          total={clients.totalCount}
        />
      )}
    </div>
  );
};

export default AdminClientList;

import React, { useReducer, useState } from 'react';

import ShareIcon from '../../../../general/assets/svg/ShareIcon';
import useKey from '../../../../general/hooks/useKey';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface ShareProps {
  item: {
    referralLink: string;
  };
}

const Share: React.FC<ShareProps> = ({ item }) => {
  const [link, setLink] = useState(item.referralLink);
  const [isShare, setIsShare] = useReducer((temp) => !temp, false);

  const [buttonText, setButtonText] = useState('Copy');

  const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  useKey((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      isShare && setIsShare();
    }
  });

  const copyToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      try {
        await navigator.clipboard.writeText(text);
        setButtonText('Copied');
        setTimeout(() => {
          setButtonText('Copy');
        }, 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      console.error('Clipboard API not available.');
    }
  };

  return (
    <>
      <button onClick={() => setIsShare()} className="table-block__tbody__change-item__button">
        <ShareIcon />
      </button>
      {isShare && (
        <AdminModalWindow
          title={'Share referral link'}
          isOpen={() => {
            setIsShare();
            setLink(item.referralLink);
          }}
          mWidth={'50%'}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <input
              className="input_symbols_panel"
              type="text"
              value={link}
              onChange={handleChangeLink}
            />
            <button className="section-btn-gradient" onClick={() => copyToClipboard(link)}>
              {buttonText}
            </button>
          </div>
        </AdminModalWindow>
      )}
    </>
  );
};

export default Share;

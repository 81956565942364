import './Pool.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

import useScrollTo from '../../../general/hooks/useScrollTo';
import { setIsOpened } from '../../../general/redux/reducers/GetInTouch-Reducer';
import { images, partnerLinks } from '../../../general/StaticData';
import ContactForm from '../../components/contact-form/ContactForm';

interface Possibility {
  img: string;
  name: string;
  text: string;
}

const Pool = () => {
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();

  const possibilities: Possibility[] = [
    {
      img: images.poolPosibility1,
      name: 'Diverse Coin Mining',
      text: "Whether you're mining Bitcoin or exploring other promising cryptocurrencies, the pool has got you covered. Enjoy the flexibility to mine different coins, maximizing your profitability and diversifying your portfolio",
    },
    {
      img: images.poolPosibility2,
      name: 'Top-Tier Profitability',
      text: 'The pool consistently ranks among the top pools in terms of profitability, ensuring that you get the most out of your mining efforts. The easy registration process makes it simple to get started, so you can start earning right away',
    },
    {
      img: images.poolPosibility3,
      name: 'Global Connectivity',
      text: 'Connect globally and mine locally with dedicated EMCD servers across North America, Europe, and Asia. Enjoy low-latency mining operations, ensuring efficient and reliable performance, no matter where you are located',
    },
  ];

  const goToPartner = () => {
    window.open(partnerLinks.ecmdIo, '_blank');
  };

  return (
    <div className="services_pool page-container">
      <div className="map-section">
        <h1>
          MINING <span>POOL</span>
        </h1>
        <h2>
          JOIN OUR OFFICIAL <span className="simple-text">EMCD POOL</span> PARTNERSHIP FOR MAXIMIUM
          RETURNS
        </h2>
        <div className="buttons">
          <button
            onClick={() => {
              //dispatch(setIsModal(true)); // For mobile
              dispatch(setIsOpened(true));
              scrollTo('get-in-touch');
            }}
          >
            Request Info
          </button>
          <button onClick={goToPartner}>Get Started</button>
        </div>
      </div>
      <div className="info-container_section">
        <div className="low-fees">
          <p className="number">1,5%</p>
          <span>LOW FEES</span>
          <p>
            With fees as low as 1.5%, you’ll save more on every transaction, maximizing your mining
            rewards and boosting your profits.
          </p>
        </div>
        <div className="hight-fills">
          <p className="number">8% & 14%</p>
          <span>HIGH RETURNS</span>
          <p>
            You can store your mined coins and earn competitive APY rates: up to 8% for BTC and up
            to 14% for USDT/USDC. Ensure your assets work for you.
          </p>
        </div>
      </div>
      <div className="abilites">
        <p>Seamless Fiat Withdrawals</p>
        <p>
          Tired of dealing with multiple platforms to{' '}
          <span style={{ color: '#25A8CF' }}>convert</span> your crypto earnings into fiat currency?
          Say goodbye to hassle. The service's <span style={{ color: '#25A8CF' }}>single app</span>{' '}
          allows you to effortlessly withdraw your earnings in fiat currencies, simplifying the
          process like never before. Real-time cryptocurrency prices from{' '}
          <span style={{ color: '#25A8CF' }}>CoinMarketCap</span> ensure fair and transparent
          conversions.
        </p>
      </div>
      <div className="possibilities">
        {possibilities.map((item) => (
          <div key={item.name} className="item">
            <div
              className="img"
              style={{
                backgroundImage: `url(${item.img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />
            <div className="name">{item.name}</div>
            <div className="text">{item.text}</div>
          </div>
        ))}
      </div>
      <div className="offer-section">
        <p>Exclusive Initial Offer</p>
        <p>
          Unlock a generous 20% initial discount for the first two weeks by using our referral link.
          Available exclusively for new users!
        </p>
        <button onClick={goToPartner}>Join Now</button>
      </div>
      <div className="statistic">
        <div>
          <p>TOP-7</p>
          <span>Among the largest Bitcoin mining pools</span>
        </div>
        <div>
          <p>&gt;270 000</p>
          <span>Satisfied customers making money on crypto</span>
        </div>
        <div>
          <p>&gt;6 years</p>
          <span>On the digital assets mining market</span>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Pool;

import './CustomFilter.scss';

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

const CurrentName: React.FC<{ name: string }> = ({ name }) => {
  switch (name) {
    default:
      return <>{name.slice(0, -1)}</>;
    case 'countryLocations':
      return <>Available in Country</>;
    case 'energyConsumptions':
      return <>Power consumption</>;
    case 'energyEfficiencies':
      return <>Energy efficiency</>;
    case 'hashrates':
      return <>Hashrate</>;
  }
};

interface CustomFilterProps {
  setIsShowMore: (value: number | null) => void;
  isMinig?: boolean;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  name: string;
  query: string;
  data: string[];
}

const CustomFilter: React.FC<CustomFilterProps> = ({
  setIsShowMore,
  isMinig = true,
  searchParams,
  setSearchParams,
  name,
  query,
  data,
}) => {
  const useFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const filter = e.target.id.split('+');
      const newParams = new URLSearchParams(searchParams.toString());

      let values = newParams.get(query)?.split(',');

      if (values) {
        if (values.includes(filter[1])) {
          values = values.filter((currentValue) => currentValue !== filter[1]);
        } else {
          values.push(filter[1]);
        }

        if (values.length) {
          newParams.set(query, values.join(','));
        } else {
          newParams.delete(query);
        }
      } else {
        newParams.set(query, [filter[1]].join(','));
      }

      setIsShowMore(null);
      setSearchParams(newParams);
    },
    [query, searchParams, setIsShowMore, setSearchParams],
  );

  const [isShowMoreOpen, setIsShowMoreOpen] = useState(false);

  const showMoreFilters = useCallback(() => {
    setIsShowMoreOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (searchParams.size === 0) {
      document
        .querySelectorAll<HTMLInputElement>('.filter-actions__checkbox-input')
        .forEach((item) => {
          item.checked = false;
        });
    }
  }, [searchParams]);

  return (
    <div className="filter-box">
      <div className="filter-title">{isMinig ? <CurrentName name={name} /> : <>{name}</>}</div>
      <div className="filter-actions">
        {data.map((item, index) => (
          <div
            className="filter-actions__checkbox"
            key={index}
            style={{ display: index > 4 && !isShowMoreOpen ? 'none' : '' }}
          >
            <input
              className="filter-actions__checkbox-input"
              type="checkbox"
              onChange={useFilter}
              name={query}
              id={`${query}+${item}`}
              defaultChecked={searchParams?.get(query)?.split(',')?.includes(item.toString())}
            />
            <label htmlFor={`${query}+${item}`}>{item}</label>
          </div>
        ))}
      </div>
      {data?.length > 4 && (
        <button className="filter-actions__show-more" onClick={showMoreFilters}>
          {isShowMoreOpen ? 'HIDE' : 'SHOW MORE'}
        </button>
      )}
    </div>
  );
};

export default CustomFilter;

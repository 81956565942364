import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AdminReducer from './reducers/Admin-Reducer';
import AffiliateReducer from './reducers/Affiliate-Reducer';
import ClientCartReducer from './reducers/ClientCart-Reducer';
import ClientCartProcessingReducer from './reducers/ClientCartProcessing-Reducer';
import ClientCompareReducer from './reducers/ClientCompare-Reducer';
import ClientProfileReducer from './reducers/ClientProfile-Reducer';
import ClientsTrackingReducer from './reducers/ClientsTracking-Reducer';
import CookiePolicyReducer from './reducers/CookiePolicy-Reducer';
import ErrorReducer from './reducers/Error-Reducer';
import GetInTouchReducer from './reducers/GetInTouch-Reducer';
import HomeStaticInfoReducer from './reducers/HomeStaticInfo-Reducer';
import HostingReducer from './reducers/HostingLocations-Reducer';
import ProductReducer from './reducers/Product-Reducer';
import ReferralCodeReducer from './reducers/ReferralCode-Reducer';
import StoresReducer from './reducers/Stores-Reducer';

const rootReducer = combineReducers({
  a4: AdminReducer,
  clps: ClientProfileReducer,
  e6: ErrorReducer,
  acp: CookiePolicyReducer,
  pc: ClientCartReducer,
  compare: ClientCompareReducer,
  affiliate: AffiliateReducer,
  referralCode: ReferralCodeReducer,
  clientsTracking: ClientsTrackingReducer,
  clientCartProcessing: ClientCartProcessingReducer,
  getInTouch: GetInTouchReducer,
  homeStatic: HomeStaticInfoReducer,
  store: StoresReducer,
  product: ProductReducer,
  hosting: HostingReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'a4',
    'clps',
    'pc',
    'acp',
    'compare',
    'affiliate',
    'referralCode',
    'clientsTracking',
    'clientCartProcessing',
    'getInTouch',
    'homeStatic',
    'store',
    'hosting',
    'product',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persister = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;

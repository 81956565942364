import React from 'react';

const CalendarIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.52 4.11816H1.47998V8.89816H22.52V4.11816Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.52 8.89795H1.47998V23.2479H22.52V8.89795Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M5.2998 13.6782H7.2198" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M9.12988 13.6782H11.0399" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M12.96 13.6782H14.87" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M16.7798 13.6782H18.6998" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M16.7798 18.4683H18.6998" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M5.2998 18.4683H7.2198" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M9.12988 18.4683H11.0399" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M12.96 18.4683H14.87" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M6.25977 1.24805V6.02805" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M12 1.24805V6.02805" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M17.7402 1.24805V6.02805" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default CalendarIcon;

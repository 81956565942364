import React from 'react';
import { Link } from 'react-router-dom';

import BucketIcon from '../../../general/assets/svg/BucketIcon';
import { images } from '../../../general/StaticData';
import { ProductMax } from '../../../general/types/types';

interface ProductCardProps {
  index: number;
  product: ProductMax;
  generalInfo: any;
  remove: (id: number) => void;
  isMiningCategory?: boolean;
}

const miningCharacteristics = [
  { name: 'Condition', key: 'condition' },
  { name: 'Manufacturer', key: 'manufacturer' },
  { name: 'Country', key: 'countryLocation' },
  { name: 'Algorithm', key: 'algorithm' },
  { name: 'Hashrate', key: 'hashrate' },
  { name: 'Energy efficiency', key: 'energyEfficiency' },
  { name: 'Power Consumption', key: 'energyConsumption' },
];

const ProductCard: React.FC<ProductCardProps> = ({
  index,
  product,
  generalInfo,
  remove,
  isMiningCategory,
}) => {
  const {
    price,
    percentageDiscount,
    manufacturer,
    name,
    amount,
    images: productImages,
  } = isMiningCategory === undefined ? product : generalInfo;
  const discount =
    isMiningCategory === undefined ? percentageDiscount : generalInfo.percentageDiscount;
  const imgUrl = productImages?.[0]?.url || images.defaultImg;

  const getBatch = () => {
    const batchInfo = isMiningCategory === undefined ? product?.batch : generalInfo?.batch;
    return batchInfo
      ? new Date(batchInfo)?.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })
      : '';
  };

  const manufacturerName =
    isMiningCategory !== undefined &&
    product.some((item: ProductMax) => item.name === 'Manufacturer')
      ? product.find((item: ProductMax) => item.name === 'Manufacturer')?.value
      : manufacturer;

  return (
    <div key={index} className="product-card">
      <div className="product-section__info-header">
        <Link to={`/${generalInfo?.url}`}>
          <div className="img-container">
            <div
              style={{
                backgroundImage: `url(${imgUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            ></div>
          </div>
          <div>
            <div className="product-section__info-name">
              <div className="product-section__info-name__firm">{manufacturerName}</div>
              <div className="product-section__info-name__firm-actions">
                <div className="product-section__info-name__name">
                  {isMiningCategory !== undefined ? generalInfo?.name : name}
                </div>
              </div>
            </div>
            <div className="product-section__info-price">
              <div className="product-section__info-price__price">
                {discount === 0 ? (
                  `${price}$`
                ) : (
                  <>
                    <span
                      style={{ marginRight: '7px' }}
                      className="product-section__info-price__price-without-discount"
                    >{`${price}$`}</span>
                    <span className="product-section__info-price__price-with-discount">{`${
                      price - price * (discount / 100)
                    }$`}</span>
                  </>
                )}
              </div>
              <div className="product-section__info-price__available">
                <span>{amount === 0 ? 'Pre-order' : 'Available'}</span>
                <span>{getBatch()} Batch</span>
              </div>
            </div>
          </div>
        </Link>
        <div
          onClick={() => remove(isMiningCategory === undefined ? product.id : generalInfo.id)}
          tabIndex={0}
          className="product-section__info-name__actions"
        >
          <BucketIcon />
        </div>
      </div>
      <div className="product-section__info-characteristics">
        {isMiningCategory === undefined
          ? miningCharacteristics
              .filter((characteristic) => characteristic.name !== 'Manufacturer')
              .map((characteristic, idx) => (
                <div key={idx} className="product-section__info-characteristics__characteristics">
                  <span className="product-section__info-characteristics__characteristics-name">
                    {characteristic.name}
                  </span>
                  <span className="product-section__info-characteristics__characteristics-value">
                    {product[characteristic.key]}
                  </span>
                </div>
              ))
          : product
              .filter((characteristic: any) => characteristic.name !== 'Manufacturer')
              .map((characteristic: any, idx: number) => (
                <div key={idx} className="product-section__info-characteristics__characteristics">
                  <span className="product-section__info-characteristics__characteristics-name">
                    {characteristic.name}
                  </span>
                  <span className="product-section__info-characteristics__characteristics-value">
                    {characteristic.value || '-'}
                  </span>
                </div>
              ))}
      </div>
    </div>
  );
};

export default ProductCard;

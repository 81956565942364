import React from 'react';

const LogoutIcon: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0002 0C11.34 0.000376943 11.6669 0.130506 11.914 0.363798C12.1611 0.59709 12.3098 0.915937 12.3297 1.25519C12.3496 1.59445 12.2393 1.92851 12.0211 2.18911C11.803 2.44971 11.4936 2.6172 11.1562 2.65733L11.0002 2.66667H4.3335C4.00692 2.66671 3.69171 2.78661 3.44767 3.00362C3.20362 3.22063 3.04771 3.51967 3.0095 3.844L3.00016 4V20C3.00021 20.3266 3.1201 20.6418 3.33712 20.8858C3.55413 21.1299 3.85316 21.2858 4.1775 21.324L4.3335 21.3333H10.3335C10.6733 21.3337 11.0002 21.4638 11.2473 21.6971C11.4944 21.9304 11.6431 22.2493 11.6631 22.5885C11.683 22.9278 11.5726 23.2618 11.3545 23.5224C11.1364 23.783 10.827 23.9505 10.4895 23.9907L10.3335 24H4.3335C3.31322 24.0001 2.33148 23.6102 1.58915 22.9103C0.84682 22.2103 0.400019 21.2532 0.340163 20.2347L0.333496 20V4C0.333439 2.97972 0.723263 1.99798 1.42321 1.25565C2.12315 0.513324 3.08031 0.0665233 4.09883 0.00666682L4.3335 0H11.0002ZM18.6095 7.28533L22.3802 11.0573C22.6301 11.3074 22.7705 11.6464 22.7705 12C22.7705 12.3536 22.6301 12.6926 22.3802 12.9427L18.6095 16.7147C18.3593 16.9647 18.0201 17.1051 17.6664 17.1049C17.3127 17.1048 16.9735 16.9642 16.7235 16.714C16.4735 16.4638 16.3331 16.1246 16.3332 15.7709C16.3334 15.4172 16.474 15.078 16.7242 14.828L18.2188 13.3333H11.0002C10.6465 13.3333 10.3074 13.1929 10.0574 12.9428C9.80731 12.6928 9.66683 12.3536 9.66683 12C9.66683 11.6464 9.80731 11.3072 10.0574 11.0572C10.3074 10.8071 10.6465 10.6667 11.0002 10.6667H18.2188L16.7242 9.172C16.474 8.92199 16.3334 8.58283 16.3332 8.22914C16.3331 7.87545 16.4735 7.53619 16.7235 7.286C16.9735 7.03581 17.3127 6.89519 17.6664 6.89506C18.0201 6.89494 18.3593 7.03532 18.6095 7.28533Z"
      fill="#6E7EA8"
    />
  </svg>
);

export default LogoutIcon;

import React from 'react';

const CartBucketIncon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2299 21.2938H6.76992C6.08873 21.2959 5.429 21.0556 4.90871 20.6159C4.38841 20.1763 4.04147 19.5658 3.92992 18.8938L2.41992 9.79385H21.5799L20.0699 18.8938C19.9584 19.5658 19.6114 20.1763 19.0911 20.6159C18.5708 21.0556 17.9111 21.2959 17.2299 21.2938Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M23.5 9.79385H0.5" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M7.20996 11.7139V9.79385L10.08 3.09385"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16.7899 11.7139V9.79385L13.9199 3.09385"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M12 13.6339V18.4239" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8.16992 13.6339V18.4239" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M15.8301 13.6339V18.4239" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default CartBucketIncon;

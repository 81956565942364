import { adminInstance } from '../../general/services/main/axiosInstances';

export const TestimonialsService = {
  // GET
  async getTestimonealById(id: string) {
    try {
      const response = await adminInstance.get(`/Testimonial/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getAllTestimoneals() {
    try {
      const response = await adminInstance.get(`/Testimonial/full`);
      return response;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postTestimoneal(
    name: string,
    country: string,
    content: string,
    rating: number,
    image: File,
  ) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await adminInstance.post(
        `/Testimonial?Username=${name}&Country=${country}&Content=${content}&Rating=${rating}`,
        data,
        { headers },
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async putTestimonealImage(id: string, image: File) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await adminInstance.post(
        `/Testimonial/updateImage?TestimonialId=${id}`,
        data,
        {
          headers,
        },
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putTestimoneal(data: any) {
    try {
      const response = await adminInstance.put(`/Testimonial`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  //DELETE
  async deleteTestimonealImage(data: any) {
    try {
      const response = await adminInstance.delete(`/Testimonial/removeImage`, {
        data,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteTestimoneal(id: any) {
    const data = {
      id: id,
    };

    const response = await adminInstance.delete(`/Testimonial`, { data });
    return response.data;
  },
};

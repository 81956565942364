import './slick-theme.scss';
import './slick.scss';

import React from 'react';
import Slider from 'react-slick';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { Testimonial } from '../../../../general/types/types';
import Review from '../../review/Review';

const ReviewsSlider: React.FC = () => {
  const { width } = useResize();

  const { data: testimonials, isLoading: isLoadingTestimonials } = useCustomQuery<Testimonial[]>(
    queryKeys.TESTIMONIALS,
    () => createHomePageService(clientInstance).getTestimonials(),
  );

  const settings = {
    dots: true,
    infinite: (testimonials?.length || 0) > 2,
    speed: 500,
    slidesToShow: width <= 900 ? 1 : width <= 1440 ? 2 : 3,
    slidesToScroll: width <= 900 ? 1 : width <= 1440 ? 2 : 3,
    arrows: false,
  };

  return (
    <>
      {testimonials && !isLoadingTestimonials ? (
        <Slider {...settings}>
          {testimonials?.map((testimonial, index) => (
            <div className="slider-item" key={index}>
              <Review
                image={testimonial.imageUrl}
                name={testimonial.username}
                country={testimonial.country}
                rating={testimonial.rating}
                text={testimonial.content}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="reviews-section__reviews_loading">
          <Preloader />
        </div>
      )}
    </>
  );
};

export default ReviewsSlider;

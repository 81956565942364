import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductsStoreAddress } from '../../types/types';

export interface GetInTouchState {
  choosenStores: ProductsStoreAddress[];
}

const initialState: GetInTouchState = {
  choosenStores: [],
};

const ProductSlicer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addChoosenStore: (state, action: PayloadAction<ProductsStoreAddress>) => {
      state.choosenStores.push(action.payload);
    },
    removeChoosenStore: (state, action: PayloadAction<string>) => {
      state.choosenStores = state.choosenStores.filter((store) => store.id !== action.payload);
    },
    resetChoosenStores: (state) => {
      state.choosenStores = [];
    },
  },
});

export const { addChoosenStore, removeChoosenStore, resetChoosenStores } = ProductSlicer.actions;
export default ProductSlicer.reducer;

import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import EnergyConsumption from '../../../general/assets/svg/EnergyConsumption';
import EnergyEfficiency from '../../../general/assets/svg/EnergyEfficiency';
import HashRateIcon from '../../../general/assets/svg/HashRateIcon';
import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import RoundArrow45 from '../../../general/assets/svg/RoundArrow45';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createShippingService from '../../../general/services/shipping';
import { HostingLocationStates, IHostingLocation } from '../../../general/types/types';
import LocationImage from './LocationImage';

interface HostingLocationProps {
  hosting: IHostingLocation;
}

const HostingLocation: React.FC<HostingLocationProps> = ({ hosting }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const resize = useResize();

  const { data: states } = useCustomQuery<HostingLocationStates>(
    queryKeys.HOSTING_LOCATION_STATES,
    () => createShippingService(clientInstance).getHostingLocationStates(),
  );

  const width = resize.width <= 768 ? 450 : resize.width <= 1024 ? 522 : 622;
  const height =
    resize.width <= 500 ? 220 : resize.width <= 768 ? 230 : resize.width <= 1024 ? 264 : 354;
  const cutoutWidth =
    resize.width <= 500 ? 110 : resize.width <= 768 ? 110 : resize.width <= 1024 ? 125 : 130;
  const cutoutHeight =
    resize.width <= 500 ? 110 : resize.width <= 768 ? 110 : resize.width <= 1024 ? 120 : 130;
  const radius = 60;

  return (
    <>
      <LocationImage
        src={hosting.images[0].imageUrl}
        width={width}
        height={height}
        cutoutWidth={cutoutWidth}
        cutoutHeight={cutoutHeight}
        radius={radius}
      />

      <div className="text-content">
        <div className={`hosting-header ${isOpen ? 'hosting-header-open' : ''}`}>
          <div className="location">
            <LocationMarkerIcon fill="white" />
            <span className={`histing${hosting.id}`}>{hosting.locationName}</span>
            <Tooltip anchorSelect={`.histing${hosting.id}`} place="top">
              {hosting.locationName}
            </Tooltip>
          </div>
          <span
            className="state"
            style={{
              color:
                hosting.state === states?.Available
                  ? '#65E279'
                  : hosting.state === states?.ComingSoon
                    ? '#F6B760'
                    : '#F66060',
            }}
          >
            {hosting.state}
          </span>
        </div>
        <div className={`main-characteristics ${isOpen ? 'main-characteristics-open' : ''}`}>
          <div className="main-characteristic">
            <HashRateIcon />
            <span className={`hosting${hosting.id}mainCharacteristic1`}>
              {hosting.characteristics[0]?.value}
            </span>
            <Tooltip anchorSelect={`.hosting${hosting.id}mainCharacteristic1`} place="top">
              {hosting.characteristics[0]?.value}
            </Tooltip>
          </div>
          <div className="main-characteristic">
            <EnergyConsumption />
            <span className={`hosting${hosting.id}mainCharacteristic2`}>
              {hosting.characteristics[1]?.value}
            </span>
            <Tooltip anchorSelect={`.hosting${hosting.id}mainCharacteristic2`} place="top">
              {hosting.characteristics[1]?.value}
            </Tooltip>
          </div>
          <div className="main-characteristic">
            <EnergyEfficiency />
            <span className={`hosting${hosting.id}mainCharacteristic3`}>
              {hosting.characteristics[2]?.value}
            </span>
            <Tooltip anchorSelect={`.hosting${hosting.id}mainCharacteristic3`} place="top">
              {hosting.characteristics[2]?.value}
            </Tooltip>
          </div>
        </div>
        <div className={`other-characteristics ${isOpen ? 'other-characteristics-open' : ''}`}>
          {hosting.characteristics.map(
            (characteristic, index) =>
              index > 2 && (
                <div className="other-characteristic" key={index}>
                  <span className={`characteristic${characteristic.id}key`}>
                    {characteristic.key}
                  </span>
                  <Tooltip anchorSelect={`.characteristic${characteristic.id}key`} place="top">
                    {characteristic.key}
                  </Tooltip>
                  <span className={`characteristic${characteristic.id}value`}>
                    {characteristic.value}
                  </span>
                  <Tooltip anchorSelect={`.characteristic${characteristic.id}value`} place="top">
                    {characteristic.value}
                  </Tooltip>
                </div>
              ),
          )}
        </div>
      </div>
      <div
        className={`arrow ${isOpen ? 'arrow-opened' : ''}`}
        //style={{ transform: `rotate(${isOpen ? '90deg' : '0deg'})` }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>
          <RoundArrow45 stroke={isOpen ? '#25a8cf' : 'white'} />
        </div>
      </div>
    </>
  );
};

export default HostingLocation;

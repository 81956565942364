import { adminInstance } from '../../general/services/main/axiosInstances';

export const MessagesService = {
  // GET
  async getMessage(pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(`/ContactMessage?PageSize=${pageSize}&Page=${page}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getSimpleMessage(pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/ContactMessage/simple?PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getSimpleMessageCount() {
    try {
      const response = await adminInstance.get(
        `/ContactMessage/simpleMessagesWithStatusCount?IsProcessed=false`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getMessageCount() {
    try {
      const response = await adminInstance.get(
        `/ContactMessage/messagesWithStatusCount?IsProcessed=false`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putMessageStatus(data: any) {
    try {
      const response = await adminInstance.put(`/ContactMessage/status`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putSimpleMessageStatus(data: any) {
    try {
      const response = await adminInstance.put(`/ContactMessage/simpleStatus`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteMessage(id: string) {
    try {
      const response = await adminInstance.delete(`/ContactMessage`, {
        data: { id: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteSimpleMessage(id: string) {
    try {
      const response = await adminInstance.delete(`/ContactMessage/simple`, {
        data: { id: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

import './RefferalTabs.scss';

import React, { useReducer } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import AdminPlusIconWhite from '../../../general/assets/svg/AdminPlusIconWhite';
import ExportIcon from '../../../general/assets/svg/ExportIcon';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useKey, { keys } from '../../../general/hooks/useKey';
import { queryKeys } from '../../../general/queryKeys';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import createRefferalService from '../../../general/services/refferal';
import getFile from '../../../general/utils/GetFile';
import AdminModalWindow from '../../components/modal-window/ModalWindow';
import AddAffiliate from './bloggersAndCompanies/addAffiliate/AddAffiliate';
import AddReferral from './referralCodes/addReferral/AddReferral';

const ReferralTabs: React.FC = () => {
  const location = useLocation();
  const locationComponents = location.pathname.split('/');

  const [modalAddAffiliates, setModalAddAffiliates] = useReducer((temp) => !temp, false);
  const [modalAddReferral, setModalAddReferral] = useReducer((temp) => !temp, false);

  const { refetch: getXlsx } = useCustomQuery<any>(
    queryKeys.REFFERAL_XLSX,
    () => createRefferalService(adminInstance).getXslxUsersWithRefs(),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess: (data: any) => {
        getFile(data, 'clients_referrals');
      },
    },
  );

  useKey((event: KeyboardEvent) => {
    if (event.key === keys.ESCAPE) {
      modalAddAffiliates && setModalAddAffiliates();
      !modalAddAffiliates && modalAddReferral && setModalAddReferral();
    }
  });

  const handleExport = () => getXlsx();

  return (
    <div className="page-section__referral">
      <div className="page-section__top">
        <div className="page-section__top__title-level">
          <div className="page-section__top__title">Referral Program</div>
          {locationComponents.includes('client-tracking') && (
            <button onClick={handleExport} className="button_with_border">
              <ExportIcon />
              <span>Export</span>
            </button>
          )}
        </div>
        <div className="page-section__top-tabs-level">
          <div className="page-section__top-tabs">
            <NavLink
              end
              to="/admin/referral-program"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Bloggers / Companies
            </NavLink>
            <NavLink
              end
              to="/admin/referral-program/referral-codes"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Referral Codes
            </NavLink>
            <NavLink
              end
              to="/admin/referral-program/client-tracking"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Client Tracking
            </NavLink>
          </div>
          {!locationComponents.includes('client-tracking') && (
            <button
              onClick={() => {
                locationComponents.includes('referral-codes')
                  ? setModalAddReferral()
                  : setModalAddAffiliates();
              }}
              className="button_with_border"
            >
              <AdminPlusIconWhite />
              <span>
                {locationComponents.includes('referral-codes')
                  ? 'Add referral code'
                  : 'Add Affiliate'}
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="page-section__content">
        <Outlet />
      </div>
      {modalAddAffiliates && (
        <AdminModalWindow title={'Add Affiliate'} isOpen={setModalAddAffiliates} mWidth={'50%'}>
          <AddAffiliate close={setModalAddAffiliates} />
        </AdminModalWindow>
      )}
      {modalAddReferral && (
        <AdminModalWindow title={'Add Referral'} isOpen={setModalAddReferral} mWidth={'50%'}>
          <AddReferral
            close={setModalAddReferral}
            modalAddAffiliates={modalAddAffiliates}
            setModalAddAffiliates={setModalAddAffiliates}
          />
        </AdminModalWindow>
      )}
    </div>
  );
};

export default ReferralTabs;

function converterFromHTML() {
  return {
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node.style.textAlign === 'center')
        return currentStyle.add('center');
      if (nodeName === 'span' && node.style.textAlign === 'left') return currentStyle.add('left');
      if (nodeName === 'span' && node.style.textAlign === 'right') return currentStyle.add('right');
      else return currentStyle;
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === 'figure') {
        return 'atomic';
      }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      const src = node.src;

      if (nodeName === 'a') {
        return createEntity('LINK', 'IMMUTABLE', {
          url: node.href,
        });
      }

      if (nodeName === 'iframe') {
        return createEntity('draft-js-video-plugin-video', 'IMMUTABLE', {
          src: src,
        });
      }
      if (nodeName === 'img') {
        const style = node.attributes.style.value;

        // get flaot from style
        const float = (str) => {
          const floatMatch = str.match(/float\s*:\s*([^;]+)/);
          return floatMatch ? floatMatch[1].trim() : null;
        };

        // get width from style
        const width = (str) => {
          const floatMatch = str.match(/width\s*:\s*([^;]+)/);
          return floatMatch ? floatMatch[1].trim() : null;
        };

        return createEntity('IMAGE', 'IMMUTABLE', {
          src: src,
          alignment: `${float(style) !== undefined ? float(style) : 'none'}`,
          width: parseInt(width(style)),
        });
      }
    },
  };
}

export default converterFromHTML;

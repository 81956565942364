import React from 'react';

const CartSectionEmpty: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0415 65.9167L93.6665 54.0001V18.1667H17.2915"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M2.1665 6.25H14.1248L29.9998 65.9167L23.0832 76.2917C22.4212 77.3075 22.0734 78.4959 22.0832 79.7083C22.0832 81.3217 22.7241 82.8691 23.8649 84.0099C25.0058 85.1508 26.5531 85.7917 28.1665 85.7917H77.7498"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M77.7498 93.7499C79.936 93.7499 81.7082 91.9777 81.7082 89.7916C81.7082 87.6055 79.936 85.8333 77.7498 85.8333C75.5637 85.8333 73.7915 87.6055 73.7915 89.7916C73.7915 91.9777 75.5637 93.7499 77.7498 93.7499Z"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M37.9583 93.7499C40.1445 93.7499 41.9167 91.9777 41.9167 89.7916C41.9167 87.6055 40.1445 85.8333 37.9583 85.8333C35.7722 85.8333 34 87.6055 34 89.7916C34 91.9777 35.7722 93.7499 37.9583 93.7499Z"
      stroke="#25A8CF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CartSectionEmpty;

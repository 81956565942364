import React from 'react';

const InfoIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
      fill="#25A8CF"
    />
    <path
      d="M12.0001 16.7701V10.0901H10.0901"
      stroke="#0F1928"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M10.0901 16.77H13.9101" stroke="#0F1928" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M11.05 7.22998H12.95" stroke="#0F1928" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default InfoIcon;

import React from 'react';

const CookieIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_161_11131)">
      <path
        d="M14.6667 1.33317L1.33335 14.6665"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6667 10.5L14.6667 1.33333L5.50002 1.33333"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_161_11131">
        <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default CookieIcon;

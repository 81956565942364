import React from 'react';

interface InstagranFooterIconProps {
  color: string;
}

const InstagranFooterIcon: React.FC<InstagranFooterIconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1790_4097)">
      <path
        d="M18.68 1.5H5.32C3.21027 1.5 1.5 3.21027 1.5 5.32V18.68C1.5 20.7897 3.21027 22.5 5.32 22.5H18.68C20.7897 22.5 22.5 20.7897 22.5 18.68V5.32C22.5 3.21027 20.7897 1.5 18.68 1.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0005 16.7705C14.6349 16.7705 16.7705 14.6349 16.7705 12.0005C16.7705 9.36607 14.6349 7.23047 12.0005 7.23047C9.36607 7.23047 7.23047 9.36607 7.23047 12.0005C7.23047 14.6349 9.36607 16.7705 12.0005 16.7705Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M18.1995 7.23012C18.9893 7.23012 19.6295 6.58988 19.6295 5.80012C19.6295 5.01035 18.9893 4.37012 18.1995 4.37012C17.4098 4.37012 16.7695 5.01035 16.7695 5.80012C16.7695 6.58988 17.4098 7.23012 18.1995 7.23012Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1790_4097">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InstagranFooterIcon;

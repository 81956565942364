import { AxiosInstance } from 'axios';

interface IAddress {
  name: string;
  lastName: string;
  company: string;
  street1: string;
  street2: string;
  street3: string;
  streetNumber: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  metadata: string;
}

interface IContacts {
  address: IAddress;
  addressWeb: string;
  phoneWeb: string;
  emailWeb: string;
  telegramLink: string;
  facebookLink: string;
  linkedinLink: string;
  instagramLink: string;
  xLink: string;
  whatsappLink: string;
}

interface IContactsData {
  contacts: IContacts;
}

interface ContactsService {
  getContacts: () => Promise<any>;
  postContacts: (data: IContactsData) => Promise<any>;
}

const createContactsService = (instance: AxiosInstance): ContactsService => ({
  async getContacts() {
    try {
      const response = await instance.get(`/CompanyContacts`);
      return response;
    } catch (error) {
      return error;
    }
  },

  // TODO use in admin
  async postContacts(data: IContactsData) {
    try {
      const response = await instance.post(`/CompanyContacts`, data);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createContactsService;

import React, { useEffect, useState } from 'react';

const ActionButton = ({ updateState, trigger, item, refreshMethod }) => {
  const [isSubscribed, setIsSubscribed] = useState(item.isActive);

  useEffect(() => {
    if (trigger !== undefined) {
      setIsSubscribed(trigger);
    }
  }, [trigger]);

  useEffect(() => {
    setIsSubscribed(item.isActive);
  }, [item.isActive]);

  const handleCheck = () => {
    updateState(undefined);
    setIsSubscribed(!isSubscribed);
    item.isActive = !isSubscribed;
    refreshMethod(item);
  };

  return (
    <div className="check-box__container">
      <input
        type="checkbox"
        id={`is_subscribed${item.id}`}
        checked={isSubscribed}
        onChange={handleCheck}
      />
      <label htmlFor={`is_subscribed${item.id}`} />
    </div>
  );
};

export default ActionButton;

import './EmailAuthorization.scss';

import React, { ChangeEvent, useState } from 'react';

import ErrorFieldAttentionIcon from '../../../../general/assets/svg/ErrorFieldAttentionIcon';
import PasswordVisibilityIcon from '../../../../general/assets/svg/PasswordVisibilityIcon';

interface LogInProps {
  emailError: { status: boolean; message: string };
  passwordError: { status: boolean; message: string };
  defaultEmailInput: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultPasswordInput: (e: ChangeEvent<HTMLInputElement>) => void;
}

const LogIn: React.FC<LogInProps> = ({
  emailError,
  passwordError,
  defaultEmailInput,
  defaultPasswordInput,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="email-authorization-section__sing-in">
      <div>
        <input type="email" placeholder="Please enter email" onChange={defaultEmailInput} />
        {emailError.status && (
          <span className="inputs-block__email-number__error">
            <ErrorFieldAttentionIcon />
            {emailError.message}
          </span>
        )}
      </div>
      <div className="password-container">
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder="Please enter password"
          onChange={defaultPasswordInput}
        />
        <button
          className={`show-hide-password ${isPasswordVisible ? 'active-password' : ''}`}
          onClick={togglePasswordVisibility}
          type="button"
        >
          <PasswordVisibilityIcon />
        </button>
        {passwordError.status && (
          <span className="inputs-block__email-number__error">
            <ErrorFieldAttentionIcon />
            {passwordError.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default LogIn;

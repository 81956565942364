import './AdminAuthorization.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import LogoIcon from '../../../general/assets/svg/LogoIcon';
import useCustomMutation from '../../../general/hooks/useCustomMutation';
import { setAdminAuth } from '../../../general/redux/reducers/Admin-Reducer';
import createAuthorisationService from '../../../general/services/authorisation';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import { saveDataForage } from '../../../localforage';

const AdminAuthorization: React.FC = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { mutate: authorise } = useCustomMutation(
    createAuthorisationService(adminInstance).postAuthAdminPanel,
    undefined,
    {
      onSuccess: async (data: any) => {
        await saveDataForage(
          'a4ad',
          JSON.stringify({
            a4d: data.userId,
            a4ac: data.accessToken,
            a4rt: data.refreshToken,
          }),
        );

        dispatch(setAdminAuth(data !== null ? data.userId : ''));
      },
    },
  );

  return (
    <div className="authorization-section">
      <div className="authorization-section__title">
        <LogoIcon />
        <span>AsicXchange</span>
      </div>
      <div className="authorization-section__inputs">
        <div className="authorization-section__inputs__input">
          <label htmlFor="login">Login</label>
          <input
            id="login"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
          />
        </div>
        <div className="authorization-section__inputs__input">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            className="authorization-section__inputs__input-i"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="authorization-section__button">
        <button
          className="section-btn-gradient"
          onClick={() => {
            authorise({
              username: username.trim(),
              password: password.trim(),
            });
          }}
        >
          Sign In
        </button>
      </div>
      <div className="authorization-section__forgot-password">
        <span>Forgot password?</span>
      </div>
    </div>
  );
};

export default AdminAuthorization;

import './AdminCompanyPartners.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { RootState } from '../../../../general/redux/store';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { CompanyPertner } from '../../../../general/types/types';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface ModalCompanyPartnerProps {
  isOpen: (state: boolean) => void;
  refresh: () => void;
}

const ModalCompanyPartner: React.FC<ModalCompanyPartnerProps> = ({ isOpen, refresh }) => {
  const partnerId = useSelector((state: RootState) => state.homeStatic.companyParterIdToEdit);

  const closeModal = () => {
    isOpen(false);
  };

  const [partner, setPartner] = useState<CompanyPertner | null>(null);
  const [currentIMG, setCurrentIMG] = useState<string>('');

  const { isLoading: gettingPartner, refetch: getPartner } = useCustomQuery<CompanyPertner | null>(
    queryKeys.COMPANY_PARTNER_TO_EDIT,
    () => createHomePageService(adminInstance).getCompanyPartnerById(partnerId as string),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess: (response: any) => {
        setPartner(response);
        setCurrentIMG(response?.imageUrl);
      },
    },
  );

  const { mutate: createNewPartner } = useCustomMutation(
    createHomePageService(adminInstance).postCompanyPartner,
  );

  const { mutate: removePartner } = useCustomMutation(
    createHomePageService(adminInstance).deleteCompanyPartner,
  );

  const { mutate: updateImg } = useCustomMutation(
    createHomePageService(adminInstance).postCompanyPartnerImg,
  );

  const { mutate: updateName } = useCustomMutation(
    createHomePageService(adminInstance).putCompanyPartnerName,
  );

  useEffect(() => {
    if (partnerId) {
      getPartner();
    }
  }, [partnerId, getPartner]);

  const addPartner = async () => {
    const inputsBlock = document.querySelector('.modal-window-action__modal__partners__inputs');
    const inputs = inputsBlock?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else {
      if (input_img.files && input_img.files[0]) {
        createNewPartner({
          name: inputs[0].value,
          url: inputs[1].value,
          image: input_img.files[0],
        });
        refresh();
        closeModal();
      }
    }
  };

  const updatePartner = async () => {
    const inputsBlock = document.querySelector('.modal-window-action__modal__partners__inputs');
    const inputs = inputsBlock?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else if (partner) {
      if (input_img.files && input_img.files[0]) {
        updateImg({
          id: partner.id,
          image: input_img.files[0],
        });
      }
      updateName({
        id: partner.id,
        name: inputs[0].value,
        url: inputs[1].value,
      });
      refresh();
      closeModal();
    }
  };

  const deletePartner = async () => {
    if (partner) {
      removePartner(partner.id);
      refresh();
      closeModal();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCurrentIMG(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Company Partners'}>
      {gettingPartner && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__partners">
        <div className="modal-window-action__modal__partners__img">
          <label htmlFor="upload-photo" className="img-loader">
            {currentIMG === '' ? (
              <>+</>
            ) : (
              <div
                className="selected-img"
                style={{
                  backgroundImage: `url(${currentIMG})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </label>
          <input id="upload-photo" type="file" accept="image/*" onChange={handleFileChange} />
        </div>
        <div className="modal-window-action__modal__partners__inputs inputs">
          <input type="text" placeholder="name" defaultValue={partner?.name || ''} />
          <input type="text" placeholder="url" defaultValue={partner?.url || ''} />
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        {partner !== null && (
          <button className="delete-btn" onClick={deletePartner}>
            Delete
          </button>
        )}
        <button
          className="section-btn-gradient"
          onClick={partner === null ? addPartner : updatePartner}
        >
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default ModalCompanyPartner;

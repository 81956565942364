import './PriceFilter.scss';

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Slider from 'react-slider';

import PriceFilterSeparatorIcon from '../../../../general/assets/svg/PriceFilterSeparatorIcon';

interface PriceFilterProps {
  setIsShowMore: (value: any) => void;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  min: number;
  max: number;
}

const PriceFilter: React.FC<PriceFilterProps> = ({
  setIsShowMore,
  searchParams,
  setSearchParams,
  min,
  max,
}) => {
  const [values, setValues] = useState<number[]>([
    searchParams.get('PriceFrom') !== null
      ? parseInt(searchParams.get('PriceFrom') as string)
      : Math.trunc(min),
    searchParams.get('PriceTo') !== null
      ? parseInt(searchParams.get('PriceTo') as string)
      : Math.trunc(max),
  ]);

  const changeSlider = useCallback((values: number[]) => {
    const inputs = document.querySelectorAll<HTMLInputElement>('.price-input');
    if (inputs[0]) inputs[0].value = values[0].toString();
    if (inputs[1]) inputs[1].value = values[1].toString();
    setValues(values);
  }, []);

  const changeInputMin = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(e.target.value);
      if (isNaN(value) || e.target.value === '') {
        value = min;
      }

      if (value > values[1]) {
        value = values[1];
      }

      setValues([value, values[1]]);
    },
    [min, values],
  );

  const changeInputMax = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(e.target.value);
      if (isNaN(value) || e.target.value === '') {
        value = max;
      }

      if (value < values[0]) {
        value = values[0];
      }

      if (value > max) {
        value = max;
      }

      setValues([values[0], value]);
    },
    [max, values],
  );

  const filter = useCallback(() => {
    const newParams = new URLSearchParams(searchParams.toString());

    // if (values[0] !== min) {
    //   newParams.set('PriceFrom', values[0].toString());
    // } else {
    //   newParams.delete('PriceFrom');
    // }

    // newParams.set('PriceFrom', values[0].toString());

    // if (values[1] !== max) {
    //   newParams.set('PriceTo', values[1].toString());
    // } else {
    //   newParams.delete('PriceTo');
    // }

    // newParams.set('page', '1');

    newParams.set('PriceFrom', values[0].toString());
    newParams.set('PriceTo', values[1].toString());
    newParams.set('page', '1');

    setIsShowMore(null);
    setSearchParams(newParams);
  }, [searchParams, setIsShowMore, setSearchParams, values]);

  useEffect(() => {
    if (searchParams.size === 0) {
      const inputs = document.querySelectorAll<HTMLInputElement>('.price-input');
      if (inputs[0]) inputs[0].value = Math.trunc(min).toString();
      if (inputs[1]) inputs[1].value = Math.trunc(max).toString();
      setValues([Math.trunc(min), Math.trunc(max)]);
    }
  }, [searchParams, min, max]);

  return (
    <div className="filter-box">
      <div className="filter-title">Price</div>
      <div className="filter-actions">
        <div className="filter-actions-slider">
          <Slider
            className="filter-actions-slider__slider"
            onChange={changeSlider}
            value={values}
            min={Math.trunc(min)}
            max={Math.trunc(max)}
          />
        </div>
        <div className="filter-price-inputs">
          <div className="filter-price-input">
            <input
              type="number"
              className="price-input"
              defaultValue={values[0]}
              onChange={changeInputMin}
            />
          </div>
          <PriceFilterSeparatorIcon />
          <div className="filter-price-input">
            <input
              type="number"
              className="price-input"
              defaultValue={values[1]}
              onChange={changeInputMax}
            />
          </div>
        </div>
        <div className="filter-price-btn">
          <button onClick={filter}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default PriceFilter;

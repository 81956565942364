import React from 'react';

const EnergyConsumption: React.FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_817_132)">
      <path
        d="M6.5929 8.65259H10.4129V10.5593C10.413 10.8924 10.2825 11.2123 10.0494 11.4503C9.81637 11.6884 9.4993 11.8256 9.16624 11.8326H7.8329C7.49519 11.8326 7.17132 11.6984 6.93252 11.4596C6.69372 11.2208 6.55957 10.897 6.55957 10.5593V8.65259H6.5929Z"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.77323 6.10593V8.64593H7.22656V6.10593"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.5002 11.8326V14.2393C8.49753 14.4263 8.53675 14.6115 8.61498 14.7814C8.69321 14.9513 8.80847 15.1015 8.95231 15.221C9.09614 15.3406 9.26491 15.4264 9.44623 15.4722C9.62755 15.5181 9.81683 15.5228 10.0002 15.4859C11.5165 15.1516 12.8784 14.3221 13.8712 13.1283C14.8641 11.9344 15.4314 10.4441 15.4837 8.89227C15.536 7.34043 15.0704 5.8153 14.1602 4.55731C13.25 3.29931 11.9471 2.38 10.4567 1.94429C8.96637 1.50858 7.3734 1.58125 5.92892 2.15085C4.48444 2.72045 3.2706 3.75457 2.47874 5.09021C1.68688 6.42585 1.36205 7.98704 1.55544 9.52767C1.74884 11.0683 2.44946 12.5008 3.54687 13.5993"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_817_132">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.652588)" />
      </clipPath>
    </defs>
  </svg>
);

export default EnergyConsumption;

import { useCallback } from 'react';

const useScrollTo = () => {
  const scrollTo = useCallback(
    (
      id: string,
      behavior: ScrollBehavior = 'smooth',
      block: ScrollLogicalPosition = 'center',
      inline: ScrollLogicalPosition = 'nearest',
    ) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior, block, inline });
      }
    },
    [],
  );

  return scrollTo;
};

export default useScrollTo;

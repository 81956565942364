import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductMax } from '../../types/types';

interface CompareState {
  i: { [key: string]: ProductMax[] };
  c: number;
}

interface AddCompareItemPayload {
  c: string;
  p: ProductMax;
}

interface DeleteCompareItemPayload {
  c: string;
  p: string;
}

const initialState: CompareState = {
  i: {},
  c: 0,
};

const CompareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    addCompareItem: (state, action: PayloadAction<AddCompareItemPayload>) => {
      const { c, p } = action.payload;
      if (state.i[c] && state.i[c].findIndex((item) => item.id === p.id) === -1) {
        state.i[c].push(p);
        state.c += 1;
        return;
      }

      state.i[c] = [p];
      state.c += 1;
    },

    deleteCompareItem: (state, action: PayloadAction<DeleteCompareItemPayload>) => {
      const { c, p } = action.payload;
      state.i[c] = state.i[c].filter((item) => item.id !== p);
      state.c -= 1;

      if (state.i[c].length === 0) {
        delete state.i[c];
      }
    },

    clearCompare: (state) => {
      state.i = {};
      state.c = 0;
    },
  },
});

export const { addCompareItem, deleteCompareItem, clearCompare } = CompareSlice.actions;
export default CompareSlice.reducer;

import './AdminProductList.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import getFile from '../../../../general/utils/GetFile';
import Confirm from '../../../components/confirm/Confirm';
import AdminSearch from '../../../components/search/Search';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import { ProductService } from '../../../service/produt-services/ProductService';

const ActionButtons = ({ item, deleteMethod }) => {
  const [deleteProductAsk, setDeleteProductAsk] = useState(false);

  return (
    <>
      <Link
        to={`/admin/products/product/` + item.id}
        className="table-block__tbody__change-item__button"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_653_106)">
            <path
              d="M6.18386 16.8721L2.35986 17.6721L3.15986 13.8481L14.7119 2.2321C14.9123 2.03121 15.1505 1.87195 15.4127 1.76349C15.675 1.65502 15.9561 1.59949 16.2399 1.6001C16.8127 1.6001 17.3621 1.82767 17.7672 2.23275C18.1723 2.63783 18.3999 3.18723 18.3999 3.7601C18.4005 4.04389 18.3449 4.32499 18.2365 4.58723C18.128 4.84947 17.9688 5.08766 17.7679 5.2881L6.18386 16.8721Z"
              stroke="#25A8CF"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M0.839844 18.3999H19.1598"
              stroke="#25A8CF"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M16.1119 6.9437L13.0559 3.8877"
              stroke="#25A8CF"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_653_106">
              <rect
                width="19.2"
                height="19.2"
                fill="white"
                transform="translate(0.399902 0.399902)"
              />
            </clipPath>
          </defs>
        </svg>
      </Link>
      <button
        onClick={() => setDeleteProductAsk(!deleteProductAsk)}
        className="table-block__tbody__change-item__button"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_653_112)">
            <path
              d="M13.9041 18.3998H6.09606C5.70642 18.4003 5.33145 18.2512 5.04858 17.9832C4.76572 17.7152 4.59657 17.3489 4.57606 16.9598L3.88806 4.65576H16.1121L15.4241 16.9598C15.4036 17.3489 15.2344 17.7152 14.9515 17.9832C14.6687 18.2512 14.2937 18.4003 13.9041 18.3998Z"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M2.35999 4.65576H17.64"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M8.47197 1.6001H11.528C11.9332 1.6001 12.3219 1.76108 12.6084 2.04764C12.895 2.33419 13.056 2.72285 13.056 3.1281V4.6561H6.94397V3.1281C6.94397 2.72285 7.10495 2.33419 7.39151 2.04764C7.67807 1.76108 8.06672 1.6001 8.47197 1.6001Z"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M10 6.94385V16.1118"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M13.056 6.94385V16.1118"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M6.94397 6.94385V16.1118"
              stroke="#25A8CF"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_653_112">
              <rect
                width="19.2"
                height="19.2"
                fill="white"
                transform="translate(0.400024 0.399902)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      {deleteProductAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this product?'}
          handler={setDeleteProductAsk}
          clickEvent={() => {
            deleteMethod(item.id);
          }}
        />
      )}
    </>
  );
};

const Export = ({ isActive }) => {
  const closeModal = () => {
    isActive(false);
  };

  const [isPreloader, setIsPreloader] = useState(false);

  const exportXLSX = async () => {
    setIsPreloader(true);
    const export_type = document.querySelector('input[name=export]:checked').value;
    let response;
    if (export_type === 'products') {
      response = await ProductService.getXLSXProducts();
    } else if (export_type === 'price') {
      response = await ProductService.getXLSXProductsPrice();
    }

    getFile(response, export_type);

    setIsPreloader(false);
    isActive(false);
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__import-export" style={{ width: 600 }}>
        <div className="modal-window-action__modal__title">
          <span>Export</span>
          <button onClick={closeModal}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10.5981 10.5977L21.4027 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M21.4023 10.5977L10.5978 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
          </button>
        </div>
        <div className="modal-window-action__modal__exports">
          <div>
            <input
              type="radio"
              name="export"
              value="products"
              id="export-products"
              defaultChecked={true}
            />
            <label htmlFor="export-products">Export all products in xlsx file</label>
          </div>
          <div>
            <input type="radio" name="export" value="price" id="export-price" />
            <label htmlFor="export-price">Export prices for all products in xlsx file</label>
          </div>
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={exportXLSX}>
            {isPreloader ? (
              <>
                <Preloader style={preloaderStyles.DARK} />
              </>
            ) : (
              'Export'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const DragAndDrop = ({ file, setFile }) => {
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    processFiles(e.target.files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    processFiles(e.dataTransfer.files);
  };

  const processFiles = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFile(file);
      }
    }
  };

  return (
    <>
      {file ? (
        <div className="imported__drag-and-drop">
          <div className="imported__drag-and-drop__icon">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.4535 8.36V30H4.54688V2H21.0935L27.4535 8.36Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M27.4535 8.36V9.64H19.8135V2H21.0935L27.4535 8.36Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
          <div className="imported__drag-and-drop__file-name">
            <span>{file.name}</span>
          </div>
          <div className="imported__drag-and-drop__remove">
            <button onClick={() => setFile(null)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_566_578)">
                  <path
                    d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75Z"
                    stroke="#315486"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M6.62354 6.62378L13.3764 13.3766"
                    stroke="#315486"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M13.3765 6.62378L6.6236 13.3766"
                    stroke="#315486"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_566_578">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div
          id="drop-area"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
          className="import__drag-and-drop"
        >
          <p>
            Drag & Drop your file here <span>.xlsx only</span>
          </p>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
      )}
    </>
  );
};

const Import = ({ isActive, getData }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isPreloader, setIsPreloader] = useState(false);
  const closeModal = () => {
    isActive(false);
  };

  const importXLSX = async () => {
    setIsPreloader(true);
    if (file) {
      const formData = new FormData();
      formData.append(`File`, file);

      const export_type = await document.querySelector('input[name=import]:checked').value;
      let response;

      if (export_type === 'products') {
        response = await ProductService.postXLSXProducts(formData);
      } else if (export_type === 'price') {
        response = await ProductService.putXLSXProductsPrice(formData);
      }

      if (response.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: response.message,
            r: Object.prototype.hasOwnProperty.call(response, 'response')
              ? { d: response.response.data, s: response.response.statusText }
              : '',
          }),
        );
      } else {
        getData();
      }
    }

    setIsPreloader(false);
    isActive(false);
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__import-export" style={{ width: 600 }}>
        <div className="modal-window-action__modal__title">
          <span>Import</span>
          <button onClick={closeModal}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10.5981 10.5977L21.4027 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M21.4023 10.5977L10.5978 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
          </button>
        </div>
        <div className="modal-window-action__modal__exports">
          <div>
            <input
              type="radio"
              name="import"
              value="products"
              id="export-products"
              defaultChecked={true}
            />
            <label htmlFor="export-products">Import all products in xlsx file</label>
          </div>
          <div>
            <input type="radio" name="import" value="price" id="export-price" />
            <label htmlFor="export-price">Import prices for all products in xlsx file</label>
          </div>
        </div>
        <div>
          <DragAndDrop file={file} setFile={setFile} />
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={importXLSX}>
            {isPreloader ? (
              <>
                <Preloader style={preloaderStyles.DARK} />
              </>
            ) : (
              'Import'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

function AdminProductList() {
  const navigate = useNavigate();
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const {
    data: products,
    isLoading: productsLoading,
    refetch: getData,
  } = useCustomQuery(queryKeys.PRODUCTS_ADMIN, () =>
    createProductsService(adminInstance).getAllProducts(searchQuery, pageSize, page),
  );

  const actionButtons = (item, deleteMethod) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} />
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  const goCreateProduct = () => {
    navigate('/admin/products/product');
  };

  const deleteItem = async (id) => {
    await ProductService.deleteProduct(id);
    getData();
  };

  const [isImport, setIsImport] = useState(false);
  const [isExport, setIsExport] = useState(false);

  return (
    <>
      {isImport && <Import isActive={setIsImport} getData={getData} />}
      {isExport && <Export isActive={setIsExport} />}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button className="import-export-button" onClick={() => setIsImport(true)}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1598_125)">
                <path
                  d="M10 16.0583L10 0.916683"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.625 9.68335L10 16.0583L16.375 9.68335"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.625 19.25L16.375 19.25"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0_1598_125">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(20 20.5) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>Import</span>
          </button>
          <button className="import-export-button" onClick={() => setIsExport(true)}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1598_126)">
                <path
                  d="M10 4.94165V20.0833"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16.375 11.3166L10 4.94165L3.625 11.3166"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16.375 1.75H3.625"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0_1598_126">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <span>Export</span>
          </button>
          <button onClick={goCreateProduct}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
              <path
                d="M7.63989 0.859863V16.1399"
                stroke="#FFF9F9"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
            <span>Add product</span>
          </button>
        </div>
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by name'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {productsLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={products ? products?.items : null}
              headers={['image', 'name', 'status', 'price', 'count']}
              deleteMethod={deleteItem}
              isAction={true}
              actionComponent={actionButtons}
            />
          </div>
        </div>
        {products?.pageSize < products?.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={products.hasNextPage}
            isPrevPage={products.hasPreviousPage}
            total={products.totalCount}
          />
        )}
      </div>
    </>
  );
}

export default AdminProductList;

import './AdminHeader.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LogoIcon from '../../../general/assets/svg/LogoIcon';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { routesAdmin } from '../../../general/routes';
import createAdminsService from '../../../general/services/admins';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import { Admin } from '../../../general/types/types';
import { getDataForage } from '../../../localforage';

const AdminHeader: React.FC = () => {
  const [userId, setUserId] = useState<string>('');

  const { data: admin } = useCustomQuery<Admin>(
    queryKeys.ADMIN_INFO,
    () => createAdminsService(adminInstance).getAdmin(userId),
    undefined,
    undefined,
    {
      enabled: !!userId,
    },
  );

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const data = await getDataForage<any>('a4ad');
        const parsedData = JSON.parse(data || '{}');
        const userId = parsedData?.a4d || '';
        setUserId(userId);
      } catch (err) {
        return null;
      }
    };

    fetchUserId();
  }, []);

  return (
    <div className="header-section">
      <div className="header-title">
        <LogoIcon />
        <Link to={routesAdmin.home.path}>
          <span>AsicXchange</span>
        </Link>
      </div>
      <div className="header-user">
        <Link to={`profile`}>Hello, {admin?.userName}</Link>
      </div>
    </div>
  );
};

export default AdminHeader;

import React from 'react';
import AdminShowcase from './showcase/Showcase';
import AdminVideoHomePage from './video/Video';
import AdminCompanyPartners from './company-partners/CompanyPartners';
import AdminTestimonials from './testimonials/Testimonials';
import AdminInfoBlock from './info-block/InfoBlock';
import Staff from './staff/Staff';
import AboutUsCarousel from './about-us-carousel/AboutUsCarousel';
import './AdminHome.scss';

const AdminHome: React.FC = () => {
  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">HOME</div>
      </div>
      <div className="page-section__content">
        <div className="page-section__content__home-grid">
          <div className="page-section__content__home-video home-container">
            <AdminVideoHomePage />
          </div>
          <div className="page-section__content__home-info-block home-container">
            <AdminInfoBlock />
          </div>
          <div className="page-section__content__home-company-partners home-container">
            <AdminCompanyPartners />
          </div>
          <div className="page-section__content__home-showcase home-container">
            <AdminShowcase />
          </div>
          <div className="page-section__content__home-reviews home-container">
            <AdminTestimonials />
          </div>
          <div className="page-section__content__home-staff home-container">
            <Staff />
          </div>
          <div className="page-section__content__home-about-us-carousel home-container">
            <AboutUsCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;

import React from 'react';

const PauseVideoIcon: React.FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M39.627 19.2798V44.7198" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M24.373 44.7198V19.2798" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default PauseVideoIcon;

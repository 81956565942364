import React from 'react';

const RefferalIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3609_630)">
      <path
        d="M11.4803 5.80034L14.1903 3.08034C15.2034 2.06722 16.5775 1.49805 18.0103 1.49805C19.443 1.49805 20.8171 2.06722 21.8303 3.08034C22.8434 4.09347 23.4126 5.46757 23.4126 6.90034C23.4126 8.33312 22.8434 9.70722 21.8303 10.7203L19.1103 13.4303"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.71025 10.5703L3.99025 13.2803C2.97713 14.2934 2.40796 15.6675 2.40796 17.1003C2.40796 18.5331 2.97713 19.9072 3.99025 20.9203C5.00338 21.9334 6.37748 22.5026 7.81026 22.5026C9.24303 22.5026 10.6171 21.9334 11.6303 20.9203L14.3403 18.2003"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6802 7.23047L8.14024 16.7705"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3609_630">
        <rect width="24" height="24" fill="white" transform="translate(0.910248)" />
      </clipPath>
    </defs>
  </svg>
);

export default RefferalIcon;

import createContactsService from '../../../../general/services/contacts';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import {
  FieldConfig,
  OrderBody,
  OrderProduct,
  Rates,
  ToResponceAddresses,
  TransactionBody,
  TransactionProcessAddress,
  UserCartForOrde,
} from '../../../../general/types/types';
import { errorMessages, regexp, validatePhoneNumber } from '../../../../general/utils/Validations';

interface ValueField {
  field: string;
  value: string;
}

export const getAddress = (
  isOptionVisible: boolean,
  blockId: string,
): { address: TransactionProcessAddress } => {
  const values: ValueField[] =
    blockId === 'shipping-fields'
      ? JSON.parse(localStorage.getItem('shipping-fields') || '[]')
      : JSON.parse(localStorage.getItem('billing-fields') || '[]');

  const findValue = (field: string) => values?.find((obj) => obj.field === field)?.value || '';

  const address: { address: TransactionProcessAddress } = {
    address: {
      name:
        findValue('name') ||
        (document.querySelector(`#${blockId} #firstName`) as HTMLInputElement)?.value.trim(),
      lastName:
        findValue('lastName') ||
        (document.querySelector(`#${blockId} #lastName`) as HTMLInputElement)?.value.trim(),
      company:
        findValue('company') ||
        (document.querySelector(`#${blockId} #company`) as HTMLInputElement)?.value.trim(),
      street1:
        findValue('street1') ||
        (document.querySelector(`#${blockId} #street1`) as HTMLInputElement)?.value.trim(),
      city:
        findValue('city') ||
        (document.querySelector(`#${blockId} #city`) as HTMLInputElement)?.value.trim(),
      state:
        findValue('state') ||
        (document.querySelector(`#${blockId} #state`) as HTMLInputElement)?.value.trim(),
      zip:
        findValue('zip') ||
        (document.querySelector(`#${blockId} #zip`) as HTMLInputElement)?.value.trim(),
      country:
        findValue('country') ||
        (document.querySelector(`#${blockId} #country`) as HTMLInputElement)?.value.trim(),
    },
  };

  if (isOptionVisible) {
    address.address.street2 = (
      document.querySelector(`#${blockId} #street2`) as HTMLInputElement
    )?.value.trim();
    address.address.street3 = (
      document.querySelector(`#${blockId} #street3`) as HTMLInputElement
    )?.value.trim();
    address.address.streetNumber = (
      document.querySelector(`#${blockId} #streetNumber`) as HTMLInputElement
    )?.value?.trim();
  }

  const phone =
    findValue('phone') ||
    (document.querySelector(`#${blockId} #phone`) as HTMLInputElement)?.value.trim();
  if (phone !== '') address.address.phone = phone;

  const email =
    findValue('email') ||
    (document.querySelector(`#${blockId} #email`) as HTMLInputElement)?.value.trim();
  if (email !== '') address.address.email = email;

  const comment =
    findValue('comment') ||
    (document.querySelector(`#${blockId} #comment`) as HTMLInputElement)?.value;
  if (comment !== undefined) address.address.metadata = comment;

  return address;
};

export const getAddressFrom = async () => {
  const isAddressValid = (address: TransactionProcessAddress) => {
    for (const key in address) {
      if (address[key] === '' || address[key] === null) {
        return false;
      }
    }
    return true;
  };

  const responce = await createContactsService(clientInstance).getContacts();

  if (!isAddressValid(responce.data.address)) {
    return { address: null };
  }

  return { address: responce.data.address };
};

export const getInfoForRates = async (
  companyAddress: TransactionProcessAddress,
  addressTo: TransactionProcessAddress,
  billingAddress: TransactionProcessAddress | undefined = undefined,
  userCart: UserCartForOrde | undefined = undefined,
): Promise<ToResponceAddresses> => {
  async function fillProductsTable(userCart: any) {
    const promises = userCart.pc.map(async (element: any) => {
      const { data } = await createProductsService(clientInstance).getProductByURL(element.p.url);
      const size = data.characteristics.filter((item: any) =>
        item.name.toLowerCase().includes('miner size') &&
        element.p.condition.toLowerCase() === 'new'
          ? item.name.includes('with package')
          : item.name.includes('w/o package') || item.name.includes('w/o package'),
      );

      const table = {
        weight:
          (data.characteristics.length > 0 &&
            data.characteristics.find((item: any) =>
              element.p.condition.toLowerCase() === 'new'
                ? item.name.toLowerCase().includes('gross weight')
                : item.name.toLowerCase().includes('net weight') ||
                  item.name.includes('w/o package'),
            )?.value) ||
          1,
        massUnit: 'kg',
        distanceUnit: 'mm',
        length: size.length > 0 ? size[0].value.split('*')[0] : 1,
        width: size.length > 0 ? size[0].value.split('*')[1] : 1,
        height: size.length > 0 ? size[0].value.split('*')[2] : 1,
      };

      return table;
    });

    const tables = await Promise.all(promises);
    const sumTable = {
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
    };

    tables.forEach((table: any) => {
      sumTable.weight += parseFloat(table.weight);
      sumTable.length += parseFloat(table.length);
      sumTable.width += parseFloat(table.width);
      sumTable.height += parseFloat(table.height);
    });

    return {
      distanceUnit: 'mm',
      height: sumTable.height.toString(),
      length: sumTable.length.toString(),
      massUnit: 'kg',
      weight: sumTable.weight.toString(),
      width: sumTable.width.toString(),
    };
  }

  const toReates: ToResponceAddresses = {
    addressFrom: companyAddress.address,
    addressTo: addressTo.address,
  };

  if (userCart !== undefined) toReates.parcelTables = [await fillProductsTable(userCart)];
  if (billingAddress !== undefined) toReates.billingAddress = billingAddress.address;

  return toReates;
};

export const getInfoForTransaction = (
  rates: Rates,
  choosedRateId: string | undefined,
  isForUsed: boolean | undefined,
): TransactionBody => {
  const body: TransactionBody = {
    transactionDTO: {
      billingAddressId: rates.billingAddressId,
      addressFromId: rates.idAddressFrom,
      addressToId: rates.idAddressTo,
    },
  };

  if (!isForUsed) {
    body.transactionDTO.objectId = choosedRateId;
    body.transactionDTO.labelFileType = 'PDF';
    body.transactionDTO.parcelsIds = rates.parselsIds;
  }

  return body;
};

export const getBodyForOrder = (
  userCart: UserCartForOrde,
  userId: string,
  transaction: string,
  contact: { name: string; value: string },
  shippingPriceForUnit: number,
): OrderBody => {
  const order: OrderProduct[] = userCart.pc.map((element: any) => ({
    productId: element.p.id,
    productName: element.p.name,
    quantity: element.c,
  }));

  const body: OrderBody = {
    userId: userId,
    orderedProducts: order,
    transactionId: transaction,
    contacts: contact,
    shippingPriceForUnit: shippingPriceForUnit,
  };

  return body;
};

export const getInputs = (fieldsName: string, option: boolean): FieldConfig[] => {
  const fieldsConfig: FieldConfig[] = [
    {
      input: document.querySelector(`#${fieldsName} #firstName`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'name',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #lastName`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'lastName',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #company`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'company',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: document.querySelector(`#${fieldsName} #street1`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'street1',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #country`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'country',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #state`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'state',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #city`) as HTMLInputElement,
      regex: regexp.string,
      errorField: 'city',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #zip`) as HTMLInputElement,
      regex: regexp.numbers,
      errorField: 'zip',
      errorMessage: errorMessages[0],
    },
    {
      input: document.querySelector(`#${fieldsName} #phone`) as HTMLInputElement,
      validateFunc: validatePhoneNumber,
      errorField: 'phone',
    },
    {
      input: document.querySelector(`#${fieldsName} #email`) as HTMLInputElement,
      regex: regexp.email_regexp,
      errorField: 'email',
      errorMessage: errorMessages[1],
    },
  ];

  if (option) {
    fieldsConfig.push(
      {
        input: document.querySelector(`#${fieldsName} #street2`) as HTMLInputElement,
        regex: regexp.string,
        errorField: 'street2',
        errorMessage: errorMessages[0],
        optional: true,
      },
      {
        input: document.querySelector(`#${fieldsName} #street3`) as HTMLInputElement,
        regex: regexp.string,
        errorField: 'street3',
        errorMessage: errorMessages[0],
        optional: true,
      },
      {
        input: document.querySelector(`#${fieldsName} #streetNumber`) as HTMLInputElement,
        regex: regexp.string,
        errorField: 'streetNumber',
        errorMessage: errorMessages[0],
        optional: true,
      },
    );
  }

  return fieldsConfig;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContactMethod, ProductsStoreAddress, ValidationError } from '../../types/types';

export const billingState = {
  DEFAULT: 'default',
  SAME: 'same',
  NOT_SAME: 'not_same',
} as const;

export const paymentServicesEnum = {
  DEFAULT: 'default',
  BANK_TRANSACTION: 'Bank / Wire Transfer',
  STABLE_COIN: 'Stablecoin Payments',
  // COIN_PAL: 'CoinPal',
} as const;

export const deliveryMethodsEnum = {
  SHIPPING: 'Shipping',
  SELF_PICKUP: 'Self Pickup',
} as const;

export const paymentCurrenciesEnum = {
  USD: 'USD',
  GBR: 'GBR',
  EUR: 'EUR',
  HKD: 'HKD',
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  BTC: 'BTC',
  ETH: 'ETH',
  USDT: 'USDT',
  USDC: 'USDC',
} as const;

export type BillingState = (typeof billingState)[keyof typeof billingState];
export type PaymentService = (typeof paymentServicesEnum)[keyof typeof paymentServicesEnum];
export type PaymentCurrency = (typeof paymentCurrenciesEnum)[keyof typeof paymentCurrenciesEnum];
export type DeliveryMethod = (typeof deliveryMethodsEnum)[keyof typeof deliveryMethodsEnum];

interface RatesResponse {
  rates: any;
  idAddressFrom: string;
  billingAddressId: string;
  idAddressTo: string;
  parselsIds: string[];
}

interface ProcessingState {
  shippingPrice: number | null;
  reciept: string | null;
  isUploadRecieptLater: boolean;
  fieldsErrors: ValidationError[];
  paymentService: string;
  paymentCurrency: PaymentCurrency;
  paymentAmount: number;
  paymentCurrencyRate: number;
  paymentAmountInCurrency: number;
  paymentAmountInCurrencyRate: number;
  contactMethod: ContactMethod | null;
  user: any; // Define the shape of the user object as needed
  ratesResponce: RatesResponse | null;
  choosenRateId: string | null;
  isOptionalLocationVisible: boolean;
  isBillingTheSame: BillingState;
  isOptionLocationBillingVisible: boolean;
  deliveryMethod: DeliveryMethod;
  count: number;
  shippingStoresLocationAddress: ProductsStoreAddress[];
  shippingStoresLocationTransactionId: string | null;
  tax: number;
}

const initialState: ProcessingState = {
  shippingPrice: null,
  reciept: null,
  isUploadRecieptLater: false,
  fieldsErrors: [],
  paymentService: paymentServicesEnum.DEFAULT,
  paymentCurrency: paymentCurrenciesEnum.USD,
  paymentAmount: 0,
  paymentCurrencyRate: 0,
  paymentAmountInCurrency: 0,
  paymentAmountInCurrencyRate: 0,
  contactMethod: null,
  user: null,
  ratesResponce: null,
  choosenRateId: null,
  isOptionalLocationVisible: false,
  isBillingTheSame: billingState.DEFAULT,
  isOptionLocationBillingVisible: false,
  count: 0,
  deliveryMethod: deliveryMethodsEnum.SHIPPING,
  shippingStoresLocationAddress: [],
  shippingStoresLocationTransactionId: null,
  tax: 0,
};

const ProcessingSlice = createSlice({
  name: 'clientCartProcessing',
  initialState,
  reducers: {
    initUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setChoosenRateId: (state, action: PayloadAction<string | null>) => {
      state.choosenRateId = action.payload;
    },
    setIsOptionalLocationVisible: (state, action: PayloadAction<boolean>) => {
      state.isOptionalLocationVisible = action.payload;
    },
    setIsBillingTheSame: (state, action: PayloadAction<BillingState>) => {
      state.isBillingTheSame = action.payload;
    },
    setIsOptionLocationBillingVisible: (state, action: PayloadAction<boolean>) => {
      state.isOptionLocationBillingVisible = action.payload;
    },
    setFieldsErrors: (state, action: PayloadAction<ValidationError[]>) => {
      state.fieldsErrors = action.payload;
    },
    setContMethod: (state, action: PayloadAction<ContactMethod | null>) => {
      state.contactMethod = action.payload;
    },
    setPaymntService: (state, action: PayloadAction<string>) => {
      state.paymentService = action.payload;
    },
    setPaymntCurrency: (state, action: PayloadAction<PaymentCurrency>) => {
      state.paymentCurrency = action.payload;
    },
    setReciept: (state, action: PayloadAction<string | null>) => {
      state.reciept = action.payload;
    },
    setDeliveryMethodRedux: (state, action: PayloadAction<DeliveryMethod>) => {
      state.deliveryMethod = action.payload;
    },
    addShippingStoreLocationAddress: (state, action: PayloadAction<ProductsStoreAddress>) => {
      state.shippingStoresLocationAddress.push(action.payload);
    },
    removeShippingStoreLocationAddress: (state, action: PayloadAction<string>) => {
      state.shippingStoresLocationAddress = state.shippingStoresLocationAddress.filter(
        (store) => store.id !== action.payload,
      );
    },
    setShippingStoreLocationAddress: (state, action: PayloadAction<ProductsStoreAddress[]>) => {
      state.shippingStoresLocationAddress = action.payload;
    },
    setShippingStoresLocationTransactionId: (state, action: PayloadAction<string | null>) => {
      state.shippingStoresLocationTransactionId = action.payload;
    },
    setReatesResponce: (state, action: PayloadAction<RatesResponse | null>) => {
      state.ratesResponce = action.payload;
    },
    setIsUplaodRecieptLater: (state, action: PayloadAction<boolean>) => {
      state.isUploadRecieptLater = action.payload;
    },
    setTax: (state, action: PayloadAction<number>) => {
      state.tax = action.payload;
    },
  },
});

export const {
  initUser,
  setChoosenRateId,
  setIsOptionalLocationVisible,
  setIsBillingTheSame,
  setIsOptionLocationBillingVisible,
  setFieldsErrors,
  setContMethod,
  setPaymntService,
  setPaymntCurrency,
  setReciept,
  setDeliveryMethodRedux,
  addShippingStoreLocationAddress,
  removeShippingStoreLocationAddress,
  setShippingStoreLocationAddress,
  setShippingStoresLocationTransactionId,
  setReatesResponce,
  setIsUplaodRecieptLater,
  setTax,
} = ProcessingSlice.actions;

export default ProcessingSlice.reducer;

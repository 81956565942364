import './Payment.scss';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CalendarIcon from '../../../../../general/assets/svg/CalendarIcon';
import CopiedIcon from '../../../../../general/assets/svg/CopiedIcon';
import InformationIcon from '../../../../../general/assets/svg/InformationIcon';
import NotCopiedTableIcon from '../../../../../general/assets/svg/NotCopiedTableIcon';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../../../general/hooks/useResize';
import { queryKeys } from '../../../../../general/queryKeys';
import {
  paymentServicesEnum,
  setIsUplaodRecieptLater,
  setReciept,
} from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../../general/redux/store';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';
import createPaymentService from '../../../../../general/services/payment';
import createStaticPaymentInfosService from '../../../../../general/services/staticPayments';
import {
  initialValuesStableCoin,
  validationSchemaStableCoin,
} from '../../../../../general/utils/YupValidators';
import Copie from './Copie';
import PaymentReceiptUpload from './DragAndDropCheck';

interface BankTransferInfo {
  recipientName: string;
  recipientAddress: string;
  countryOrRegion: string;
  bankName: string;
  bankAddress: string;
  accountNumber: string;
  swiftCode: string;
  routingIntermediaryNumber: string;
  institutionId: string;
}

interface StableCoinInfo {
  id: string;
  name: string;
  address: string;
  qrURL: string;
}

interface ToolTripProps {
  className: string;
}

const ToolTrip: React.FC<ToolTripProps> = ({ className }) => (
  <span className={className}>
    <span className="main-text">Processing times may vary depending on the type of transfer:</span>
    <ul>
      <li>Domestic transfers within Canada: typically 1-2 business days.</li>
      <li>International SWIFT transfers: 1-5 business days.</li>
      <li>
        Transfers initiated on weekends or holidays may be processed on the next business day.
      </li>
    </ul>
  </span>
);

interface ServicesPros {
  services: { name: string }[];
  service: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
  ) => void;
  groupName: string;
  isBankTransferInfoVisible: boolean;
  isTableCoinVisible: boolean;
}

interface StableCoinsContentProps {
  addressName?: string;
  address?: string;
  qr?: string;
}

const StableCoinsContent: React.FC<StableCoinsContentProps> = ({ addressName, address, qr }) => {
  const userCart = useSelector((state: RootState) => state.pc);
  const [copied, setCopied] = useState<{ amount: boolean; address: boolean }>({
    amount: false,
    address: false,
  });
  const [copyText, setCopyText] = useState<{ amount: string; address: string }>({
    amount: 'Copy',
    address: 'Copy',
  });

  const { data: shippingCostData } = useCustomQuery(
    queryKeys.SHIPPING_COST,
    () => createStaticPaymentInfosService(clientInstance).getInfos(),
    (data: any) => ({ lastId: data[0].id, shippingCost: data[0].shippingCost }),
  );

  const getShippingCost = () => {
    const amount = userCart.pc.reduce((total: number, order: any) => total + order.c, 0);
    return amount * (shippingCostData?.shippingCost || 0);
  };

  const getTotal = () => {
    const subtotal = userCart.pc.reduce(
      (total: number, order: any) => total + order.p.price * order.c,
      0,
    );

    const shippingCost = window.location.href.includes('shipping') ? getShippingCost() : 0;
    return (subtotal + shippingCost).toFixed(2);
  };

  const copyToClipboard = (text: string, type: 'amount' | 'address') => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied((prevState) => ({
        ...prevState,
        [type]: true,
      }));
      setCopyText((prevState) => ({
        ...prevState,
        [type]: 'Copied',
      }));
      setTimeout(() => {
        setCopyText((prevState) => ({
          ...prevState,
          [type]: 'Copy',
        }));
      }, 2000);
    });
  };

  return (
    <>
      <div className="tab-content">
        <p>To make a payment, send USDT to the address below</p>
        <div className="table">
          <div className="row">
            <div className="main-info">
              <span>Amount</span>
              <span>{getTotal()}</span>
            </div>
            <div className="copie" onClick={() => copyToClipboard(getTotal(), 'amount')}>
              <button>{copyText.amount}</button>
              {copied.amount ? (
                <CopiedIcon width="24" height="24" viewBox="0 0 24 24" />
              ) : (
                <NotCopiedTableIcon />
              )}
            </div>
          </div>
          <div className="row">
            <div className="main-info">
              <span>{addressName}</span>
              <span>{address}</span>
            </div>
            <div className="copie" onClick={() => address && copyToClipboard(address, 'address')}>
              <button>{copyText.address}</button>
              {copied.address ? (
                <CopiedIcon width="24" height="24" viewBox="0 0 24 24" />
              ) : (
                <NotCopiedTableIcon />
              )}
            </div>
          </div>
        </div>
        <div className="qr-container">
          <div
            className="qr"
            style={{
              backgroundImage: `url(${qr})`,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const Services: React.FC<ServicesPros> = ({
  services,
  service,
  handleChange,
  groupName,
  isBankTransferInfoVisible,
  isTableCoinVisible,
}) => {
  const dispatch = useDispatch();
  const resize = useResize();

  const { data: stableCoinsInfos, isLoading: stableCoinsInfosLoading } = useCustomQuery<
    StableCoinInfo[]
  >(queryKeys.STABLE_COIN_INFOS, () =>
    createStaticPaymentInfosService(clientInstance).getStableCoinInfos(),
  );

  const userId = useSelector((state: RootState) => state.clps.cp2);
  const [stableCoinTab, setStableCoinTab] = useState<StableCoinInfo | null>(null);
  const [copiedIndexes, setCopiedIndexes] = useState<number[]>([]);
  const [lastCopiedIndex, setLastCopiedIndex] = useState<number | null>(null);
  const [isHoveredInfo, setIsHoveredInfo] = useState<boolean>(false);
  const [isUplaodLater, setIsUplaodLater] = useState<boolean>(false);
  const reciept = useSelector((state: RootState) => state.clientCartProcessing.reciept);

  useEffect(() => {
    if (stableCoinsInfos && stableCoinsInfos.length > 0) {
      setStableCoinTab(stableCoinsInfos[0]);
    }
  }, [stableCoinsInfos]);

  useEffect(() => {
    dispatch(setIsUplaodRecieptLater(isUplaodLater));
  }, [dispatch, isUplaodLater]);

  const { data: bankTransferInfos, isLoading: bankTransferInfosLoading } = useCustomQuery<
    BankTransferInfo[]
  >(
    queryKeys.BANK_TRANSFER_INFOS,
    () => createPaymentService(clientInstance).getBankTransferDetails(),
    undefined,
    undefined,
    {
      enabled: service === paymentServicesEnum.BANK_TRANSACTION,
    },
  );

  const bankTransferRequiredInfo = useMemo(
    () =>
      [
        {
          title: 'Your user ID',
          value: userId,
          helper: 'Required in a field like “memo”, “description”, or “notes”',
        },
        ...(bankTransferInfos
          ? bankTransferInfos.map((info) => [
              { title: 'Recipient Name', value: info.recipientName },
              { title: 'Recipient Address', value: info.recipientAddress },
              { title: 'Country or Region', value: info.countryOrRegion },
              { title: 'Bank Name', value: info.bankName },
              { title: 'Bank Address', value: info.bankAddress },
              { title: 'Account Number', value: info.accountNumber },
              { title: 'Swift Code', value: info.swiftCode },
              {
                title: 'Routing Intermediary Number',
                value: info.routingIntermediaryNumber,
              },
              { title: 'Institution ID', value: info.institutionId },
            ])
          : []),
      ].flat(),
    [bankTransferInfos, userId],
  );

  const handleCopyValue = (value: string, index: number) => {
    navigator.clipboard.writeText(value).then(() => {
      // Удаление индекса перед добавлением его снова
      setCopiedIndexes((prev) => {
        const updatedIndexes = prev.filter((i) => i !== index);
        return [...updatedIndexes, index];
      });
      setLastCopiedIndex(index);
    });
  };

  return (
    <>
      {services.map(({ name }, index) => (
        <div
          onClick={() => handleChange({ target: { value: name } })}
          className="service"
          key={index}
        >
          <label className="custom-radio">
            <input
              type="radio"
              name={groupName}
              value={name}
              checked={name === service}
              onChange={handleChange}
            />
            <span className="radio-btn"></span>
          </label>
          <p>{name}</p>
        </div>
      ))}
      {/* BANK_TRANSFER */}
      <div
        className={`payment-bank-transfer-infos drop-down-content ${
          isBankTransferInfoVisible ? 'open' : 'close'
        }`}
      >
        <span className="main-info">
          To deposit via wire transfer, copy and paste the required info to your online banking
          system
        </span>
        <div className="required-info">
          <span className="header">Required info</span>
          {bankTransferInfosLoading ? (
            <Preloader />
          ) : (
            <>
              {bankTransferRequiredInfo?.length > 0 &&
                bankTransferRequiredInfo.map((info, index) => (
                  <div
                    key={index}
                    className="info"
                    onClick={() => handleCopyValue(info.value, index)}
                  >
                    <div className="main-infos">
                      <span className="title">{info.title}</span>
                      <div className="value">
                        <span className="value">{info.value}</span>
                        {'helper' in info && <span className="helper">{info.helper}</span>}
                      </div>
                    </div>
                    <Copie
                      index={index}
                      copiedIndexes={copiedIndexes}
                      lastCopiedIndex={lastCopiedIndex}
                    />
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="other-info">
          <span className="header">Other info</span>
          <div className="processing-info">
            <CalendarIcon />
            <span className="tooltip-container">
              Processing time: 1-5 business days{' '}
              <span
                className="processing-time-info"
                onMouseEnter={() => setIsHoveredInfo(true)}
                onMouseLeave={() => setIsHoveredInfo(false)}
                onClick={() => setIsHoveredInfo(!isHoveredInfo)}
              >
                <InformationIcon />
                {resize.width >= 768 && <ToolTrip className="tooltip-text" />}
              </span>
            </span>
          </div>
          {resize.width < 768 && (
            <ToolTrip className={`tooltip-text ${isHoveredInfo ? 'hovered' : ''}`} />
          )}
          <div className="fees-info">
            <span>%</span>
            <span>
              Fees: We do not charge fees for deposits via wire transfer, but your bank may charge a
              wire transfer fee.
            </span>
          </div>
        </div>
        <PaymentReceiptUpload />
        {!reciept && (
          <div className="check-box__container" id="upload-later-reciept-container">
            <input
              id="upload-later"
              type="checkbox"
              onChange={() => setIsUplaodLater(!isUplaodLater)}
              checked={isUplaodLater}
            />
            <label htmlFor="upload-later" />
            <span>Upload payment receipt later</span>
          </div>
        )}
      </div>
      {/* STABLE_COIN */}
      <div
        id="stable-coin"
        className={`table-coin drop-down-content ${isTableCoinVisible ? 'open' : 'close'}`}
      >
        {stableCoinsInfosLoading || !stableCoinsInfos ? (
          <Preloader />
        ) : (
          <>
            <div className="tabs">
              {stableCoinsInfos?.map((coin, index) => (
                <button
                  key={index}
                  className={`tab-button ${stableCoinTab?.name === coin.name ? 'active' : ''}`}
                  onClick={() => setStableCoinTab(coin)}
                >
                  {coin.name}
                </button>
              ))}
            </div>

            <StableCoinsContent
              addressName={stableCoinTab?.name}
              address={stableCoinTab?.address}
              qr={stableCoinTab?.qrURL}
            />
            <p style={{ marginBottom: '0px' }}>
              Please enter Transaction ID or Transaction link to confirm payment:
            </p>
            <Formik
              initialValues={initialValuesStableCoin}
              validationSchema={validationSchemaStableCoin}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={() => {}} // Пустая функция, так как submit не нужен
            >
              {({ values, setFieldValue, setFieldTouched, setFieldError, errors }) => {
                const handleRecieptChange = async (e: ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;

                  // Обновление значения в Formik
                  setFieldValue('reciept', value);
                  setFieldTouched('reciept', true);

                  // Проверка на ошибки валидации
                  try {
                    await validationSchemaStableCoin.validateAt('reciept', { reciept: value });
                    setFieldError('reciept', undefined);
                    dispatch(setReciept(value));
                  } catch (err: any) {
                    setFieldError('reciept', err.message);
                    dispatch(setReciept(null));
                  }
                };

                return (
                  <Form className="transaction-id_form" id="stable-coins-reciept">
                    <label className="transaction-id-label" htmlFor="reciept">
                      Transaction ID / Link
                    </label>
                    <Field
                      name="reciept"
                      type="text"
                      value={values.reciept}
                      onChange={handleRecieptChange}
                      placeholder="Transaction ID / Link"
                    />
                    <ErrorMessage className="transaction-errors" name="reciept" component="div" />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default Services;

import './AdminShowcase.scss';

import debounce from 'lodash/debounce';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import MinusIcon from '../../../../general/assets/svg/MinusIcon';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { images } from '../../../../general/StaticData';
import { PaggingObj, ProductMax } from '../../../../general/types/types';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import { ShowcaseService } from '../../../service/ShowcaseServices';

interface ShowcaseProduct {
  id: string;
  image: {
    url: string;
  };
  name: string;
  url: string;
  mainPageOrder: string;
}

interface ShowcaseModalProps {
  isOpen: (isOpen: boolean) => void;
  showcase: ProductMax[];
}

const ShowcaseModal: React.FC<ShowcaseModalProps> = ({ isOpen, showcase }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    isOpen(false);
  };

  const convertProductMaxToShowcaseProduct = (productMax: ProductMax): ShowcaseProduct => {
    return {
      id: productMax.id,
      image: { url: productMax.image.url },
      name: productMax.name,
      url: productMax.url,
      mainPageOrder: '', // you can define how to get this value if needed
    };
  };

  const convertProductMaxArrayToShowcaseProducts = (
    productsMax: ProductMax[],
  ): ShowcaseProduct[] => {
    return productsMax.map(convertProductMaxToShowcaseProduct);
  };

  const [showcaseProducts, setShowcaseProducts] = useState<ShowcaseProduct[]>(
    convertProductMaxArrayToShowcaseProducts(showcase),
  );
  const [removedShowcaseProducts, setRemovedShowcaseProducts] = useState<string[]>([]);

  const [search, setSearch] = useState<string>('');

  const [isSavePreloader, setIsSavePreloader] = useState<boolean>(false);

  const {
    data: allProducts,
    isLoading: gettingProds,
    refetch: getData,
  } = useCustomQuery<PaggingObj<ProductMax>>(
    queryKeys.ADMIN_SHOW_CASE,
    () => createProductsService(adminInstance).getAllProducts(search, 1000, 1),
    undefined,
    undefined,
    {
      onSuccess: (data: any) => {
        if (showcaseProducts.length !== 0) {
          return data.items.filter(
            (item: ProductMax) => !showcaseProducts.find((s) => s.id === item.id),
          );
        } else {
          return data.items;
        }
      },
    },
  );

  useEffect(() => {
    getData();
  }, [getData, showcaseProducts, search]);

  const addToShowcase = (e: React.MouseEvent<HTMLButtonElement>) => {
    const datasetId = e.currentTarget.dataset.id;

    if (showcaseProducts.length === 5) {
      return;
    }

    setRemovedShowcaseProducts((items) => {
      return items.filter((item) => item !== datasetId);
    });

    const temp = allProducts && allProducts.items.find((item: any) => item.id === datasetId);

    if (temp) {
      setShowcaseProducts((items: any) => {
        items.push({
          id: temp.id,
          image: temp.images[0],
          name: temp.name,
          url: temp.url,
          mainPageOrder: '',
        });

        return items;
      });

      getData();
    }
  };

  const removeFromShowcase = (e: MouseEvent<HTMLButtonElement>) => {
    const datasetId = e.currentTarget.dataset.id;

    setRemovedShowcaseProducts((removed) => {
      const id = showcase.find((item) => item.id === datasetId)?.id;
      if (id !== undefined && !removed.includes(id)) {
        removed.push(id);
        return [...removed];
      }

      return removed;
    });

    setShowcaseProducts((items) => {
      return items.filter((item) => item.id !== datasetId);
    });
  };

  const removeFromAPI = async () => {
    removedShowcaseProducts.forEach(async (item) => {
      const result = {
        productId: item,
        isOnMainPage: false,
        mainPageOrder: 1,
      };
      await ShowcaseService.putMainPageProductsStatus(result);
    });
  };

  const addToAPI = async () => {
    showcaseProducts.forEach((item) => {
      const result = {
        productId: item.id,
        isOnMainPage: true,
        mainPageOrder: item.mainPageOrder === '' ? 1 : item.mainPageOrder,
      };
      ShowcaseService.putMainPageProductsStatus(result);
    });
  };

  const saveShowcase = async () => {
    setIsSavePreloader(true);

    if (showcaseProducts.length === 5) {
      await removeFromAPI();
      await addToAPI();

      closeModal();
    } else {
      dispatch(
        setInfo({
          n: 'Request failed',
          r: { d: { detail: 'The showcase must contain 5 elements.' }, s: '' },
        }),
      );
    }

    setIsSavePreloader(false);
  };

  const searchItems = debounce((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '' || e.target.value.length >= 1) {
      setSearch(e.target.value);
    }
  }, 500);

  const changeItemPosition = (e: ChangeEvent<HTMLInputElement>) => {
    const product = showcaseProducts.find((item) => item.id === e.target.dataset.id);
    if (product) {
      product.mainPageOrder = e.target.value;
    }
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Showcase'} mWidth={'70%'}>
      <div className="modal-window-action__modal__showcase">
        <div className="modal-window-action__modal__showcase__picked-products">
          <div className="modal__showcase__products">
            {showcaseProducts?.length === 0 ? (
              <div className="modal__showcase__products__empty">Showcase is empty</div>
            ) : (
              <>
                <ul className="modal__showcase__products__list">
                  {showcaseProducts &&
                    showcaseProducts.map((product, index) => (
                      <li key={index}>
                        <div className="modal__showcase__products__list__product">
                          <input
                            onChange={changeItemPosition}
                            data-id={product?.id}
                            className="showcase__products__list__product__order"
                            type="number"
                            placeholder="id"
                            defaultValue={product?.mainPageOrder}
                          />
                          <div
                            className="selected-img"
                            style={{
                              backgroundImage: `url(${
                                product?.image?.url === null || product?.image?.url === undefined
                                  ? images.defaultImg
                                  : product?.image?.url
                              })`,
                              margin: '0 10px',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              width: '90px',
                              height: '90px',
                            }}
                          />
                          <span>{product?.name}</span>
                        </div>
                        <div className="modal__showcase__products__list__button">
                          <button onClick={removeFromShowcase} data-id={product.id}>
                            <MinusIcon />
                          </button>
                        </div>
                      </li>
                    ))}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="modal-window-action__modal__showcase__all-products">
          <div className="modal__showcase__search inputs">
            <input type="text" placeholder="Product name" onChange={searchItems} />
          </div>
          <div className="modal__showcase__products">
            {gettingProds ? (
              <div className="search-preloader">
                <Preloader />
              </div>
            ) : (
              <>
                {allProducts?.items.length === 0 ? (
                  <div className="modal__showcase__products__empty">Products not found</div>
                ) : (
                  <>
                    <ul className="modal__showcase__products__list">
                      {allProducts &&
                        allProducts.items.map((product, index) => (
                          <li key={index}>
                            <div className="modal__showcase__products__list__product">
                              <div
                                className="selected-img"
                                style={{
                                  backgroundImage: `url(${
                                    product?.images[0]?.url === undefined
                                      ? images.defaultImg
                                      : product?.images[0]?.url
                                  })`,
                                  margin: '0 10px',
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  width: '90px',
                                  height: '90px',
                                }}
                              />
                              <span>{product?.name}</span>
                            </div>
                            <div className="modal__showcase__products__list__button">
                              <button onClick={addToShowcase} data-id={product.id}>
                                <PlusIcon />
                              </button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        <button className="section-btn-gradient" onClick={saveShowcase}>
          {isSavePreloader ? <Preloader style={preloaderStyles.DARK} /> : 'Save'}
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default ShowcaseModal;

interface FreeImages {
  defaultImg: string;
  noGoods: string;
  poolPosibility1: string;
  poolPosibility2: string;
  poolPosibility3: string;
}

interface Google {
  authClientId: string;
  gaTrackingId: string;
  mapPb: string;
}

interface Urls {
  dev: string;
  prodOrTestDev: string;
}

interface PartnerLinks {
  ecmdIo: string;
}

export type ENVIROMENT = 'DEV_LOCAL' | 'DEV_SITE' | 'PROD';

export const enviroments = {
  DEV_LOCAL: 'DEV_LOCAL' as ENVIROMENT,
  DEV_SITE: 'DEV_SITE' as ENVIROMENT,
  PROD: 'PROD' as ENVIROMENT,
};

export const enviroment = enviroments.PROD; // TODO attendtion

export const urls: Urls = {
  dev: 'http://localhost:20080',
  prodOrTestDev: '/api',
};

export const partnerLinks: PartnerLinks = {
  ecmdIo: 'https://emcd.io/pool/dashboard/registration?promo=OtJ20VMw',
};

export const images: FreeImages = {
  defaultImg: 'https://s3.tebi.io/media-folder/b25df7ad-ae22-4dac-a639-66f6503724ee',
  noGoods: 'https://s3.tebi.io/media-folder/3472a84f-aa53-47d3-9d36-c5d339526fc6',
  poolPosibility1: 'https://s3.tebi.io/asicxchange-media/96f55dc7-3692-44bd-9090-2747d7cbf800',
  poolPosibility2: 'https://s3.tebi.io/asicxchange-media/14717b71-078b-4106-9139-0c5353bbf4a5',
  poolPosibility3: 'https://s3.tebi.io/asicxchange-media/408d28ae-5813-4e9d-b21a-0c4eb9aa84ab',
};

export const googleFeatures: Google = {
  authClientId: '271131907807-j5s6vtpbufq88mht5j6059iok28ods6s.apps.googleusercontent.com',
  gaTrackingId: 'G-349E87DSP5',
  mapPb:
    '!1m14!1m8!1m3!1d2798.087135730231!2d-73.8771261!3d45.4680486!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93bd4eaf56261:0xd63b93c434021668!2sASICXchange!5e0!3m2!1sru!2sua!4v1699441599787!5m2!1sen!2sua',
};

export const hostingMapLibreKey = '9d3TxD9kpQgr5wRM8kSg';

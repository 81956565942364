import React, { Fragment, MouseEvent, useState } from 'react';

import { ProductMax } from '../../../general/types/types';

interface TabsProps {
  product: ProductMax;
}

const Tabs: React.FC<TabsProps> = ({ product }) => {
  const [isPaymentTab, setIsPaymentTab] = useState('payment-delivery');

  const changeTab = (e: MouseEvent<HTMLButtonElement>) => {
    const buttons = document.querySelectorAll<HTMLButtonElement>('#Product-Tab');
    buttons.forEach((button) => button.classList.remove('product-active-tabs'));

    const target = e.target as HTMLButtonElement;
    if (target.dataset.name === 'payment-delivery') {
      setIsPaymentTab('payment-delivery');
      target.classList.add('product-active-tabs');
    } else if (target.dataset.name === 'characteristics') {
      setIsPaymentTab('characteristics');
      target.classList.add('product-active-tabs');
    } else if (target.dataset.name === 'description') {
      setIsPaymentTab('description');
      target.classList.add('product-active-tabs');
    }

    e.stopPropagation();
  };

  return (
    <>
      <div className="product-section-main-info__tabs-buttons">
        <button
          className="product-section-main-info__tabs-button product-active-tabs"
          data-name="payment-delivery"
          id="Product-Tab"
          onClick={changeTab}
        >
          Payment and delivery
        </button>
        <button
          className="product-section-main-info__tabs-button"
          data-name="characteristics"
          id="Product-Tab"
          onClick={changeTab}
        >
          Additional characteristics
        </button>
        {product?.description !== '' && (
          <button
            className="product-section-main-info__tabs-button"
            data-name="description"
            id="Product-Tab"
            onClick={changeTab}
          >
            Additional description
          </button>
        )}
      </div>
      {isPaymentTab === 'payment-delivery' && (
        <div className="product-section-main-info__tabs-content">
          <div className="product-section-main-info__tabs-content__payment-delivery">
            <div>
              <span>Payment</span>
              <p>We offer convenient payment options:</p>
              <ul>
                <li>
                  <span>Bank Transfer</span>: Secure and reliable.
                </li>
                <li>
                  <span>Credit Card</span>: Quick and easy.
                </li>
                <li>
                  <span>Card Payment at Pickup</span>: Pay by card when you collect your order
                </li>
              </ul>
            </div>
            <div>
              <span>Delivery</span>
              <p>Choose a delivery option that suits you:</p>
              <ul>
                <li>
                  <span>Pickup</span>: Collect your order at your convenience.
                </li>
                <li>
                  <span>Delivery via Partners</span>: Specific timing and costs will be determined
                  based on our partners tariffs. Discuss details after placing your order
                </li>
                <li>
                  <span>Delivery within Canada</span>: Option to discuss individual terms
                </li>
                <li>
                  <span>FedEx</span>: Reliable and fast delivery worldwide. Please note that
                  delivery times and costs are subject to FedEx tariffs
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {isPaymentTab === 'characteristics' && (
        <div className="product-section-main-info__tabs-content">
          <table>
            <tbody>
              {product?.characteristics?.length > 0 ? (
                <Fragment>
                  {product?.characteristics?.map((item, index) => (
                    <tr
                      key={index}
                      className="product-section-main-info__tabs-content__caracteristics"
                    >
                      <td className="product-section-main-info__tabs-content__caracteristics__name">
                        {item.name}
                      </td>
                      <td className="product-section-main-info__tabs-content__caracteristics__doter"></td>
                      <td className="product-section-main-info__tabs-content__caracteristics__value">
                        {item.value}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ) : (
                <div>Empty</div>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isPaymentTab === 'description' && (
        <div className="product-section-main-info__tabs-content">
          {product?.description.split('\n')?.map((string, index) => (
            <Fragment key={index}>
              {string}
              <br />
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default Tabs;

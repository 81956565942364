import './AddAffiliate.scss';

import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '../../../../..';
import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useRequired from '../../../../../general/hooks/useRequired';
import {
  addAffiliate,
  addIdToLastAffiliate,
  updateAffiliate,
} from '../../../../../general/redux/reducers/Affiliate-Reducer';
import { RootState } from '../../../../../general/redux/store';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import createRefferalService from '../../../../../general/services/refferal';
import { Affiliate } from '../../../../../general/types/types';
import { linksReducer } from './linksReducer';
import SocialLink from './SocialLink';

interface AddAffiliateProps {
  isEdit?: boolean;
  item?: any;
  close: () => void;
}

const AddAffiliate: React.FC<AddAffiliateProps> = ({ isEdit = false, item, close }) => {
  const convertSocialLinksToArray = (socialLinks: any[]) => {
    return socialLinks.map((item) => ({
      id: item.id,
      name: item.name,
      url: item.link,
      isActive: true, // TODO add isActive
    }));
  };

  const dispatch = useDispatch();
  const { affiliates } = useSelector((state: RootState) => state.affiliate);

  const { errors, setError } = useRequired();

  const [affiliateName, setAffiliateName] = useReducer(
    (_: string, e: React.ChangeEvent<HTMLInputElement>) => e.target.value,
    isEdit ? item.firstName : '',
  );
  const [affiliateNick, setAffiliateNick] = useReducer(
    (_: string, e: React.ChangeEvent<HTMLInputElement>) => e.target.value,
    isEdit ? item.nickName : '',
  );

  const [links, dispatchLinks] = useReducer(
    linksReducer,
    isEdit ? convertSocialLinksToArray(item.socialNetworks) : [{ id: 0, name: '', url: '' }],
  );

  //#region mutations
  const { mutate: create } = useCustomMutation(
    createRefferalService(adminInstance).createAffiliate,
    undefined,
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(['affiliates']);
        queryClient.invalidateQueries(['affiliatesForRefs']);
        dispatch(addIdToLastAffiliate(data));
      },
    },
  );
  const { mutate: update } = useCustomMutation(
    createRefferalService(adminInstance).updateAffiliate,
  );
  const { mutate: addSocialNetwork } = useCustomMutation(
    createRefferalService(adminInstance).addSocialNetowrkToAffiliate,
  );
  const { mutate: removeSocialNetwork } = useCustomMutation(
    createRefferalService(adminInstance).deleteSocialNetowrkFromAffiliate,
  );
  //#endregion

  const handleSaveAffiliate = () => {
    //#region validates
    if (!affiliateName) {
      setError('name', 'Name is required');
      return;
    }

    if (!affiliateNick) {
      setError('nickname', 'Nickname is required');
      return;
    }

    if (links.length > 0) {
      if (!links[0].name || links[0].name === 'Choose social link name') {
        setError('firstLinkName', 'Social name is required');
        return;
      }

      if (!links[0].url) {
        setError('firstLinkUrl', 'Link is required');
        return;
      }
    }

    if (!isEdit) {
      if (affiliates.find((affiliate) => affiliate.nickName === affiliateNick)) {
        alert('Cannot have affiliates with the same nickname');
        return;
      }

      const urls = links.map((link) => link.url);
      const checkLinksExistence = (urls: string[], objects: any[]) => {
        const existingLinks = objects.flatMap((obj) =>
          obj.socialNetworks.map((socialLink: any) => socialLink.link),
        );
        return urls.some((url) => existingLinks.includes(url));
      };

      if (checkLinksExistence(urls, affiliates)) {
        alert('Cannot have affiliates with the same link');
        return;
      }
    }
    //#endregion

    const uniqueLinks = links.filter(
      (link, index, self) => index === self.findIndex((t) => t.url === link.url),
    );
    const withoutEmpty = uniqueLinks.filter((link) => link.name && link.url);

    const data: Affiliate = {
      firstName: affiliateName,
      lastName: affiliateName,
      nickName: affiliateNick,
      socialNetworks: withoutEmpty.map((link) => {
        return {
          name: link.name,
          link: link.url,
          isActive: true, // TODO add isActive
        };
      }),
    };

    if (!isEdit) {
      create(data);
      dispatch(addAffiliate(data));
    } else {
      data.id = item.id;
      data.socialNetworks = data.socialNetworks.map((link, index) => ({
        id: links[index].id,
        ...link,
      }));
      update(data);

      // if exists new links
      const uniqueObjects = withoutEmpty.filter(
        (firstItem) =>
          !item.socialNetworks.some((secondItem: any) => firstItem.id === secondItem.id),
      );
      uniqueObjects.forEach((element) => {
        const data = {
          affiliateId: item.id,
          name: element.name,
          link: element.url,
          isActive: true, // TODO add isActive
        };

        addSocialNetwork(data);
      });

      // if exists old links
      const oldObjects = item.socialNetworks.filter(
        (firstItem: any) => !withoutEmpty.some((secondItem) => firstItem.id === secondItem.id),
      );
      oldObjects.forEach((element: any) => {
        removeSocialNetwork(element.id);
      });

      dispatch(updateAffiliate(data));
    }

    close();
  };

  return (
    <div className="add-affiliate__content">
      <input
        required
        type="text"
        value={affiliateName}
        className={`input_symbols_panel ${errors.name && 'error_required'}`}
        onChange={setAffiliateName}
        placeholder={errors.name ? errors.name : 'Name'}
      />
      <input
        type="text"
        value={affiliateNick}
        className={`input_symbols_panel ${errors.nickname && 'error_required'}`}
        onChange={setAffiliateNick}
      />
      <h3>Social Links</h3>
      <div className="social-links">
        {links.map((link, index) => (
          <div key={index} className="new-social-link">
            <SocialLink
              index={index}
              link={link}
              linksLength={links.length}
              errors={errors}
              dispatchLinks={dispatchLinks}
            />
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <button onClick={() => dispatchLinks({ type: 'add' })} className="button_with_border">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
            <path
              d="M7.63989 0.859863V16.1399"
              stroke="#FFF9F9"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
          <span>Add social link</span>
        </button>
        <button className="section-btn-gradient" onClick={handleSaveAffiliate}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddAffiliate;

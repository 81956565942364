import React from 'react';

const CopyrightIcon: React.FC = () => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.704 0.471998C13.8587 0.471998 15.7787 0.962665 17.464 1.944C19.1493 2.904 20.4507 4.248 21.368 5.976C22.3067 7.68267 22.776 9.63467 22.776 11.832C22.776 14.0293 22.3067 15.992 21.368 17.72C20.4507 19.4267 19.1493 20.7707 17.464 21.752C15.7787 22.712 13.8587 23.192 11.704 23.192C9.528 23.192 7.59733 22.712 5.912 21.752C4.22667 20.7707 2.91467 19.4267 1.976 17.72C1.03733 15.992 0.568 14.0293 0.568 11.832C0.568 9.63467 1.03733 7.68267 1.976 5.976C2.91467 4.248 4.22667 2.904 5.912 1.944C7.59733 0.962665 9.528 0.471998 11.704 0.471998ZM11.704 1.752C9.72 1.752 7.992 2.17867 6.52 3.032C5.048 3.864 3.91733 5.03733 3.128 6.552C2.33867 8.06667 1.944 9.82667 1.944 11.832C1.944 13.816 2.33867 15.576 3.128 17.112C3.91733 18.6267 5.048 19.8107 6.52 20.664C7.992 21.5173 9.72 21.944 11.704 21.944C13.6667 21.944 15.3733 21.5173 16.824 20.664C18.296 19.8107 19.4267 18.6267 20.216 17.112C21.0053 15.576 21.4 13.816 21.4 11.832C21.4 9.82667 21.0053 8.06667 20.216 6.552C19.4267 5.03733 18.296 3.864 16.824 3.032C15.3733 2.17867 13.6667 1.752 11.704 1.752ZM11.64 4.408C10.36 4.408 9.18667 4.70667 8.12 5.304C7.07467 5.90133 6.24267 6.76533 5.624 7.896C5.00533 9.00533 4.696 10.3067 4.696 11.8C4.696 13.2933 5.00533 14.6053 5.624 15.736C6.24267 16.8453 7.07467 17.7093 8.12 18.328C9.18667 18.9253 10.36 19.224 11.64 19.224C13.24 19.224 14.6267 18.7973 15.8 17.944C16.9733 17.0693 17.7733 15.9067 18.2 14.456H15.512C14.872 16.2267 13.5813 17.112 11.64 17.112C10.36 17.112 9.31467 16.6533 8.504 15.736C7.69333 14.8187 7.288 13.5173 7.288 11.832C7.288 10.1253 7.69333 8.81333 8.504 7.896C9.31467 6.97867 10.36 6.52 11.64 6.52C12.5787 6.52 13.3893 6.75467 14.072 7.224C14.7547 7.672 15.2347 8.32267 15.512 9.176H18.2C17.7947 7.66133 17.0053 6.488 15.832 5.656C14.6587 4.824 13.2613 4.408 11.64 4.408Z"
      fill="white"
    />
  </svg>
);

export default CopyrightIcon;

import React from 'react';

const CloseModalCrossIconWhite: React.FC = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1.21362L11 11.2136M1 11.2136L11 1.21362"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseModalCrossIconWhite;

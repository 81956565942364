import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Confirm from '../../../../admin/components/confirm/Confirm';
import BucketIcon from '../../../../general/assets/svg/BucketIcon';
import {
  changeCartItemCount,
  removeCartItem,
  setCartItemCount,
} from '../../../../general/redux/reducers/ClientCart-Reducer';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';

interface OrderProps {
  order: {
    c: number;
    p: {
      id: string;
      url: string;
      images: { url: string }[];
      condition: string;
      name: string;
      price: number;
      percentageDiscount: number;
    };
  };
}

const Order: React.FC<OrderProps> = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(order.c);
  const userCart = useSelector((state: RootState) => state.pc);

  const [removeLastItemQuestion, setRemoveLastItemQuestion] = useState(false);

  const changeCountInput = (e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);
    if (value > 999) {
      value = 999;
    }

    dispatch(setCartItemCount({ p: order.p.id, c: value >= 1 ? +value : 1 }));
    setCount(value >= 1 ? value : 1);
  };

  const changeCount = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const countElement = (e.target as HTMLElement)
      .closest('div')
      ?.querySelector('.counts-buttons__counts-count') as HTMLInputElement | null;

    if (e.currentTarget.hasAttribute('data-plus') && count < 999) {
      dispatch(changeCartItemCount({ p: order?.p?.id, c: '++' }));
      if (countElement) countElement.value = String(count + 1);
      setCount(count + 1);
    }
    if (e.currentTarget.hasAttribute('data-minus') && count >= 2) {
      dispatch(changeCartItemCount({ p: order?.p?.id, c: '--' }));
      if (countElement) countElement.value = String(count - 1);
      setCount(count - 1);
    }
  };

  const removeProdFromCart = () => {
    if (userCart.pc.length <= 1) {
      setRemoveLastItemQuestion(true);
    } else {
      dispatch(removeCartItem(order.p.id));
    }
  };

  return (
    <>
      <div className="prod" style={{ display: 'flex' }}>
        <Link to={`/product/${order?.p.url}`}>
          <div
            className="prod-img"
            style={{
              backgroundImage: `url(${order.p.images[0].url})`,
            }}
          />
        </Link>
        <div className="prod-info">
          <div className="condition">{order.p.condition}</div>
          <Link to={`/product/${order?.p.url}`}>
            <p>{order.p.name}</p>
          </Link>
          <div className="price-section">
            <div className="product-section__info-characteristics__counts-buttons__counts">
              <button className="counts-buttons__counts-minus" onClick={changeCount} data-minus>
                -
              </button>
              <input
                id={order.p.id}
                type="number"
                className="counts-buttons__counts-count"
                defaultValue={count}
                onChange={changeCountInput}
              />
              <button className="counts-buttons__counts-plus" onClick={changeCount} data-plus>
                +
              </button>
            </div>
            {order?.p?.percentageDiscount === 0 ? (
              <span style={{ fontWeight: 600 }}>${(order?.p?.price * count).toFixed(2)}</span>
            ) : (
              <>
                <span className="price-without-discount">{`$${(order?.p?.price * count).toFixed(
                  2,
                )}`}</span>
                <span className="price-with-discount" style={{ fontWeight: 600 }}>
                  {`$${(
                    order?.p?.price * count -
                    order?.p?.price * count * (order?.p?.percentageDiscount / 100)
                  ).toFixed(2)}`}
                </span>
              </>
            )}
          </div>
        </div>
        <div onClick={removeProdFromCart}>
          <BucketIcon />
        </div>
        {removeLastItemQuestion && (
          <Confirm
            title="Delete confirm"
            text={'Are you sure you want to delete last position and go to cart?'}
            handler={setRemoveLastItemQuestion}
            clickEvent={() => {
              dispatch(removeCartItem(order.p.id));
              navigate(routesClient.cart.path);
            }}
          />
        )}
      </div>
    </>
  );
};

export default Order;

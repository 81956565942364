import React from 'react';

const InfosIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.9998 22.36V13.4534H13.4531"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M13.4531 22.3601H18.5465" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M14.7334 9.63989H17.2667" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default InfosIcon;

import React from 'react';

import AdminModalWindow from '../modal-window/ModalWindow';

interface ConfirmProps {
  title: string;
  text: string;
  handler: (isOpen: boolean) => void;
  clickEvent: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({ title, text, handler, clickEvent }) => {
  return (
    <AdminModalWindow isOpen={handler} title={title} mWidth="500px">
      <div className="confirm-content">
        <p style={{ textAlign: 'start', fontSize: '18px' }}>{text}</p>
        <button
          className="section-btn-gradient"
          onClick={() => {
            clickEvent();
            handler(false);
          }}
        >
          Yes
        </button>
        <button className="section-btn-gradient" onClick={() => handler(false)}>
          No
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default Confirm;

import './GoogleAuth.scss';

import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useDispatch } from 'react-redux';

import useGoogleCredentials from '../../../../general/hooks/useGoogleCredentials';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';

interface GoogleAuthProps {
  isOpenModal: (isOpen: boolean) => void;
}

export const createCredentials = (credentialResponse: CredentialResponse) => {
  let credential: { credential: string; referralCode?: string } = {
    credential: credentialResponse.credential as string,
  };

  const refCode = localStorage.getItem('referralCode');
  if (refCode) {
    credential.referralCode = refCode;
  }

  return credential;
};

const GoogleAuth: React.FC<GoogleAuthProps> = ({ isOpenModal }) => {
  const dispatch = useDispatch();
  const setGoogleCredentials = useGoogleCredentials(() => {
    isOpenModal(false);
  });

  const handleSuccess = async (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Credential is missing' },
        }),
      );
      return;
    }

    setGoogleCredentials(createCredentials(credentialResponse));
  };

  return (
    <div className="google-authorization-section">
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={() => {
          dispatch(setType(types.ERROR));
          dispatch(
            setInfo({
              n: types.ERROR,
              r: { s: 'Ooops... Login Failed' },
            }),
          );
        }}
        type="icon"
        theme="outline"
        shape="circle"
        containerProps={{ className: 'google-authorization-button' }}
      />
    </div>
  );
};

export default GoogleAuth;

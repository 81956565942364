import React from 'react';

const GuaranteedSafeTransactionIcon: React.FC = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.2649 24.9259C46.2649 26.8115 43.9602 28.3619 43.4993 30.08C43.0383 31.798 44.2326 34.375 43.3316 35.9254C42.4307 37.4759 39.6232 37.7063 38.3452 39.0053C37.0671 40.3043 36.8576 43.0699 35.2653 43.9708C33.673 44.8718 31.2007 43.6775 29.4198 44.1594C27.6389 44.6413 26.1304 46.925 24.2657 46.925C22.401 46.925 20.8506 44.6203 19.1116 44.1594C17.3727 43.6985 14.9213 44.8927 13.2662 43.9708C11.611 43.049 11.4853 40.2834 10.1863 39.0053C8.88729 37.7273 6.12169 37.4968 5.19982 35.9254C4.27795 34.3541 5.51409 31.8818 5.03221 30.08C4.55032 28.2781 2.2666 26.8115 2.2666 24.9259C2.2666 23.0402 4.57127 21.4898 5.03221 19.7718C5.49314 18.0538 4.2989 15.5815 5.19982 13.9263C6.10074 12.2711 8.88729 12.1454 10.1863 10.8464C11.4853 9.54745 11.6948 6.78184 13.2662 5.85998C14.8375 4.93811 17.3098 6.17425 19.1116 5.69236C20.9135 5.21048 22.3801 2.92676 24.2657 2.92676C26.1514 2.92676 27.7018 5.23143 29.4198 5.69236C31.1378 6.1533 33.6101 4.95906 35.2653 5.85998C36.9205 6.76089 37.0462 9.54745 38.3452 10.8464C39.6442 12.1454 42.4098 12.355 43.3316 13.9263C44.2535 15.4977 43.0174 17.97 43.4993 19.7718C43.9811 21.5736 46.2649 23.0402 46.2649 24.9259Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M14.2715 34.9197V16.9224" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M14.125 30.918H16.2202L20.2219 33.0131H29.4406C30.2358 33.0132 31.0082 32.7472 31.6347 32.2573C32.2612 31.7675 32.7058 31.0821 32.8976 30.3104L34.1128 25.4287C34.19 25.1491 34.2253 24.8596 34.2175 24.5697C34.2175 23.625 33.8423 22.7191 33.1743 22.0511C32.5064 21.3831 31.6004 21.0079 30.6558 21.0079H26.3607L27.7645 18.2004C28.1621 17.4218 28.3703 16.5604 28.3721 15.6862C28.3748 15.335 28.308 14.9867 28.1756 14.6615C28.0431 14.3362 27.8475 14.0404 27.6002 13.7911C27.3528 13.5418 27.0585 13.344 26.7343 13.2089C26.4101 13.0739 26.0624 13.0044 25.7112 13.0044C25.3037 13.0116 24.9033 13.1116 24.5402 13.2967C24.1772 13.4819 23.8611 13.7473 23.6161 14.0729L18.2734 20.9241H14.2717"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default GuaranteedSafeTransactionIcon;

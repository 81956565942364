import { adminInstance } from '../../general/services/main/axiosInstances';

interface INewsletterCreate {
  title: string;
  content: string;
  timeToPost: string; // Use string for ISO date format
}

interface INewsletterUpdate {
  id: string;
  title: string;
  content: string;
  timeToPost: string; // Use string for ISO date format
}

export const MailingServices = {
  //POST
  async createNewsLetter(body: INewsletterCreate) {
    try {
      const responce = await adminInstance.post('/Newsletter', body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  //PUT
  async updateNewsLetter(body: INewsletterUpdate) {
    try {
      const responce = await adminInstance.put(`/Newsletter`, body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  //GET
  async getNewsLetterById(id: string) {
    try {
      const responce = await adminInstance.get(`/Newsletter/byId?Id=${id}`);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  //GET
  async getNewsLetter(page = 1, pageSize = 10) {
    try {
      const responce = await adminInstance.get('/Newsletter/all?Page=1&PageSize=1');
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  //POST
  async sendNewsLetter(id: string) {
    try {
      const responce = await adminInstance.post('/Newsletter/sendToSubscribers', {
        newsletterId: id,
      });
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  //POST
  async postImage(image: any) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await adminInstance.post('/Newsletter/contentImage', data, {
      headers,
    });

    return response.data;
  },
};

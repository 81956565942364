import React from 'react';

const AdminRemoveIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_653_112)">
      <path
        d="M13.9041 18.3998H6.09606C5.70642 18.4003 5.33145 18.2512 5.04858 17.9832C4.76572 17.7152 4.59657 17.3489 4.57606 16.9598L3.88806 4.65576H16.1121L15.4241 16.9598C15.4036 17.3489 15.2344 17.7152 14.9515 17.9832C14.6687 18.2512 14.2937 18.4003 13.9041 18.3998Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M2.35999 4.65576H17.64" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M8.47197 1.6001H11.528C11.9332 1.6001 12.3219 1.76108 12.6084 2.04764C12.895 2.33419 13.056 2.72285 13.056 3.1281V4.6561H6.94397V3.1281C6.94397 2.72285 7.10495 2.33419 7.39151 2.04764C7.67807 1.76108 8.06672 1.6001 8.47197 1.6001Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M10 6.94385V16.1118" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M13.056 6.94385V16.1118" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M6.94397 6.94385V16.1118" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_653_112">
        <rect width="19.2" height="19.2" fill="white" transform="translate(0.400024 0.399902)" />
      </clipPath>
    </defs>
  </svg>
);

export default AdminRemoveIcon;

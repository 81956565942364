import { adminInstance } from '../../general/services/main/axiosInstances';

export const QuestionAndAnswerServices = {
  // GET
  async getQuestionAnswerGroup() {
    try {
      const response = await adminInstance.get(`/QuestionAnswerGroup/all`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getQuestionAnswerById(id: string) {
    try {
      const response = await adminInstance.get(`/QuestionAnswer/byId?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postQuestionAnswer(data: any) {
    try {
      const response = await adminInstance.post(`/QuestionAnswer`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postQuestionAnswerGroup(data: any) {
    try {
      const response = await adminInstance.post(`/QuestionAnswerGroup`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putQuestionAnswer(data: any) {
    try {
      const response = await adminInstance.put(`/QuestionAnswer`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putQuestionAnswerGroup(data: any) {
    try {
      const response = await adminInstance.put(`/QuestionAnswerGroup`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteQuestionAnswerGroup(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await adminInstance.delete(`/QuestionAnswerGroup`, {
        data,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteQuestionAnswer(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await adminInstance.delete(`/QuestionAnswer`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export const FrequentlyAskedQuestionServices = {
  // GET
  async getFAQGroup() {
    try {
      const response = await adminInstance.get(`/FaqGroup/all`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getFAQById(id: string) {
    try {
      const response = await adminInstance.get(`/Faq/byId?Id=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postFAQ(data: any) {
    try {
      const response = await adminInstance.post(`/Faq`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postFAQGroup(data: any) {
    try {
      const response = await adminInstance.post(`/FaqGroup`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putFAQ(data: any) {
    try {
      const response = await adminInstance.put(`/Faq`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putFAQGroup(data: any) {
    try {
      const response = await adminInstance.put(`/FaqGroup`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteFAQGroup(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await adminInstance.delete(`/FaqGroup`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteFAQ(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await adminInstance.delete(`/Faq`, { data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

import React from 'react';

import GuaranteedSafeTransactionIcon from '../../../general/assets/svg/GuaranteedSafeTransactionIcon';
import IndividualConditionIcon from '../../../general/assets/svg/IndividualConditionIcon';
import PreShipmentTestingIcon from '../../../general/assets/svg/PreShipmentTestingIcon';
import RemoteCustomerSupportIcon from '../../../general/assets/svg/RemoteCustomerSupportIcon';

const Features = () => {
  return (
    <>
      <div className="product-section-guarantee__element">
        <IndividualConditionIcon />
        <h1>Individual conditions</h1>
        <p>Tailored Solutions for Wholesale</p>
      </div>
      <div className="product-section-guarantee__element">
        <GuaranteedSafeTransactionIcon />
        <h1>Guaranteed safe transaction</h1>
        <p>Commitment to Quality</p>
      </div>
      <div className="product-section-guarantee__element">
        <RemoteCustomerSupportIcon />
        <h1>Remote customer support</h1>
        <p>Comprehensive Setup & Guidance</p>
      </div>
      <div className="product-section-guarantee__element">
        <PreShipmentTestingIcon />
        <h1>Pre-shipment testing</h1>
        <p>Meticulous Preparation & Delivery Coordination</p>
      </div>
    </>
  );
};

export default Features;

import './Information.scss';

import React, { ChangeEvent, FocusEvent } from 'react';
import { useDispatch } from 'react-redux';

import { setFieldsErrors } from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';

interface InputBlockProps {
  id: string;
  label: string;
  placeholder: string;
  defValue: string;
  isMore?: boolean;
  openMore?: (isMore: boolean) => void;
  error: string;
}

const InputBlock: React.FC<InputBlockProps> = ({
  id,
  label,
  placeholder,
  defValue,
  isMore,
  openMore = undefined,
  error,
}) => {
  const dispatch = useDispatch();

  const setDefaultBorder = (e: FocusEvent<HTMLInputElement>) => {
    e.target.style.border = '1px solid #25A8CF';
    dispatch(setFieldsErrors([]));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (label.includes('Zip')) {
      value = value.toUpperCase();
    }
    e.target.value = value.trim();
  };

  return (
    <div className="cart-proccesing-section__input-block">
      {openMore !== undefined ? (
        <div>
          <label>{label}</label>
          <div style={{ justifyContent: 'end', gap: '10px' }}>
            <button
              onClick={() => {
                openMore(!isMore);
              }}
            >
              {isMore ? 'Less' : 'More'}
            </button>
          </div>
        </div>
      ) : (
        <label>{label}</label>
      )}
      <input
        style={{
          background: error ? '#512531' : '',
          color: error ? '#DB6484' : '',
        }}
        id={id}
        onFocus={setDefaultBorder}
        onChange={handleInputChange}
        type="text"
        placeholder={placeholder}
        defaultValue={defValue}
        required
      />
      {error && (
        <div>
          <div className="arrow"></div>
          <span className="error-text" style={{ color: '#DB6484' }}>
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

export default InputBlock;

import './EmailAuthorization.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import {
  clientLogin,
  clientWishlist,
} from '../../../../general/redux/reducers/ClientProfile-Reducer';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createAuthorisationService from '../../../../general/services/authorisation';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { HttpResponse } from '../../../../general/types/types';
import LogIn from './LogIn';
import Registration from './Registaration';

const email_regexp = /^[A-Z0-9][A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const code_regexp = /^\d{6,6}$/;

interface EmailAuthorizationProps {
  isOpenModal: (isOpen: boolean) => void;
}

const EmailAuthorization: React.FC<EmailAuthorizationProps> = ({ isOpenModal }) => {
  const dispatch = useDispatch();

  const [isSingIn, setIsSingIn] = useState(true);
  const [isGetCode, setIsGetCode] = useState(false);

  const [emailError, setEmailError] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: '',
  });
  const [codeError, setCodeError] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: '',
  });
  const [passwordError, setPasswordError] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: '',
  });

  const [isPreloader, setIsPreloader] = useState(false);

  const defaultEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError({ status: false, message: '' });
  };

  const defaultCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setCodeError({ status: false, message: '' });
  };

  const defaultPasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordError({ status: false, message: '' });
  };

  const getVerificationCode = async () => {
    setIsPreloader(true);
    const inputsBlock = document.querySelector('.email-authorization-section__sing-up');
    const emailInput = inputsBlock?.querySelector('input');

    if (emailInput && !email_regexp.test(emailInput.value.trim())) {
      setEmailError({ status: true, message: 'Invalid email' });
      setIsPreloader(false);
      return;
    }

    const response: HttpResponse<any> = await createAuthorisationService(
      clientInstance,
    ).postSendConfirmationCodeEmail({
      email: emailInput?.value.trim(),
    });

    if (response.status === 200) {
      setIsGetCode(true);
      setIsRunning(true);
      setTimeLeft(60);
      setIsPreloader(false);
    }

    if (response.response && response.response.status === 409) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'You forgot? You are already part of our family' },
        }),
      );

      isOpenModal(false);
      setIsPreloader(false);
    }
  };

  const getWishList = async (id: string) => {
    const response = await createClientService(clientInstance).getClienWishList(id);
    return response.data;
  };

  const sentAuthData = async (isSingIn: boolean) => {
    const inputsBlock = document.querySelector(
      isGetCode ? '.email-authorization-section__sing-up' : '.email-authorization-section__sing-in',
    );
    const inputs = inputsBlock?.querySelectorAll('input');

    if (!inputs) return;

    const email = inputs[0].value.trim();
    const code = isSingIn ? undefined : inputs[1].value.trim();
    const password = isSingIn ? inputs[1].value.trim() : inputs[2].value.trim();

    //#region Validation
    if (!email_regexp.test(email)) {
      setEmailError({ status: true, message: 'Invalid email' });
      setIsPreloader(false);
      return;
    }

    if (isGetCode && code && !code_regexp.test(code) && isSingIn) {
      setCodeError({ status: true, message: 'Invalid code' });
      setIsPreloader(false);
      return;
    } else {
      let requestData: any = {
        email: email,
        password: password,
        confirmationCode: isGetCode ? code : null,
      };

      const refCode = localStorage.getItem('referralCode');
      if (refCode) {
        requestData = { ...requestData, referralCode: refCode };
      }

      const response =
        await createAuthorisationService(clientInstance).postSignInEmail(requestData);

      if (response.name === 'AxiosError') {
        if (response.response.data.detail.includes('locked')) {
          setEmailError({ status: true, message: 'Number is locked out.' });
          return;
        } else if (response.response.data.detail.includes('Password')) {
          setPasswordError({ status: true, message: 'Invalid password' });
          return;
        } else if (response.response.data.detail.includes('Referral')) {
          alert('Invalid referral code');
          return;
        } else if (response.response.data.detail.includes('User')) {
          setCodeError({ status: true, message: 'You have to get new code' });
          return;
        } else if (response.response.data.detail.includes('Wrong')) {
          setEmailError({
            status: true,
            message: response.response.data.detail,
          });
          setPasswordError({
            status: true,
            message: response.response.data.detail,
          });
          return;
        }

        setCodeError({ status: true, message: response.response.data.Detail });
        return;
      } else {
        const data = response.data;

        isOpenModal(false);
        dispatch(clientLogin(data.userId));
        dispatch(clientWishlist(await getWishList(data.userId)));
      }
    }
    //#endregion
  };

  useEffect(() => {
    setIsGetCode(false); // TODO change to false
  }, [isSingIn]);

  const [isRunning, setIsRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  return (
    <div className="email-authorization-section">
      <div className="email-authorization-section__title">
        <button onClick={() => setIsSingIn(true)} className={isSingIn ? 'button-active' : ''}>
          Sign In
        </button>
        <div className="email-authorization-section__title__line" />
        <button onClick={() => setIsSingIn(false)} className={!isSingIn ? 'button-active' : ''}>
          Sign Up
        </button>
      </div>
      {isSingIn ? (
        <LogIn
          emailError={emailError}
          passwordError={passwordError}
          defaultEmailInput={defaultEmailInput}
          defaultPasswordInput={defaultPasswordInput}
        />
      ) : (
        <Registration
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          isGetCode={isGetCode}
          getVerificationCode={getVerificationCode}
          emailError={emailError}
          codeError={codeError}
          passwordError={passwordError}
          defaultEmailInput={defaultEmailInput}
          defaultCodeInput={defaultCodeInput}
          defaultPasswordInput={defaultPasswordInput}
        />
      )}
      <div className="email-authorization__confirm-button">
        <button
          className="section-btn-gradient"
          onClick={() =>
            isSingIn ? sentAuthData(true) : !isGetCode ? getVerificationCode() : sentAuthData(false)
          }
        >
          {isPreloader ? (
            <Preloader style={preloaderStyles.DARK} />
          ) : (
            <>{isSingIn ? 'Log In' : !isGetCode ? 'Get code' : 'Register Now'}</>
          )}
        </button>
      </div>
    </div>
  );
};

export default EmailAuthorization;

import { AxiosInstance } from 'axios';

interface StaffService {
  getAllStaff: () => Promise<any>;
}

const createStaffService = (instance: AxiosInstance): StaffService => ({
  async getAllStaff() {
    try {
      const response = await instance.get('/Staff/getAll');
      return response;
    } catch (error) {
      throw error;
    }
  },
});

export default createStaffService;

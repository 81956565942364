import './Staff.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import EditIcon from '../../../../general/assets/svg/EditIconAction';
import PeopleIcon from '../../../../general/assets/svg/PeopleIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setStaffToEdit } from '../../../../general/redux/reducers/HomeStaticInfo-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createStaffService from '../../../../general/services/staff';
import { StaffView } from '../../../../general/types/types';
import StaffEditor from './StaffEditor';

const Staff = () => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState<boolean>(false);

  const {
    data: staff,
    isLoading: staffLoading,
    refetch: refresh,
  } = useCustomQuery<StaffView[]>(queryKeys.STAFF, () =>
    createStaffService(adminInstance).getAllStaff(),
  );

  const openStaffEditor = () => {
    setIsModal(true);
  };

  return (
    <>
      {isModal && <StaffEditor isOpen={setIsModal} refresh={refresh} />}
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <PeopleIcon />
          <span>Staff</span>
        </div>
        <button className="home-container__header__button" onClick={openStaffEditor}>
          <AddIcon color="#25A8CF" />
        </button>
      </div>
      <div className="home-container__staff">
        {staffLoading ? (
          <div className="home-container__staff__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {staff?.length === 0 ? (
              <div className="home-container__staff__empty">No staff</div>
            ) : (
              <ul>
                {staff?.map((staff, index) => (
                  <li key={index}>
                    <div className="home-container__staff">
                      <div
                        className="ava"
                        style={{
                          backgroundImage: `url(${staff?.imageURL})`,
                        }}
                      ></div>
                      <div className="home-container__staff__info">
                        <span>{staff?.fullName}</span>
                        <span>Position: {staff?.position}</span>
                        <a
                          href={
                            staff?.linkedIn.startsWith('https')
                              ? staff?.linkedIn
                              : `https://${staff?.linkedIn}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </a>
                        <span>Is fired: {staff?.isFired ? 'Yes' : 'No'}</span>
                      </div>
                    </div>
                    <div className="home-container__staff__actions">
                      <button
                        onClick={() => {
                          dispatch(setStaffToEdit(staff));
                          openStaffEditor();
                        }}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Staff;

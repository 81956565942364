import './AdminProductsCategories.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { images } from '../../../../general/StaticData';
import { ProductCategoriesService } from '../../../service/produt-services/ProductsCategoriesService';

const CategoriesCharacteristics = ({ characteristics, removedCharacteristic }) => {
  const [itemList, setItemList] = useState([...characteristics]);

  const addCharacteristic = () => {
    const id =
      itemList[itemList.length - 1]?.id === undefined ? 0 : itemList[itemList.length - 1]?.id + 1;
    setItemList((prevComponents) => [...prevComponents, { id }]);
  };

  const deleteCharacteristic = (e, id) => {
    removedCharacteristic.push(id);
    setItemList((prevComponents) => prevComponents.filter((component) => component.id !== id));
    e.stopPropagation();
  };

  return (
    <div className="modal-window-action__modal__inputs__text__characteristics">
      <div className="inputs__text__characteristics__top">
        <span>Characteristics</span>
        <button onClick={addCharacteristic}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 10H18.8327" stroke="#25A8CF" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M9.41626 0V20" stroke="#25A8CF" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </button>
      </div>
      <div className="inputs__text__characteristics__section">
        {itemList.map((item) => (
          <div key={item.id} className="characteristics__section__input-block">
            <input
              data-id={item.id}
              type="text"
              placeholder="name"
              defaultValue={item?.name || ''}
              disabled={item?.name === undefined ? false : true}
            />
            <button onClick={(e) => deleteCharacteristic(e, item.id)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_673_3385)">
                  <path
                    d="M13.9039 18.3998H6.09594C5.7063 18.4003 5.33132 18.2512 5.04846 17.9832C4.7656 17.7152 4.59645 17.3489 4.57594 16.9598L3.88794 4.65576H16.1119L15.4239 16.9598C15.4034 17.3489 15.2343 17.7152 14.9514 17.9832C14.6686 18.2512 14.2936 18.4003 13.9039 18.3998Z"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M2.35986 4.65576H17.6399"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M8.47185 1.6001H11.5278C11.9331 1.6001 12.3218 1.76108 12.6083 2.04764C12.8949 2.33419 13.0558 2.72285 13.0558 3.1281V4.6561H6.94385V3.1281C6.94385 2.72285 7.10483 2.33419 7.39139 2.04764C7.67794 1.76108 8.0666 1.6001 8.47185 1.6001Z"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M10 6.94385V16.1118"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M13.0559 6.94385V16.1118"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M6.94385 6.94385V16.1118"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_673_3385">
                    <rect
                      width="19.2"
                      height="19.2"
                      fill="white"
                      transform="translate(0.399902 0.399902)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const CategoryModal = ({ category, isModal, updateData, method }) => {
  const dispatch = useDispatch();
  const [currentIMG, setCurrentIMG] = useState(
    category?.imageUrl === undefined ? '' : category?.imageUrl + `?${Math.random()}`,
  );
  const [isCharacteristics, setIsCharacteristics] = useState(
    category?.areMiningCharacteristics || false,
  );
  const [characteristics, setCharacteristics] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);

  const handleFileChange = (e) => {
    const sizeInMB = e.target.files[0].size / 1024; // размер в мегабайтах
    if (sizeInMB > 120) {
      dispatch(
        setInfo({
          n: 'Uploading images',
          r: {
            d: '',
            s: `Some images weigh more than 120 kB.\n\n${
              e.target.files[0].name
            } - ${sizeInMB.toFixed(2)} kB`,
          },
        }),
      );
    } else {
      setCurrentIMG(URL.createObjectURL(e.target.files[0]));
    }
  };

  const getData = async () => {
    setIsPreloader(true);
    const response = await ProductCategoriesService.getMainGeneralNames(category.id);
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
      setCharacteristics([]);
    } else {
      setCharacteristics(response);
    }
    setIsPreloader(false);
  };

  const removedCharacteristic = [];

  const updateCharacteristics = async () => {
    const section = document.querySelector('.inputs__text__characteristics__section');
    const inputs = section.querySelectorAll('input');

    inputs.forEach((item) => {
      if (!characteristics?.some((characteristic) => characteristic.id === item.dataset.id)) {
        ProductCategoriesService.postMainGeneralToCategory({
          categoryId: category.id,
          name: item.value.trim(),
        });
      }
    });

    // Delete
    if (removedCharacteristic.length > 0) {
      removedCharacteristic.forEach((id) => {
        ProductCategoriesService.deleteMainGeneralCategoryCharacteristic(id);
      });
    }
  };

  const changeCategory = async (e) => {
    const input = document.getElementById('open-modal');
    const image = document.getElementById('upload-photo');
    const lable = document.querySelector('.img-loader');
    const minig = document.getElementById('Category_mining');

    if (image.files.length === 0 && lable.querySelector('img') === undefined) {
      lable.style.border = '1px solid red';
    } else {
      lable.style.border = '1px solid transparent';
    }

    if (input.value.trim() === '') {
      input.style.border = '1px solid red';
    } else {
      input.style.border = '1px solid transparent';
    }

    if (input.value.trim() !== '') {
      const response = await ProductCategoriesService.putProductCategory(category.id, input.value);

      if (response.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: response.message,
            r: Object.prototype.hasOwnProperty.call(response, 'response')
              ? { d: response.response.data, s: response.response.statusText }
              : '',
          }),
        );
        return;
      }

      if (image.files[0] !== undefined) {
        const formData = new FormData();
        formData.append('Image', image.files[0]);
        await ProductCategoriesService.putProductCategoryImage(category.id, formData);
      }

      if (!minig.checked) {
        await updateCharacteristics();
      }
      await updateData();
      isModal();
    }
  };

  useEffect(() => {
    if (category?.id !== undefined) {
      getData();
    }
  }, []);

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={isModal} />
      <div className="modal-window-action__modal">
        {isPreloader ? (
          <div className="modal-window-action__modal__category-preloader">
            <Preloader />
          </div>
        ) : (
          <>
            <div className="modal-window-action__modal__title">
              <span>Categories</span>
              <button onClick={isModal}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M10.5981 10.5977L21.4027 21.4022"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M21.4023 10.5977L10.5978 21.4022"
                    stroke="#25A8CF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-window-action__modal__content">
              <div className="modal-window-action__modal__inputs">
                <div className="modal-window-action__modal__inputs__image">
                  <label htmlFor="upload-photo" className="img-loader">
                    {currentIMG === '' || currentIMG === undefined ? (
                      <>+</>
                    ) : (
                      <img src={currentIMG} alt="" />
                    )}
                  </label>
                  <input
                    id="upload-photo"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="modal-window-action__modal__inputs__text">
                  <div className="modal-window-action__modal__inputs__text__top">
                    <div className="modal-window-action__modal__inputs__name">
                      <input
                        id="open-modal"
                        type="text"
                        defaultValue={category?.name || ''}
                        placeholder="name"
                      />
                    </div>
                    <div className="modal-window-action__modal__inputs__is-mining">
                      <input
                        type="checkbox"
                        id="Category_mining"
                        name="category_type"
                        value="mining"
                        defaultChecked={category?.areMiningCharacteristics || false}
                        disabled={category?.areMiningCharacteristics === undefined ? false : true}
                        onChange={() => setIsCharacteristics(!isCharacteristics)}
                      />
                      <label htmlFor="Category_mining">isMining</label>
                    </div>
                  </div>
                  {isCharacteristics === false && (
                    <CategoriesCharacteristics
                      removedCharacteristic={removedCharacteristic}
                      characteristics={characteristics}
                    />
                  )}
                </div>
              </div>
              <div className="modal-window-action__modal__button">
                <button
                  className="section-btn-gradient"
                  onClick={method || changeCategory}
                  data-id={category?.id}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Category = ({ item, updateData }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openChangeModal = (e) => {
    setIsOpenModal(!isOpenModal);
  };

  const deleteCategory = async (e) => {
    const response = await ProductCategoriesService.deleteProductCategory(e.target.dataset.id);
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    } else {
      updateData();
    }
  };

  return (
    <>
      <div className="page-section__category__info">
        <img
          src={item?.imageUrl !== null ? item?.imageUrl + `?${Math.random()}` : images.defaultImg}
          alt=""
        />
        <div className="page-section__category__name-settings">
          <div className="category__name__settings">
            <span>{item.name}</span>
          </div>
          <span className="page-section__category-id">id: {item.id}</span>
        </div>
      </div>
      <div className="page-section__category__action">
        <button onClick={openChangeModal} data-id={item.id}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_653_106)">
              <path
                d="M6.18386 16.8721L2.35986 17.6721L3.15986 13.8481L14.7119 2.2321C14.9123 2.03121 15.1505 1.87195 15.4127 1.76349C15.675 1.65502 15.9561 1.59949 16.2399 1.6001C16.8127 1.6001 17.3621 1.82767 17.7672 2.23275C18.1723 2.63783 18.3999 3.18723 18.3999 3.7601C18.4005 4.04389 18.3449 4.32499 18.2365 4.58723C18.128 4.84947 17.9688 5.08766 17.7679 5.2881L6.18386 16.8721Z"
                stroke="#25A8CF"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M0.839844 18.3999H19.1598"
                stroke="#25A8CF"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M16.1119 6.9437L13.0559 3.8877"
                stroke="#25A8CF"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </g>
            <defs>
              <clipPath id="clip0_653_106">
                <rect
                  width="19.2"
                  height="19.2"
                  fill="white"
                  transform="translate(0.399902 0.399902)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button onClick={deleteCategory} data-id={item.id}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_653_112)">
              <path
                d="M13.9041 18.3998H6.09606C5.70642 18.4003 5.33145 18.2512 5.04858 17.9832C4.76572 17.7152 4.59657 17.3489 4.57606 16.9598L3.88806 4.65576H16.1121L15.4241 16.9598C15.4036 17.3489 15.2344 17.7152 14.9515 17.9832C14.6687 18.2512 14.2937 18.4003 13.9041 18.3998Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M2.35999 4.65576H17.64"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M8.47197 1.6001H11.528C11.9332 1.6001 12.3219 1.76108 12.6084 2.04764C12.895 2.33419 13.056 2.72285 13.056 3.1281V4.6561H6.94397V3.1281C6.94397 2.72285 7.10495 2.33419 7.39151 2.04764C7.67807 1.76108 8.06672 1.6001 8.47197 1.6001Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10 6.94385V16.1118"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M13.056 6.94385V16.1118"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M6.94397 6.94385V16.1118"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </g>
            <defs>
              <clipPath id="clip0_653_112">
                <rect
                  width="19.2"
                  height="19.2"
                  fill="white"
                  transform="translate(0.400024 0.399902)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      {isOpenModal && (
        <CategoryModal
          category={item}
          isModal={openChangeModal}
          getData={updateData}
          updateData={updateData}
        />
      )}
    </>
  );
};

function AdminProductsCategories() {
  const dispatch = useDispatch();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [categories, setCategories] = useState(null);

  const getData = async () => {
    const response = await ProductCategoriesService.getProductsCategories();
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
      setCategories([]);
    } else {
      setCategories(response !== undefined && response !== null ? response : []);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const openCreateModal = (e) => {
    setIsCreateModal(!isCreateModal);
  };

  const addNewCharacteristics = async (id) => {
    const section = document.querySelector('.inputs__text__characteristics__section');
    const inputs = section.querySelectorAll('input');

    inputs.forEach((item) => {
      ProductCategoriesService.postMainGeneralToCategory({
        categoryId: id,
        name: item.value.trim(),
      });
    });
  };

  const saveCategory = async (e) => {
    const input = document.getElementById('open-modal');
    const image = document.getElementById('upload-photo');
    const lable = document.querySelector('.img-loader');
    const minig = document.getElementById('Category_mining');

    if (image.files.length === 0) {
      lable.style.border = '1px solid red';
    } else {
      lable.style.border = '1px solid transparent';
    }

    if (input.value.trim() === '') {
      input.style.border = '1px solid red';
    } else {
      input.style.border = '1px solid transparent';
    }

    if (input.value.trim() !== '' && image.files.length !== 0) {
      const formData = new FormData();
      formData.append('Image', image.files[0]);

      const response = await ProductCategoriesService.postProductCategory(
        formData,
        input.value,
        minig.checked,
      );
      if (response.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: response.message,
            r: Object.prototype.hasOwnProperty.call(response, 'response')
              ? { d: response.response.data, s: response.response.statusText }
              : '',
          }),
        );
        return;
      }

      if (!minig.checked) {
        await addNewCharacteristics(response);
      }
      getData();
      openCreateModal();
    }
  };

  return (
    <>
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={openCreateModal}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
              <path
                d="M7.63989 0.859863V16.1399"
                stroke="#FFF9F9"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
            <span>Categories</span>
          </button>
        </div>
        <div className="page-section-categories">
          <div className="page-section__categories">
            <div className="page-section__categories__title">All categories</div>
            {categories === null ? (
              <div className="preloader-block">
                <Preloader />
              </div>
            ) : (
              <ul>
                {categories.length === 0 ? (
                  <li>NO DATA</li>
                ) : (
                  categories.map((category) => (
                    <li key={category.id}>
                      <Category item={category} updateData={getData} />
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        </div>
        {isCreateModal && (
          <CategoryModal isModal={openCreateModal} getData={getData} method={saveCategory} />
        )}
      </div>
    </>
  );
}

export default AdminProductsCategories;

import { AxiosInstance } from 'axios';

import { saveDataForage } from '../../localforage';

interface AuthorisationService {
  postGoogleCredentials: (credential: {
    credential: string;
    referralCode?: string;
  }) => Promise<any>;
  postSendConfirmationCode: (phone: any) => Promise<any>;
  postSendConfirmationCodeEmail: (email: any) => Promise<any>;
  postSignInEmail: (data: any) => Promise<any>;
  postSignIn: (data: any) => Promise<any>;
  postAuthAdminPanel: (data: { username: string; password: string }) => Promise<any>;
}

const createAuthorisationService = (instance: AxiosInstance): AuthorisationService => ({
  async postGoogleCredentials(credential: { credential: string; referralCode?: string }) {
    try {
      const response = await instance.post(`/Auth/signInViaGoogle`, credential);
      await saveDataForage(
        'clp',
        JSON.stringify({
          c5d: response.data.userId,
          c5ac: response.data.accessToken,
          c5rt: response.data.refreshToken,
        }),
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async postSendConfirmationCode(phone: any) {
    try {
      const response = await instance.post(`/Phone/sendConfirmationCode`, phone);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postSendConfirmationCodeEmail(email: any) {
    try {
      const response = await instance.post(`/Email/sendConfirmationCode`, email);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postSignInEmail(data: any) {
    try {
      const response = await instance.post(`/Auth/signInViaEmailPassword`, data);
      await saveDataForage(
        'clp',
        JSON.stringify({
          c5d: response.data.userId,
          c5ac: response.data.accessToken,
          c5rt: response.data.refreshToken,
        }),
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async postSignIn(data: any) {
    try {
      const response = await instance.post(`/Auth/signInViaPhone`, data);
      await saveDataForage(
        'clp',
        JSON.stringify({
          c5d: response.data.userId,
          c5ac: response.data.accessToken,
          c5rt: response.data.refreshToken,
        }),
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async postAuthAdminPanel(data: { username: string; password: string }) {
    try {
      const response = await instance.post(`/Auth/signInAdmin`, data);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createAuthorisationService;

import { adminInstance } from '../../general/services/main/axiosInstances';

// TODO rewrite with services
export const ShippoServices = {
  async ValidateAddress(body: any) {
    try {
      const responce = await adminInstance.post('/GoShippo/valideAddress', body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  async GetShippingRates(body: any) {
    try {
      const responce = await adminInstance.post('/GoShippo/getRates', body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  async CreateTransaction(body: any) {
    try {
      const responce = await adminInstance.post('/GoShippo/makeTransaction', body);
      return responce.data;
    } catch (error) {
      return error;
    }
  },

  async RunTracking(rate: string, trackingNumber: string) {
    try {
      const responce = await adminInstance.get(
        `/GoShippo/runTracking?reate=${rate}&trackingNumber=${trackingNumber}`,
      );

      return responce.data;
    } catch (error) {
      return error;
    }
  },
};

import './FAQandQA.scss';

import React, { Fragment, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import QaFaqOpenQuestionIcon from '../../../../general/assets/svg/QaFaqOpenQuestionIcon';
import QaFaqTitleDecorationIcon from '../../../../general/assets/svg/QaFaqTitleDecorationIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHelpCenterServiceService from '../../../../general/services/helpCenter';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { QuestionGroup } from './FAQ';

const QA = () => {
  const navigate = useNavigate();

  const { data: qaes, isLoading: qaesLoading } = useCustomQuery<QuestionGroup[]>(
    queryKeys.QAES,
    () => createHelpCenterServiceService(clientInstance).getAllQA(),
    undefined,
    () => {
      navigate('/');
    },
  );

  const openQuestion = (e: MouseEvent<HTMLButtonElement>) => {
    const titleQuestion = e.currentTarget.closest('.help-center__aq__question__title');
    if (titleQuestion) {
      const blockQuestion = titleQuestion.parentElement;
      if (blockQuestion) {
        blockQuestion.classList.toggle('question-open');
      }
    }
  };

  return (
    <section className="help-center__aq page-container">
      <div className="help-center__aq__title">
        <span>Questions</span> and answers
      </div>
      {qaesLoading ? (
        <div className="help-center__aq__preloader">
          <Preloader />
        </div>
      ) : qaes ? (
        qaes.map((group) => (
          <div key={group.id} className="help-center__aq__question-group">
            <div className="help-center__aq__question-group__title">{group.name}</div>
            {group.questionAnswers.length === 0 ? (
              <div className="help-center__aq__question-group__empty">
                <Preloader />
              </div>
            ) : (
              group.questionAnswers.map((question, qIndex) => (
                <div key={qIndex} className="help-center__aq__question">
                  <div className="help-center__aq__question__title">
                    <article className="help-center__aq__question__title__decoration">
                      <QaFaqTitleDecorationIcon />
                    </article>
                    <h1 className="help-center__aq__question__title__text">
                      Q: {question.question.toUpperCase()}
                    </h1>
                    <button
                      className="help-center__aq__question__title__button"
                      onClick={openQuestion}
                    >
                      <QaFaqOpenQuestionIcon />
                    </button>
                  </div>
                  <div className="help-center__aq__question__content">
                    <div className="help-center__aq__question__content__text">
                      A:{' '}
                      {question.answer.split('\n').map((string, aIndex) => (
                        <Fragment key={aIndex}>
                          {string}
                          <br />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        ))
      ) : (
        <p>No Questions and Answers available</p>
      )}
    </section>
  );
};

export default QA;

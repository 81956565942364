import React from 'react';

const ScrollToTopFooterIcon: React.FC = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1865_404)">
      <path d="M15 3.21489V26.7851" stroke="white" strokeWidth="2.5" strokeMiterlimit="10" />
      <path
        d="M6.89648 11.3174L14.9987 3.21512L23.101 11.3174"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1865_404">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.857422 15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ScrollToTopFooterIcon;

import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { ifServerError } from '../error/CustomErorr';
import { setInfo, setType, types } from '../redux/reducers/Error-Reducer';

type ServiceMethod<T> = () => Promise<{ data: T } | any>;
type PreReturnCallback<T> = (data: T) => void;
type ErrorCallback = (error: any) => void;

const useCustomQuery = <T>(
  key: string,
  serviceMethod: ServiceMethod<T>,
  preReturnCallback?: PreReturnCallback<T>,
  errorCallback?: ErrorCallback,
  options?: UseQueryOptions<T | undefined>,
): UseQueryResult<T | undefined> => {
  const dispatch = useDispatch();

  const queryFn = async (): Promise<T | undefined> => {
    try {
      const response = await serviceMethod();

      if (
        !ifServerError(response, (func: any) => {
          dispatch(setType(types.ERROR));
          dispatch(func);
        })
      ) {
        const data = response.data;
        let modifiedData = data;
        if (preReturnCallback) {
          modifiedData = preReturnCallback(data);
        }
        return modifiedData;
      } else {
        if (errorCallback) {
          errorCallback(response);
        }
        return response;
      }
    } catch (error: any) {
      if (errorCallback) {
        errorCallback(error);
      }
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: error },
        }),
      );
      return error;
    }
  };

  const queryOptions = {
    ...options,
    cacheTime: 300000, // Данные хранятся в кэше в течение 5 минут после того, как они станут "устаревшими"
    onError: (error: any) => {
      if (errorCallback) {
        errorCallback(error);
      }
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: error },
        }),
      );
    },
  };

  return useQuery<T | undefined>(key, queryFn, queryOptions);
};

export default useCustomQuery;

import React from 'react';

const ErrorFieldAttentionIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7209 2.5L18.6592 16.25C18.7323 16.3767 18.7708 16.5204 18.7708 16.6667C18.7708 16.8129 18.7323 16.9566 18.6592 17.0833C18.5861 17.21 18.4809 17.3152 18.3542 17.3884C18.2275 17.4615 18.0838 17.5 17.9375 17.5H2.06085C1.91457 17.5 1.77087 17.4615 1.64419 17.3884C1.51751 17.3152 1.41232 17.21 1.33918 17.0833C1.26604 16.9566 1.22754 16.8129 1.22754 16.6667C1.22754 16.5204 1.26605 16.3767 1.33918 16.25L9.27752 2.5C9.35066 2.37333 9.45586 2.26814 9.58254 2.19501C9.70921 2.12188 9.85291 2.08337 9.99918 2.08337C10.1455 2.08337 10.2892 2.12188 10.4158 2.19501C10.5425 2.26814 10.6477 2.37333 10.7209 2.5ZM3.50418 15.8333H16.4942L10 4.58334L3.50418 15.8333ZM9.16585 13.3333H10.8325V15H9.16585V13.3333ZM9.16585 7.5H10.8325V11.6667H9.16585V7.5Z"
      fill="#DB6484"
    />
  </svg>
);

export default ErrorFieldAttentionIcon;

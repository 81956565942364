import React from 'react';

const CLearFilterIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0666 18.75H5.9333C5.52742 18.7506 5.13683 18.5952 4.84218 18.3161C4.54753 18.037 4.37133 17.6553 4.34997 17.25L3.6333 4.43335H16.3666L15.65 17.25C15.6286 17.6553 15.4524 18.037 15.1578 18.3161C14.8631 18.5952 14.4725 18.7506 14.0666 18.75Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M2.04175 4.43335H17.9584" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M8.40832 1.25H11.5917C12.0138 1.25 12.4186 1.41769 12.7171 1.71619C13.0156 2.01468 13.1833 2.41953 13.1833 2.84167V4.43333H6.81665V2.84167C6.81665 2.41953 6.98434 2.01468 7.28284 1.71619C7.58133 1.41769 7.98618 1.25 8.40832 1.25Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M10 6.81665V16.3666" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M13.1833 6.81665V16.3666" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M6.81665 6.81665V16.3666" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default CLearFilterIcon;

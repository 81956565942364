import React from 'react';

const ArrowLink: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12_845)">
      <path
        d="M18.3334 2.1744L1.66671 18.8411"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.3334 13.6328L18.3334 2.17448L6.87504 2.17448"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_12_845">
        <rect width="20" height="20" fill="white" transform="translate(20 20.5078) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowLink;

import './CustomTable.scss';

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { images } from '../../../../general/StaticData';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import LinksToSocialMedia from '../../../pages/referralProgram/bloggersAndCompanies/links/LinksToSocialMedia';

const getImageUrl = (item: any) => {
  if (item.images?.length > 0) {
    return item.images[item.images.length - 1].url + `?${Math.random()}`;
  }
  return item.imageUrl ? item.imageUrl + `?${Math.random()}` : images.defaultImg;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString().split('/').join('.');
};

interface VariableDataTdProps {
  header: string;
  item: any;
}

const VariableDataTd: React.FC<VariableDataTdProps> = ({ header, item }) => {
  switch (header) {
    default:
      return <>{item[header]}</>;
    case 'Reason for Contact':
      return <>{item.reason ? item.reason : '-'}</>;
    case 'name':
      if (item.firstName || item.lastName || item.nickName) return <>{item.firstName}</>;
      else return <>{item.name}</>;
    case 'referral id':
      return <>{item.referralCode.code}</>;
    case 'registration date':
      return <>{new Date(item.createdAt).toLocaleString()}</>;
    case 'full name':
      return (
        <>
          {item.name} {item.surname}
        </>
      );
    case 'client id':
      return <>{item.id}</>;
    case 'affiliate':
      return (
        <>
          {item.affiliate.firstName} / {item.affiliate.nickName}
        </>
      );
    case 'generation date':
      return <>{new Date(item.createdAt).toLocaleString()}</>;
    case 'referral code':
      return <>{item.code}</>;
    case 'nickname':
      return <>{item.nickName}</>;
    case 'links to social media':
      return <LinksToSocialMedia socialLinks={item.socialNetworks} owner={item.nickname} />;
    case 'image':
      return (
        <div
          style={{
            backgroundImage: `url(${getImageUrl(item)})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100px',
          }}
        />
      );
    case 'date':
      const date = formatDate(item.createdAt);
      return (
        <>
          {date.split(',')[0]}
          <br />
          {date.split(',')[1]}
        </>
      );
    case 'status':
      return <>{item.isActive === true || item.status === true ? 'Active' : 'Inactive'}</>;
    case 'number':
      return <>#{item.idFormatted}</>;
    case 'price':
      return <>{`${item.price} $`}</>;
    case 'count':
      return <>{item.amount}</>;
    case 'order':
      return (
        <div>
          {item.orderedProducts.map(
            (product: { name: string; quantity: string }, index: number) => (
              <Fragment key={index}>
                <span>
                  {product.name} x {product.quantity}
                </span>
                <br />
              </Fragment>
            ),
          )}
        </div>
      );
    case 'order-status':
      return <>{item.orderStatuses[item.orderStatuses.length - 1].name}</>;
    case 'total-price':
      return <>{formatToTwoPlaces(item.totalPrice)} $</>;
    case 'client':
      return (
        <div>
          {item.userId !== null ? (
            <Link className="table-block__tbody__item-link" to={`/admin/clients/${item.userId}`}>
              <span>
                {item.transaction ? (
                  <>
                    {item.transaction.addressTo.name} {item.transaction.addressTo.lastName}
                  </>
                ) : item.productsStoresTransaction ? (
                  <>
                    {item.productsStoresTransaction.billingAddress.name}{' '}
                    {item.productsStoresTransaction.billingAddress.lastName}
                  </>
                ) : (
                  <>{item.firstName}</>
                )}
              </span>
            </Link>
          ) : (
            <div className="table-block__tbody__item-quick-user">
              <span>
                {item.firstName} {item.lastName}
              </span>
            </div>
          )}

          <span>
            <span>
              {item.transaction
                ? item.transaction.addressTo.phone
                  ? item.transaction.addressTo.phone.startsWith('+')
                    ? item.transaction.addressTo.phone
                    : '+' + item.transaction.addressTo.phone
                  : '-'
                : item.productsStoresTransaction
                  ? item.productsStoresTransaction.billingAddress.phone
                    ? item.productsStoresTransaction.billingAddress.phone.startsWith('+')
                      ? item.productsStoresTransaction.billingAddress.phone
                      : '+' + item.productsStoresTransaction.billingAddress.phone
                    : '-'
                  : item.phoneNumber.startsWith('+')
                    ? item.phoneNumber
                    : '+' + item.phoneNumber}
            </span>
          </span>
          <br />
          <span>
            {item.transaction
              ? item.transaction.addressTo.email
                ? item.transaction.addressTo.email
                : '-'
              : item.productsStoresTransaction
                ? item.productsStoresTransaction.billingAddress.email
                  ? item.productsStoresTransaction.billingAddress.email
                  : '-'
                : item.email}
          </span>
        </div>
      );
    case 'user-name':
      return (
        <>{`${item.name === null ? '-' : item.name} ${
          item.surname === null || item.surname === undefined ? '' : item.surname
        }`}</>
      );
    case 'contact-name':
      return <>{`${item.name === null ? '-' : item.name}`}</>;
    case 'message-status':
      return <>{`${item.isProcessed === true ? 'Processed' : 'New'}`}</>;
    case 'phone':
      return <>{`${item.phoneNumber === null ? '-' : item.phoneNumber} `}</>;
    case 'email':
      return <>{`${item.email === null || item.email === '' ? '-' : item.email} `}</>;
    case 'admin-name':
      return <>{`${item.adminUserName}`}</>;
    case 'company-name':
      return <>{`${item.companyName} `}</>;
    case 'log-message':
      return <>{`${item.message} `}</>;
    case 'title':
      return <>{`${item.title} `}</>;
    case 'order-notification':
      return <>{`${item.orderNotification ? 'Yes' : 'No'} `}</>;
    case 'contact-message-notification':
      return <>{`${item.contactMessageNotification ? 'Yes' : 'No'} `}</>;
    case 'role':
      return <>{`${item.role} `}</>;
  }
};

export default VariableDataTd;

import { adminInstance } from '../../general/services/main/axiosInstances';

export const ShowcaseService = {
  // GET
  async getMainPageProducts() {
    try {
      const response = await adminInstance.get(`/Product/mainPageAdmin`);
      return response;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putMainPageProductsStatus(data: any) {
    try {
      const response = await adminInstance.put(`/Product/mainPageStatus`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

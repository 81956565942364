import './RecipientList.scss';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import React, { useEffect, useRef, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../../general/queryKeys';
import createClientService from '../../../../../general/services/client';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import CustomPagination from '../../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../../components/tables/custom-table/CustomTable';
import { AdminClientServices } from '../../../../service/ClientServices';
import { MailingServices } from '../../../../service/MailingServices';
import ActionButton from './ActionButton/ActionButton';

const getClearIcon = () => {
  return (
    <div style={{ color: 'white' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  );
};

const getCalendarIcon = () => {
  return (
    <div style={{ color: 'white' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-calendar"
        viewBox="0 0 16 16"
      >
        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
      </svg>
    </div>
  );
};

const RecipientList = () => {
  const userLanguage = navigator.language || navigator.userLanguage;

  const contentContainer = useRef(null);
  const [isPagePreloader] = useState(false);
  const [isAllActivated, setIsAllActivated] = useState(undefined);
  const [isPreview, setIsPreview] = useState(false);
  const [title, setTitle] = useState('');

  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: subscribers,
    isLoading: subscribersLoading,
    refetch: getData,
  } = useCustomQuery(queryKeys.ADMIN_SUBSCRIBERS, () =>
    createClientService(adminInstance).getSubscribers(pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const [date, onChange] = useState(new Date());

  const handleKeyDown = (event) => {
    // Проверяем, что нажата клавиша ESC
    if (event.keyCode === 27) {
      setIsPreview(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const refreshSubscriber = (subscriber) => {
    AdminClientServices.changeStatusSubscriberByID(subscriber.id, subscriber.isActive);
  };

  const handleChangeStatusAll = (isActivate) => {
    AdminClientServices.changeStatusSubscribers(isActivate);
    setIsAllActivated(isActivate);
    isActivate
      ? alert('All subscribers succesfully activated')
      : alert('All subscribers succesfully diactivated');
  };

  const handleLoadLastDraft = () => {
    MailingServices.getNewsLetter().then((newsletter) => {
      if (newsletter === undefined || newsletter.items.length <= 0) {
        alert('No drafts');
      } else {
        const currentNewsletter = newsletter.items[0];
        setTitle(currentNewsletter.title);

        const doc = document.implementation.createHTMLDocument();
        doc.body.innerHTML = currentNewsletter.content;
        const myContent = contentContainer.current;
        const container = document.createElement('div');
        container.innerHTML = doc.body.innerHTML;
        container.innerHTML = container.innerHTML.replace(/percent/gi, '%');

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = container;

        const content = container.querySelector('#with_unsubscribe').querySelector('div');
        myContent.insertAdjacentElement('afterbegin', content);
      }
    });
  };

  const handleSendMailing = () => {
    if (subscribers.items.length > 0) {
      const hasActiveUser = subscribers.items.some((user) => user.isActive);
      if (hasActiveUser) {
        MailingServices.getNewsLetter().then((newsletter) => {
          const currentNewsletter = newsletter.items[0];

          if (currentNewsletter !== undefined) {
            subscribers.items.forEach((subscriber) => {
              subscriber.isActive && MailingServices.sendNewsLetter(currentNewsletter.id);
            });

            alert('Mailing successfully done');

            MailingServices.createNewsLetter({
              title: currentNewsletter.title,
              content: currentNewsletter.content,
            });
          } else alert('No drafts');
        });
      } else alert('Any one subscribers');
    } else alert('Any one subscribers');
  };

  const handleSchedule = (date) => {
    onChange(date);

    MailingServices.getNewsLetter().then((newsletter) => {
      if (date) {
        const currentNewsletter = newsletter.items[0];

        const eastTimeLocale = 'en-US';
        const estTime = date.toLocaleString(eastTimeLocale, {
          timeZone: 'America/New_York',
        });
        const estTimeISO = new Date(estTime).toISOString();
        const ourDate = new Date(date);

        if (!currentNewsletter.wasPosted) {
          const ourDateInLocale = ourDate.toLocaleDateString(userLanguage);
          const ourTimeInLocale = ourDate.toLocaleTimeString(userLanguage);

          const estDateInLocale = new Date(estTimeISO).toLocaleDateString(eastTimeLocale);
          const estTimeInLocale = new Date(estTimeISO).toLocaleTimeString(eastTimeLocale);

          // for dev use ourDate.toISOString() for prod estTimeISO
          MailingServices.updateNewsLetter({
            id: currentNewsletter.id,
            title: currentNewsletter.title,
            content: currentNewsletter.content,
            timeToPost: estTimeISO,
          }).then(() =>
            alert(
              `Schedule time successfully changed to: ${ourDateInLocale} - ${ourTimeInLocale}\nEastern Standard Time (EST): ${estDateInLocale} - ${estTimeInLocale}`,
            ),
          );
        } else {
          onChange(null);
          alert('You need to select other chedule time');

          MailingServices.createNewsLetter({
            title: currentNewsletter.title,
            content: currentNewsletter.content,
          }).then(() => alert('Draft succesfully added'));
        }
      }
    });
  };

  return (
    <div className="recipient-list__container">
      <div className="page-section__content-container">
        {!isPreview ? (
          <>
            <div className="recipient-list_grid-item">
              <div className="date-time-picer__container">
                <DateTimePicker
                  className={'calendar react-datetime-picker__wrapper'}
                  clearIcon={getClearIcon}
                  calendarIcon={getCalendarIcon}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  autoFocus={true}
                  locale={userLanguage}
                  minDate={new Date()}
                  onChange={onChange}
                  value={date}
                />
                <button className="button" onClick={() => handleSchedule(date)}>
                  📌
                </button>
              </div>
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" style={{ margin: '10px' }} onClick={handleSendMailing}>
                Send now
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <button
                className="button"
                style={{ margin: '10px' }}
                onClick={() => {
                  handleLoadLastDraft();
                  setIsPreview(!isPreview);
                }}
              >
                Preview
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <div className="page-section__content__list-section">
                <div className="list-section__content">
                  {(isPagePreloader || subscribersLoading) && (
                    <div className="page-update-overlay">
                      <Preloader />
                    </div>
                  )}
                  <CustomTable
                    data={subscribers !== null ? subscribers?.items : null}
                    headers={['email']}
                    isAction={true}
                    nameOfAction="Is Subscribed"
                    actionComponent={(item, refreshSubscriber) => (
                      <ActionButton
                        updateState={setIsAllActivated}
                        trigger={isAllActivated}
                        item={item}
                        refreshMethod={refreshSubscriber}
                      />
                    )}
                    refreshMethod={refreshSubscriber}
                  />
                </div>
              </div>
              {subscribers?.pageSize < subscribers?.totalCount && (
                <CustomPagination
                  currentPage={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  isNextPage={subscribers.hasNextPage}
                  isPrevPage={subscribers.hasPreviousPage}
                  total={subscribers.totalCount}
                />
              )}
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" onClick={() => handleChangeStatusAll(false)}>
                Diactivate all
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" onClick={() => handleChangeStatusAll(true)}>
                Activate all
              </button>
            </div>
          </>
        ) : (
          <div className="recipient-list_grid-item preview">
            <div id="myModal" className="preview">
              <div className="preview-content">
                <span className="close" onClick={() => setIsPreview(!isPreview)}>
                  &times;
                </span>
                <h1 id="title" className="title">
                  {title}
                </h1>
                <div ref={contentContainer} className="content"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipientList;

import './ProductTable.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductMin } from '../../../general/types/types';

interface TBodyProps {
  data: ProductMin[] | undefined;
}

const TBody: React.FC<TBodyProps> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <tbody className="producst__table__tbody">
      {data?.map((row) => (
        <tr
          key={row.id}
          onClick={() => {
            navigate(`/${row.url}`);
          }}
        >
          <td>{row.manufacturer}</td>
          <td>{row.name}</td>
          <td>{row.price} $</td>
          <td>{row.hashrate}</td>
          <td>{row.amount}</td>
          <td>{row.energyConsumption}</td>
          <td>{row.energyEfficiency}</td>
          <td>{row.condition}</td>
          <td>{row.algorithm}</td>
          <td>{row.countryLocation}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;

import React from 'react';

const PasswordVisibilityIcon: React.FC = () => (
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4585 7.70852C21.3447 9.48213 19.7945 10.9403 17.9561 11.9435C16.1177 12.9468 14.0527 13.4615 11.9585 13.4385C9.86431 13.4615 7.79925 12.9468 5.96086 11.9435C4.12248 10.9403 2.57226 9.48213 1.4585 7.70852C2.57226 5.93491 4.12248 4.47675 5.96086 3.47352C7.79925 2.47028 9.86431 1.95554 11.9585 1.97852C14.0527 1.95554 16.1177 2.47028 17.9561 3.47352C19.7945 4.47675 21.3447 5.93491 22.4585 7.70852Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.9585 13.4385C15.1231 13.4385 17.6885 10.8731 17.6885 7.70852C17.6885 4.54392 15.1231 1.97852 11.9585 1.97852C8.79392 1.97852 6.22852 4.54392 6.22852 7.70852C6.22852 10.8731 8.79392 13.4385 11.9585 13.4385Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.9583 9.61858C13.0132 9.61858 13.8683 8.76345 13.8683 7.70858C13.8683 6.65372 13.0132 5.79858 11.9583 5.79858C10.9035 5.79858 10.0483 6.65372 10.0483 7.70858C10.0483 8.76345 10.9035 9.61858 11.9583 9.61858Z"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <line x1="1.46999" y1="1.32205" x2="22.8225" y2="13.1761" stroke="#746A81" strokeWidth="1.5" />
  </svg>
);

export default PasswordVisibilityIcon;

import './Categories.scss';

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { images } from '../../../general/StaticData';
import { Category } from '../../../general/types/types';

interface CategoriesProps {
  isRetail: boolean;
}

const Categories: React.FC<CategoriesProps> = ({ isRetail }) => {
  const { data: categories, isLoading: categoriesLoading } = useCustomQuery<Category[]>(
    queryKeys.PRODUCT_CATEGORIES,
    () => createProductsService(clientInstance).getProductsCategories(),
  );

  return (
    <div className="categories-section page-container">
      <div className="categories-section-top">
        <div className="categories-section-top__title">
          {isRetail ? 'RETAIL' : 'WHOLESALE'}
          <span className="categories-section-top__title__sub-text">Select product category</span>
        </div>
      </div>
      <div className="categories-section-content">
        {categoriesLoading ? (
          <div className="categories-section-content__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {categories && categories?.length > 0 ? (
              <div className="categories-section-content__cards-list">
                {categories.map((category, index) => (
                  <Fragment key={category.id}>
                    {(isRetail
                      ? category.retailProductsCount > 0
                      : category.wholesaleProductsCount > 0) && (
                      <div className="categories-section-content__card">
                        <Link to={`/products/${isRetail ? 'retail' : 'wholesale'}/${category.url}`}>
                          <div className="categories-section-content__card__name">
                            {category.name}
                          </div>
                          <div className="categories-section-content__card__img">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${category.imageUrl || images.defaultImg})`,
                              }}
                            />
                          </div>
                        </Link>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            ) : (
              <div className="categories-section-content__empty-data">Data empty</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Categories;

import './AdminInfoBlock.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { InfoBlcok } from '../../../../general/types/types';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface InfoBlockModalProps {
  isOpen: (state: boolean) => void;
  id: string | null;
  refetch: () => void;
}

const InfoBlockModal: React.FC<InfoBlockModalProps> = ({ isOpen, id, refetch }) => {
  const closeModal = () => {
    isOpen(false);
  };

  const [currentIMG, setCurrentIMG] = useState<string>('');
  const [currentICON, setCurrentICON] = useState<string>('');
  const [infoBlock, setInfoBlock] = useState<InfoBlcok | null>(null);

  const handleImgChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.[0]) {
      setCurrentIMG(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.[0]) {
      setCurrentICON(URL.createObjectURL(e.target.files[0]));
    }
  };

  const { isLoading: gettingInfoBlcok, refetch: getInfoBlock } = useCustomQuery<InfoBlcok | null>(
    queryKeys.INFO_BLOCK_TO_EDIT,
    () => createHomePageService(adminInstance).getInfoBlockByid(id as string),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess: (data: any) => {
        setCurrentIMG(data?.imageUrl);
        setCurrentICON(data?.buttonIconUrl);
        setInfoBlock(data);
      },
    },
  );

  const { mutate: createNewInfoBlock } = useCustomMutation(
    createHomePageService(adminInstance).postInfoBlock,
  );

  const { mutate: putInfoBlock } = useCustomMutation(
    createHomePageService(adminInstance).putInfoBlock,
  );

  const { mutate: putInfoBlockImage } = useCustomMutation(
    createHomePageService(adminInstance).putInfoBlockImage,
  );

  const { mutate: putInfoBlockIcon } = useCustomMutation(
    createHomePageService(adminInstance).putInfoBlockIcon,
  );

  useEffect(() => {
    if (id) {
      getInfoBlock();
    }
  }, [id, getInfoBlock]);

  const addInfoBlock = async () => {
    const inputBlock = document.querySelector('.modal-window-action__modal__info__text');
    if (!inputBlock) return;

    const inputs = inputBlock.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
    const textarea = inputBlock.querySelector('textarea') as HTMLTextAreaElement;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;
    const input_icon = document.getElementById('upload-icon') as HTMLInputElement;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }
    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }
    if (!input_img?.files?.[0]) {
      const imgLoader = document.querySelector('.img-loader') as HTMLElement;
      if (imgLoader) {
        imgLoader.style.border = '1px solid red';
      }
      required.push(false);
    }
    if (!input_icon?.files?.[0]) {
      const iconLoader = document.querySelector('.icon-loader') as HTMLElement;
      if (iconLoader) {
        iconLoader.style.border = '1px solid red';
      }
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else {
      createNewInfoBlock({
        title: inputs[0].value,
        btn_text: inputs[1].value,
        content: textarea.value,
        image: input_img.files?.[0],
        icon: input_icon.files?.[0],
      });
      refetch();
      closeModal();
    }
  };

  const updateInfoBlock = async () => {
    const inputBlock = document.querySelector('.modal-window-action__modal__info__text');
    const inputs = inputBlock?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
    const textarea = inputBlock?.querySelector('textarea') as HTMLTextAreaElement;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;
    const input_icon = document.getElementById('upload-icon') as HTMLInputElement;

    const required = [];
    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }
    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else {
      putInfoBlock({
        id: id,
        title: inputs[0].value,
        content: textarea.value,
        buttonText: inputs[1].value,
      });

      if (input_img.files?.[0] !== undefined) {
        putInfoBlockImage({ id: id as string, image: input_img.files[0] });
      }
      if (input_icon.files?.[0] !== undefined) {
        putInfoBlockIcon({ id: id as string, image: input_icon.files[0] });
      }

      refetch();
      closeModal();
    }
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Info block'}>
      {gettingInfoBlcok && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__info-block">
        <div>
          <div className="modal-window-action__modal__info__img">
            <label htmlFor="upload-photo" className="img-loader">
              {currentIMG === '' ? (
                <>+</>
              ) : (
                <div
                  className="selected-img"
                  style={{
                    backgroundImage: `url(${currentIMG})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </label>
            <input id="upload-photo" type="file" accept="image/*" onChange={handleImgChange} />
          </div>
          <div className="modal-window-action__modal__info__btn-icon">
            <div className="modal-window-action__modal__info__icon">
              <label htmlFor="upload-icon" className="icon-loader">
                {currentICON === '' ? (
                  <>+</>
                ) : (
                  <div
                    className="selected-img"
                    style={{
                      backgroundImage: `url(${currentICON})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </label>
              <input id="upload-icon" type="file" accept="image/*" onChange={handleIconChange} />
            </div>
            <span> Button icon</span>
          </div>
        </div>
        <div className="modal-window-action__modal__info__text inputs">
          <input type="text" placeholder="title" defaultValue={infoBlock?.title || ''} />
          <input type="text" placeholder="button name" defaultValue={infoBlock?.buttonText || ''} />
          <textarea placeholder="decription" defaultValue={infoBlock?.content || ''} />
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        <button
          className="section-btn-gradient"
          onClick={infoBlock === null ? addInfoBlock : updateInfoBlock}
        >
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default InfoBlockModal;

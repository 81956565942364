import { AxiosInstance } from 'axios';

interface ClientService {
  getClientInfo: (id: string) => Promise<any>;
  getClientOrders: (id: string, pageSize: number, page: number) => Promise<any>;
  getClienWishList: (id: string) => Promise<any>;
  postClienWishList: (data: any) => Promise<any>;
  postClientOrder: (data: any) => Promise<any>;
  uploadReciept: (data: { image: File; isImg: boolean }) => Promise<any>;
  postClientQuickOrder: (data: any) => Promise<any>;
  postNewSubscriber: (data: any) => Promise<any>;
  putClientInfo: (data: any) => Promise<any>;
  unsubscribeClient: (email: { email: string }) => Promise<any>;
  removeFromClientWishlist: (data: any) => Promise<any>;
  getAllCLients: (query: string, pageSize: number, page: number) => Promise<any>;
  getSubscribers: (pageSize: number, page: number) => Promise<any>;
  getCLient: (id: string) => Promise<any>;
  getCLientOrders: (id: string) => Promise<any>;
  updateOrederBankTransactionReciept: (data: {
    orderId: string;
    recieptId: string;
  }) => Promise<any>;
}

const createClientService = (instance: AxiosInstance): ClientService => ({
  async updateOrederBankTransactionReciept(data: { orderId: string; recieptId: string }) {
    try {
      const response = await instance.put(`/Order/updateOrederBankTransactionReciept`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCLientOrders(id: string) {
    try {
      const response = await instance.get(`/Order/adminUser?UserId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCLient(id: string) {
    try {
      const response = await instance.get(`/User/adminUser?UserId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getSubscribers(pageSize: number, page: number) {
    try {
      const response = await instance.get(`/Subscriptions?PageSize=${pageSize}&Page=${page}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllCLients(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/User/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getClientInfo(id: string) {
    try {
      const response = await instance.get(`/User?UserId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getClientOrders(id: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `Order/user?UserId=${id}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getClienWishList(id: string) {
    try {
      const response = await instance.get(`/WishList?UserId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postClienWishList(data: any) {
    try {
      const response = await instance.post(`/WishList`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postClientOrder(data: any) {
    try {
      const response = await instance.post(`/Order`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async uploadReciept(data: { image: File; isImg: boolean }) {
    const formData = new FormData();
    formData.append('image', data.image);
    formData.append('isImg', data.isImg.toString());

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(`/Order/uploadReciept`, formData, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  // not use
  async postClientQuickOrder(data: any) {
    try {
      const response = await instance.post(`/Order/quick`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postNewSubscriber(data: any) {
    try {
      const response = await instance.post(`/Subscriptions/subscribe`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putClientInfo(data: any) {
    try {
      const response = await instance.put(`/User/updateInfo`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async unsubscribeClient(email: { email: string }) {
    try {
      const response = await instance.post(`/Subscriptions/unsubscribe`, email);
      return response;
    } catch (error) {
      return error;
    }
  },

  async removeFromClientWishlist(data: any) {
    try {
      const response = await instance.delete(`/WishList`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createClientService;

import './Payment.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LocationProdStoresIcon from '../../../../../general/assets/svg/LocationProdStoresIcon';
import {
  billingState,
  deliveryMethodsEnum,
  paymentServicesEnum,
  setIsBillingTheSame,
  setPaymntService,
} from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../../general/redux/store';
import Services from './Services';

interface Address {
  value: string;
}

const paymentServices = [
  {
    name: paymentServicesEnum.BANK_TRANSACTION,
  },
  {
    name: paymentServicesEnum.STABLE_COIN,
  },
  // {
  //   name: paymentServicesEnum.COIN_PAL,
  // },
];

const Payment: React.FC = () => {
  const valuesShipping = JSON.parse(sessionStorage.getItem('shipping-fields') || '{}');
  const valuesBilling = JSON.parse(sessionStorage.getItem('billing-fields') || 'null');
  const savedContact = JSON.parse(sessionStorage.getItem('selectedContactMethod') || '{}');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storesLocationRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.shippingStoresLocationAddress,
  );
  const deliveryMethodRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.deliveryMethod,
  );

  const paymentServiceRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.paymentService,
  );
  const [paymentService, setPaymentService] = useState<string>(paymentServiceRedux);

  const contactMethod = useSelector((state: RootState) => state.clientCartProcessing.contactMethod);
  const [currentFrame, setCurrentFrame] = useState<string>('');

  const [isBankTransferInfoVisible, setIsBankTransferInfoVisible] = useState(false);
  const [isTableCoinVisible, setIsTableCoinVisible] = useState(false);

  const addresses = useMemo(() => {
    if (deliveryMethodRedux === deliveryMethodsEnum.SHIPPING) {
      const defaultAddress = valuesShipping;
      const billingAddress = valuesBilling === null ? valuesShipping : valuesBilling;

      return [defaultAddress, billingAddress];
    } else {
      return [valuesBilling];
    }
  }, [valuesShipping, valuesBilling, deliveryMethodRedux]);

  const handleChangePaymentService = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => {
      const newService = e.target.value;
      setPaymentService(newService);
      setIsBankTransferInfoVisible(newService === paymentServicesEnum.BANK_TRANSACTION);
      setIsTableCoinVisible(newService === paymentServicesEnum.STABLE_COIN);
      dispatch(setPaymntService(newService));
    },
    [dispatch],
  );

  useEffect(() => {
    //dispatch(setPaymntCurrency(paymentCurrenciesEnum.USD));
    dispatch(setPaymntService(paymentServicesEnum.DEFAULT)); // paymentServicesEnum.COIN_PAL
  }, [dispatch]);

  useEffect(() => {
    if (paymentServiceRedux !== paymentService) {
      setPaymentService(paymentServiceRedux);
    }
  }, [paymentServiceRedux, paymentService]);

  return (
    <div className="payment">
      <h2>Payment</h2>
      {deliveryMethodRedux === deliveryMethodsEnum.SELF_PICKUP && (
        <div className="shipping-stores_locations">
          <div className="available_stores">
            {storesLocationRedux.map((store, storeIndex) => (
              <div
                key={storeIndex}
                className="store-item"
                style={{ position: 'relative' }}
                onClick={() => {
                  setCurrentFrame(store.mapFrame);
                }}
              >
                <LocationProdStoresIcon />
                <div className="info-block">
                  <span className="country">{store.country}</span>
                  <span className="address">{store.address}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="map" dangerouslySetInnerHTML={{ __html: currentFrame }}></div>
        </div>
      )}
      <div className="prev-info">
        {addresses &&
          addresses.length > 0 &&
          addresses.some((address) => address.length > 0) &&
          addresses?.map((address, index) => (
            <div className="addresses" key={index}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span className="address-label">
                  {index === 0 ? 'Shipping address' : 'Billing address'}
                </span>
                <span
                  onClick={() => {
                    const anchor = index === 0 ? 'shipping-fields' : 'billing-fields';

                    if (index === 1) {
                      dispatch(setIsBillingTheSame(billingState.NOT_SAME));
                    }

                    sessionStorage.setItem('scrollToAnchor', anchor);
                    navigate(-2);
                  }}
                  className="link"
                >
                  Change
                </span>
              </div>

              <div className="address-value">
                {address?.map(
                  ({ value }: Address, innerIndex: number) =>
                    value &&
                    value !== '' && (
                      <span key={innerIndex}>
                        {value}
                        {innerIndex !== address.length - 1 && ', '}
                      </span>
                    ),
                )}
              </div>
            </div>
          ))}
        <div className="contact-method">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span className="contact-label">Contact method</span>
            <span
              onClick={() => {
                const anchor = 'contacts';
                sessionStorage.setItem('scrollToAnchor', anchor);
                navigate(-1);
              }}
              className="link"
            >
              Change
            </span>
          </div>
          <div className="contact-value">
            <span>{contactMethod?.value || savedContact?.value}</span>
          </div>
        </div>
      </div>
      <div className="payment-options" id="payment-options">
        <div className="payment-services_section">
          <div>
            <h3>Payment method</h3>
          </div>
          <div className={`payment-services`}>
            <Services
              services={paymentServices}
              service={paymentService}
              handleChange={handleChangePaymentService}
              groupName={'paymentServices'}
              isBankTransferInfoVisible={isBankTransferInfoVisible}
              isTableCoinVisible={isTableCoinVisible}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

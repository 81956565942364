import './QuestionAndAnswer.scss';

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { QuestionAndAnswerServices } from '../../../service/HelpCenterServices';

const ModalQuestionAndAnswer = ({ isOpen, qa }) => {
  const [isPreloader, setIsPreloader] = useState(false);

  const closeModal = () => {
    isOpen(false);
  };

  const setDefaultBorder = (e) => {
    e.target.style.outline = 'none';
  };

  const addQA = async () => {
    setIsPreloader(true);
    const inputsBlock = document.querySelector('.modal-window-action__modal__qa');
    const input = inputsBlock.querySelector('input');
    const textarea = inputsBlock.querySelector('textarea');

    const required = [];

    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.outline = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      await QuestionAndAnswerServices.postQuestionAnswer({
        groupId: qa.gropId,
        question: input.value,
        answer: textarea.value,
      });
    }
    setIsPreloader(false);
    closeModal();
  };

  const updateQA = async () => {
    setIsPreloader(true);
    const inputsBlock = document.querySelector('.modal-window-action__modal__qa');
    const input = inputsBlock.querySelector('input');
    const textarea = inputsBlock.querySelector('textarea');

    const required = [];

    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.outline = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      await QuestionAndAnswerServices.putQuestionAnswer({
        id: qa.id,
        question: input.value,
        answer: textarea.value,
      });
    }
    setIsPreloader(false);
    closeModal();
  };

  return (
    <div
      className="modal-window-action"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__home" style={{ width: '700px' }}>
        {isPreloader && (
          <div className="modal-window-action__modal__home__loading">
            <Preloader />
          </div>
        )}
        <div className="modal-window-action__modal__title">
          <span>Q&A</span>
          <button onClick={closeModal}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10.5981 10.5977L21.4027 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M21.4023 10.5977L10.5978 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
          </button>
        </div>
        <div className="modal-window-action__modal__qa">
          <input
            onFocus={setDefaultBorder}
            type="text"
            placeholder="question"
            defaultValue={qa?.question || ''}
          />
          <textarea
            onFocus={setDefaultBorder}
            type="text"
            placeholder="answer"
            defaultValue={qa?.answer || ''}
          />
        </div>
        <div className="modal-window-action__modal__button">
          <button
            className="section-btn-gradient"
            onClick={qa?.answer === undefined ? addQA : updateQA}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const ModalQuestionAndAnswerGroup = ({ isOpen, group }) => {
  const [isPreloader, setIsPreloader] = useState(false);

  const closeModal = () => {
    isOpen(false);
  };

  const setDefaultBorder = (e) => {
    e.target.style.outline = 'none';
  };

  const addGroup = async () => {
    setIsPreloader(true);
    const input = document.querySelector('.modal-window-action__modal__qa-group__input');
    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      setIsPreloader(false);
      return;
    } else {
      await QuestionAndAnswerServices.postQuestionAnswerGroup({
        name: input.value,
      });
    }
    setIsPreloader(false);
    closeModal();
  };

  const updateGroup = async () => {
    setIsPreloader(true);
    const input = document.querySelector('.modal-window-action__modal__qa-group__input');
    await QuestionAndAnswerServices.putQuestionAnswerGroup({
      id: group.id,
      name: input.value,
    });
    setIsPreloader(false);
    closeModal();
  };

  return (
    <div
      className="modal-window-action"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__home" style={{ width: '500px' }}>
        {isPreloader && (
          <div className="modal-window-action__modal__home__loading">
            <Preloader />
          </div>
        )}
        <div className="modal-window-action__modal__title">
          <span>Q&A Group</span>
          <button onClick={closeModal}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10.5981 10.5977L21.4027 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M21.4023 10.5977L10.5978 21.4022"
                stroke="#25A8CF"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
          </button>
        </div>
        <div className="modal-window-action__modal__qa-group">
          <input
            onFocus={setDefaultBorder}
            className="modal-window-action__modal__qa-group__input"
            type="text"
            placeholder="group name"
            defaultValue={group?.name || ''}
          />
        </div>
        <div className="modal-window-action__modal__button">
          <button
            className="section-btn-gradient"
            onClick={group === null ? addGroup : updateGroup}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

function AdminQuestionAndAnswer() {
  const dispatch = useDispatch();
  const [questionAndAnswer, setQuestionAndAnswer] = useState(null);

  const [isAddGroup, setIsAddGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(null);

  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const [editquestionAnswer, isEditQuestionAndAnswer] = useState(null);

  const [isPagePreloader, setIsPagePreloader] = useState(false);

  const getData = async () => {
    setIsPagePreloader(true);
    const response = await QuestionAndAnswerServices.getQuestionAnswerGroup();
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
      setQuestionAndAnswer([]);
    } else {
      setQuestionAndAnswer(response);
    }
    setIsPagePreloader(false);
  };

  const isOpenQA = (e) => {
    setIsAddQuestion(true);
    isEditQuestionAndAnswer({ gropId: e.target.dataset.id });
  };

  const deleteGroup = async (e) => {
    setIsPagePreloader(true);
    const response = await QuestionAndAnswerServices.deleteQuestionAnswerGroup(e.target.dataset.id);
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
      setIsPagePreloader(false);
    } else {
      getData();
    }
  };

  const deleteQuestionAnswer = async (e) => {
    setIsPagePreloader(true);
    const response = await QuestionAndAnswerServices.deleteQuestionAnswer(e.target.dataset.id);
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
      setIsPagePreloader(false);
    } else {
      getData();
    }
  };

  const openUpdateGroup = (e) => {
    setEditGroup(questionAndAnswer.find((item) => item.id === e.target.dataset.id));
    setIsAddGroup(true);
  };

  const openUpdateQA = (e) => {
    const result = questionAndAnswer
      .find((item) => item.id === e.target.dataset.group)
      .questionAnswers.find((item) => item.id === e.target.dataset.id);
    result['gropId'] = e.target.dataset.group;
    isEditQuestionAndAnswer(result);
    setIsAddQuestion(true);
  };

  useEffect(() => {
    if (!isAddGroup && !isAddQuestion) {
      getData();
      setEditGroup(null);
      isEditQuestionAndAnswer(null);
    }
  }, [isAddGroup, isAddQuestion]);

  return (
    <>
      {isAddQuestion && (
        <ModalQuestionAndAnswer isOpen={setIsAddQuestion} qa={editquestionAnswer} />
      )}
      {isAddGroup && <ModalQuestionAndAnswerGroup isOpen={setIsAddGroup} group={editGroup} />}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={() => setIsAddGroup(true)}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
              <path
                d="M7.63989 0.859863V16.1399"
                stroke="#FFF9F9"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
            <span>Add Group</span>
          </button>
        </div>
        <div className="page-section__content__list-section">
          {isPagePreloader && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          <div className="page-section__content__groups">
            {questionAndAnswer?.length === 0 ? (
              <h2 className="page-section__content__groups__empty">No Q&A</h2>
            ) : (
              <>
                {questionAndAnswer?.map((group, index) => (
                  <div key={index} className="page-section__content__group">
                    <div className="page-section__content__group__header">
                      <div className="page-section__content__group__header__name">{group.name}</div>
                      <div className="page-section__content__group__header__action">
                        <button data-id={group?.id} onClick={isOpenQA}>
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 8.5H15.28"
                              stroke="#25A8CF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M7.63989 0.859863V16.1399"
                              stroke="#25A8CF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                            />
                          </svg>
                        </button>
                        <button
                          data-id={group?.id}
                          className="page-section__content__group-action__edit"
                          onClick={openUpdateGroup}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_653_105)">
                              <path
                                d="M6.18386 16.8721L2.35986 17.6721L3.15986 13.8481L14.7119 2.2321C14.9123 2.03121 15.1505 1.87195 15.4127 1.76349C15.675 1.65502 15.9561 1.59949 16.2399 1.6001C16.8127 1.6001 17.3621 1.82767 17.7672 2.23275C18.1723 2.63783 18.3999 3.18723 18.3999 3.7601C18.4005 4.04389 18.3449 4.32499 18.2365 4.58723C18.128 4.84947 17.9688 5.08766 17.7679 5.2881L6.18386 16.8721Z"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M0.839844 18.3999H19.1598"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M16.1119 6.9437L13.0559 3.8877"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_653_105">
                                <rect
                                  width="19.2"
                                  height="19.2"
                                  fill="white"
                                  transform="translate(0.399902 0.399902)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                        <button data-id={group?.id} onClick={deleteGroup}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_653_112)">
                              <path
                                d="M13.9041 18.3998H6.09606C5.70642 18.4003 5.33145 18.2512 5.04858 17.9832C4.76572 17.7152 4.59657 17.3489 4.57606 16.9598L3.88806 4.65576H16.1121L15.4241 16.9598C15.4036 17.3489 15.2344 17.7152 14.9515 17.9832C14.6687 18.2512 14.2937 18.4003 13.9041 18.3998Z"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M2.35999 4.65576H17.64"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M8.47197 1.6001H11.528C11.9332 1.6001 12.3219 1.76108 12.6084 2.04764C12.895 2.33419 13.056 2.72285 13.056 3.1281V4.6561H6.94397V3.1281C6.94397 2.72285 7.10495 2.33419 7.39151 2.04764C7.67807 1.76108 8.06672 1.6001 8.47197 1.6001Z"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M10 6.94385V16.1118"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M13.056 6.94385V16.1118"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                              <path
                                d="M6.94397 6.94385V16.1118"
                                stroke="#25A8CF"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_653_112">
                                <rect
                                  width="19.2"
                                  height="19.2"
                                  fill="white"
                                  transform="translate(0.400024 0.399902)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="page-section__content__group__content">
                      {group?.questionAnswers?.length === 0 ? (
                        <div className="page-section__content__group__content__empty">
                          Question group is empty
                        </div>
                      ) : (
                        <ul className="page-section__content__group__content__list">
                          {group?.questionAnswers.map((questionAnswer, index) => (
                            <li key={index}>
                              <div className="group__content__list__info">
                                Q: {questionAnswer.question}
                              </div>
                              <div className="group__content__list__action">
                                <button
                                  data-group={group?.id}
                                  data-id={questionAnswer.id}
                                  onClick={openUpdateQA}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_653_105)">
                                      <path
                                        d="M6.18386 16.8721L2.35986 17.6721L3.15986 13.8481L14.7119 2.2321C14.9123 2.03121 15.1505 1.87195 15.4127 1.76349C15.675 1.65502 15.9561 1.59949 16.2399 1.6001C16.8127 1.6001 17.3621 1.82767 17.7672 2.23275C18.1723 2.63783 18.3999 3.18723 18.3999 3.7601C18.4005 4.04389 18.3449 4.32499 18.2365 4.58723C18.128 4.84947 17.9688 5.08766 17.7679 5.2881L6.18386 16.8721Z"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M0.839844 18.3999H19.1598"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M16.1119 6.9437L13.0559 3.8877"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_653_105">
                                        <rect
                                          width="19.2"
                                          height="19.2"
                                          fill="white"
                                          transform="translate(0.399902 0.399902)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                <button data-id={questionAnswer.id} onClick={deleteQuestionAnswer}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_653_112)">
                                      <path
                                        d="M13.9041 18.3998H6.09606C5.70642 18.4003 5.33145 18.2512 5.04858 17.9832C4.76572 17.7152 4.59657 17.3489 4.57606 16.9598L3.88806 4.65576H16.1121L15.4241 16.9598C15.4036 17.3489 15.2344 17.7152 14.9515 17.9832C14.6687 18.2512 14.2937 18.4003 13.9041 18.3998Z"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M2.35999 4.65576H17.64"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M8.47197 1.6001H11.528C11.9332 1.6001 12.3219 1.76108 12.6084 2.04764C12.895 2.33419 13.056 2.72285 13.056 3.1281V4.6561H6.94397V3.1281C6.94397 2.72285 7.10495 2.33419 7.39151 2.04764C7.67807 1.76108 8.06672 1.6001 8.47197 1.6001Z"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M10 6.94385V16.1118"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M13.056 6.94385V16.1118"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M6.94397 6.94385V16.1118"
                                        stroke="#25A8CF"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_653_112">
                                        <rect
                                          width="19.2"
                                          height="19.2"
                                          fill="white"
                                          transform="translate(0.400024 0.399902)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminQuestionAndAnswer;

import React, { useEffect, useState } from 'react';

import CopiedIcon from '../../../../../general/assets/svg/CopiedIcon';
import NotCopiedIcon from '../../../../../general/assets/svg/NotCopiedIcon';

interface CopieProps {
  index: number;
  copiedIndexes: number[];
  lastCopiedIndex: number | null;
}

const Copie: React.FC<CopieProps> = ({ index, copiedIndexes, lastCopiedIndex }) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (copiedIndexes.includes(index)) {
      setFade(false);
      setTimeout(() => setFade(true), 100);
    }
  }, [copiedIndexes, index]);

  return (
    <div className="copie">
      {copiedIndexes.includes(index) ? (
        <>
          <span className={`copied-text ${lastCopiedIndex === index && fade ? 'fade-in' : ''}`}>
            copied
          </span>
          <CopiedIcon width="24" height="24" viewBox="0 0 24 24" />
        </>
      ) : (
        <NotCopiedIcon />
      )}
    </div>
  );
};

export default Copie;

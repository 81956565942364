import { adminInstance } from '../../../general/services/main/axiosInstances';

export const ProductCategoriesService = {
  // GET
  async getProductsCategories() {
    try {
      const response = await adminInstance.get(`/Category`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getMainGeneralNames(id: string) {
    try {
      const response = await adminInstance.get(
        `/Characteristics/mainGeneralNames?CategoryId=${id}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getMainGeneralOfProduct(id: string) {
    try {
      const response = await adminInstance.get(
        `/Characteristics/mainGeneralOfProduct?ProductId=${id}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postProductCategory(image: FormData, category: string, minig: boolean) {
    try {
      const response = await adminInstance.post(
        `/Category?Description=${'1'}&Name=${category}&AreMiningCharacteristics=${minig}`,
        image,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postMainGeneralToCategory(data: any) {
    try {
      const response = await adminInstance.post(`/Characteristics/mainGeneralToCategory`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  // PUT

  async putProductCategory(categoryId: string, category: string) {
    const data = {
      id: categoryId,
      description: ' ',
      name: category,
    };

    try {
      const response = await adminInstance.put(`/Category`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putProductCategoryImage(categoryId: string, image: FormData) {
    try {
      const response = await adminInstance.post(
        `/Category/updateImage?CategoryId=${categoryId}`,
        image,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteProductCategory(categoryId: string) {
    try {
      const response = await adminInstance.delete(`/Category`, {
        data: { id: categoryId },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteMainGeneralCategoryCharacteristic(id: string) {
    try {
      const response = await adminInstance.delete(`/Characteristics/mainGeneralInCategory`, {
        data: { generalMainCharacteristicDefinitionId: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

import React from 'react';

const DropDownProfileIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99414 9L11.9941 17L19.9941 9"
      stroke="#746A81"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DropDownProfileIcon;

import './Unsubscribe.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useCustomMutation from '../../../general/hooks/useCustomMutation';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import createClientService from '../../../general/services/client';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { regexp } from '../../../general/utils/Validations';

const Unsubscribe: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [isEmailNotValid, setIsEmailNotValid] = useState<boolean>(false);

  const { mutate: unsubscribeClient } = useCustomMutation(
    createClientService(clientInstance).unsubscribeClient,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Successfully unsubscribed' },
          }),
        );
        navigate('/');
      },
      onError: () => {
        dispatch(setType(types.ERROR));
        dispatch(
          setInfo({
            n: types.ERROR,
            r: { s: 'Ooops... smth wrong' },
          }),
        );
        navigate('/');
      },
    },
  );

  const handleUnsubscribe = async () => {
    const isValidEmail = regexp.email_regexp.test(email);

    if (isValidEmail) {
      unsubscribeClient({ email });
    } else {
      setIsEmailNotValid(true);

      setTimeout(() => {
        setIsEmailNotValid(false);
      }, 1000);
    }
  };

  return (
    <div className="unsubscribe-container">
      <h2>Unsubscribe</h2>
      <p>Enter your email to unsubscribe</p>
      <div className={isEmailNotValid ? 'unsubscribe-form not-valid-email' : 'unsubscribe-form'}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={isEmailNotValid ? 'Email is not valid' : 'Enter your email'}
          required
        />
        <button onClick={handleUnsubscribe} className="section-btn-gradient" type="submit">
          Unsubscribe
        </button>
      </div>
    </div>
  );
};

export default Unsubscribe;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { clientLogin, clientWishlist } from '../redux/reducers/ClientProfile-Reducer';
import { setInfo, setType, types } from '../redux/reducers/Error-Reducer';
import createAuthorisationService from '../services/authorisation';
import createClientService from '../services/client';
import { clientInstance } from '../services/main/axiosInstances';
import useCustomMutation from './useCustomMutation';

const useGoogleCredentials = (callback?: () => void) => {
  const dispatch = useDispatch();

  const { mutate: setGoogleCredentials } = useCustomMutation(
    createAuthorisationService(clientInstance).postGoogleCredentials,
    undefined,
    {
      onSuccess: async (data: any) => {
        if (callback) {
          callback();
        }

        dispatch(clientLogin(data.userId));

        const response: any = await createClientService(clientInstance).getClienWishList(
          data.userId,
        );
        dispatch(clientWishlist(response.data));
      },
      onError: (error) => {
        dispatch(setType(types.ERROR));
        dispatch(setInfo({ n: types.ERROR, r: { s: `Login Failed. ${error}` } }));
      },
    },
  );

  const handleGoogleCredentials = useCallback(
    (credential: { credential: string; referralCode?: string }) => {
      setGoogleCredentials(credential);
    },
    [setGoogleCredentials],
  );

  return handleGoogleCredentials;
};

export default useGoogleCredentials;

import './AdminContacts.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../general/components/preloader/Preloader';
import useRequired from '../../../general/hooks/useRequired';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import {
  errorMessages,
  regexp,
  validateFields,
  validatePhoneNumber,
} from '../../../general/utils/Validations';
import { ContactsService } from '../../service/ContactsServices';
import { ShippoServices } from '../../service/ShippoServices';

const initializeField = (name, value = '') => ({ name, value });

const initializeWebContacts = (response = {}) => [
  initializeField('Address', response.addressWeb),
  initializeField('Phone', response.phoneWeb),
  initializeField('Email', response.emailWeb),
];

const initializeLinks = (response = {}) => [
  initializeField('Telegram', response.telegramLink),
  initializeField('Facebook', response.facebookLink),
  initializeField('LinkedIn', response.linkedinLink),
  initializeField('Instagram', response.instagramLink),
  initializeField('X (Twitter)', response.xLink),
  initializeField('WhatsApp', response.whatsappLink),
];

const initializeAddress = (response = {}) => [
  initializeField('Name*', response.name),
  initializeField('Company*', response.company),
  initializeField('Country*', response.country),
  initializeField('City*', response.city),
  initializeField('State*', response.state),
  initializeField('Street number', response.streetNumber),
  initializeField('Zip*', response.zip),
  initializeField('Street 1*', response.street1),
  initializeField('Street 2', response.street2),
  initializeField('Street 3', response.street3),
  initializeField('Phone*', response.phone),
  initializeField('Email*', response.email),
  initializeField('Additional info', response.metadata),
];

const handleChange = (index, value, setState) => {
  setState((prevState) => {
    const updatedState = [...prevState];
    updatedState[index].value = value;
    return updatedState;
  });
};

const handleChangeAddress = (index, value, setAddress) => handleChange(index, value, setAddress);
const handleChangeLinks = (index, value, setLinks) => handleChange(index, value, setLinks);

const hasNonEmptyShippingField = (inputs) =>
  [
    inputs[9],
    inputs[11],
    inputs[12],
    inputs[13],
    inputs[14],
    inputs[15],
    inputs[16],
    inputs[17],
    inputs[18],
    inputs[19],
    inputs[20],
    inputs[21],
  ].some((input) => input.value.trim() !== '');

const validateInputs = (inputs, setError) => {
  const required = [];
  const fieldsConfig = [
    {
      input: inputs[12],
      regex: regexp.string,
      errorField: 'name',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[13],
      regex: regexp.string,
      errorField: 'company',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[18],
      regex: regexp.string,
      errorField: 'street1',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[19],
      regex: regexp.string,
      errorField: 'street2',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[20],
      regex: regexp.string,
      errorField: 'street3',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[16],
      regex: regexp.string,
      errorField: 'streetNumber',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[9],
      regex: regexp.string,
      errorField: 'country',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[11],
      regex: regexp.string,
      errorField: 'state',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[10],
      regex: regexp.string,
      errorField: 'city',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[17],
      regex: regexp.numbers,
      errorField: 'zip',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[14],
      validateFunc: validatePhoneNumber,
      errorField: 'phone',
    },
    {
      input: inputs[15],
      regex: regexp.email_regexp,
      errorField: 'email',
      errorMessage: errorMessages[1],
    },
  ];
  validateFields(fieldsConfig, required, setError);
  return !required.includes(false);
};

const validateAddressWithShippo = async (address, dispatch, setIsPreloader) => {
  const addressFromResp = await ShippoServices.ValidateAddress(address);

  if (addressFromResp.name === 'AxiosError') {
    setIsPreloader(false);
    dispatch(setType(types.ERROR));
    dispatch(
      setInfo({
        n: addressFromResp.message,
        r: addressFromResp.hasOwnProperty('response')
          ? {
              d: addressFromResp.response.data,
              s: addressFromResp.response.statusText,
            }
          : '',
      }),
    );
    return false;
  }

  const getValidationErrors = () =>
    addressFromResp.messages.map((message, index) => `${index + 1}. ${message.text}`).join('\n');

  if (!addressFromResp.isValid || addressFromResp.isValid === null) {
    setIsPreloader(false);
    const errors =
      addressFromResp?.messages.length > 0
        ? getValidationErrors()
        : 'Company address not correct. Please check its correctness';
    dispatch(setType(types.ERROR));
    dispatch(setInfo({ n: types.ERROR, r: { s: errors } }));
    return false;
  }
  return true;
};

const AdminContacts = () => {
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const [isPreloader, setIsPreloader] = useState(false);
  const { errors, setError } = useRequired();
  const [webContacts, setWebContacts] = useState(initializeWebContacts());
  const [address, setAddress] = useState(initializeAddress());
  const [links, setLinks] = useState(initializeLinks());

  const getData = useCallback(async () => {
    setIsPreloader(true);
    const response = await ContactsService.getContacts();
    if (response.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? {
                d: response.response.data,
                s: response.response.statusText,
              }
            : '',
        }),
      );
    } else {
      setLinks(initializeLinks(response.data));
      setWebContacts(initializeWebContacts(response.data));
      response.address !== null && setAddress(initializeAddress(response.data.address));
    }
    setIsPreloader(false);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const saveContacts = async () => {
    //setIsPreloader(true);
    const inputs = document.querySelectorAll('input');

    const result = {
      addressWeb: inputs[0].value.trim(),
      phoneWeb: inputs[1].value.trim(),
      emailWeb: inputs[2].value.trim(),
      telegramLink: inputs[3].value.trim(),
      facebookLink: inputs[4].value.trim(),
      linkedinLink: inputs[5].value.trim(),
      instagramLink: inputs[6].value.trim(),
      xLink: inputs[7].value.trim(),
      whatsappLink: inputs[8].value.trim(),
    };

    if (hasNonEmptyShippingField(inputs) && !validateInputs(inputs, setError)) {
      setIsPreloader(false);
      return;
    }

    const address = {
      address: {
        name: inputs[12].value.trim(),
        lastName: '',
        company: inputs[13].value.trim(),
        street1: inputs[18].value.trim(),
        street2: inputs[19].value.trim() !== '' ? inputs[19].value.trim() : null,
        street3: inputs[20].value.trim() !== '' ? inputs[20].value.trim() : null,
        streetNumber: inputs[16].value.trim() !== '' ? inputs[16].value.trim() : null,
        city: inputs[10].value.trim(),
        state: inputs[11].value.trim(),
        zip: inputs[17].value.trim(),
        country: inputs[9].value.trim(),
        phone: inputs[14].value.trim(),
        email: inputs[15].value.trim(),
        metadata: inputs[21].value.trim() !== '' ? inputs[21].value.trim() : null,
      },
    };

    result.address = address.address;
    if (inputs[13].value.trim() !== '') result.address.company = inputs[13].value.trim();
    if (inputs[14].value.trim() !== '') result.address.phone = inputs[14].value.trim();
    if (inputs[15].value.trim() !== '') result.address.email = inputs[15].value.trim();
    if (inputs[19].value.trim() !== '') result.address.street2 = inputs[19].value.trim();
    if (inputs[20].value.trim() !== '') result.address.street3 = inputs[20].value.trim();
    if (inputs[16].value.trim() !== '') result.address.streetNumber = inputs[16].value.trim();

    if (
      hasNonEmptyShippingField(inputs) &&
      !(await validateAddressWithShippo(address, dispatch, setIsPreloader))
    ) {
      setIsPreloader(false);
      return;
    }

    await ContactsService.postContacts({ contacts: result });

    dispatch(setType(types.SUCCESS));
    dispatch(
      setInfo({
        n: types.SUCCESS,
        r: { s: 'Contacts successfully saved' },
      }),
    );

    scrollTo('main-info');
    getData();
  };

  useEffect(() => {
    for (const key in errors) {
      if (errors[key] !== '') alert(`${key}: ${errors[key]}`);
    }
  }, [errors]);

  const setDefaultBorder = (e) => {
    e.target.style.border = '1px solid #25A8CF';
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">CONTACTS</div>
      </div>
      <div className="page-section__content">
        {isPreloader && (
          <div className="page-update-overlay">
            <Preloader />
          </div>
        )}
        <div className="page-section__contacts">
          <div id="main-info" className="page-section__contacts__header">
            <span>Website contacts information</span>
          </div>
          <div className="page-section__contacts__body">
            <div className="address-block">
              {webContacts?.map(({ name, value }, index) => (
                <div key={index} className="page-section__contacts__input-block">
                  <label>{name}</label>
                  <input
                    onFocus={setDefaultBorder}
                    type="text"
                    placeholder={name}
                    defaultValue={value}
                    onChange={(e) => handleChangeAddress(index, e.target.value, setWebContacts)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="page-section__contacts">
          <div className="page-section__contacts__header">
            <span>Social information</span>
          </div>
          <div className="page-section__contacts__body">
            {links?.map(({ name, value }, index) => (
              <div key={index} className="page-section__contacts__input-block">
                <label>{name}</label>
                <input
                  onFocus={setDefaultBorder}
                  type="text"
                  placeholder="Link"
                  defaultValue={value}
                  onChange={(e) => handleChangeLinks(index, e.target.value, setLinks)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="page-section__contacts">
          <div className="page-section__contacts__header">
            <span>Shipping information</span>
          </div>
          <div className="page-section__contacts__body">
            <div className="address-block">
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 2 || index === 3 || index === 4) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              {address?.map(({ name, value }, index) => {
                if (index === 0 || index === 1 || index === 10 || index === 11) {
                  return (
                    <div key={index} className="page-section__contacts__input-block">
                      <label>{name}</label>
                      <input
                        onFocus={setDefaultBorder}
                        type="text"
                        placeholder={name}
                        defaultValue={value}
                        onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                      />
                    </div>
                  );
                } else return null;
              })}
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 5 || index === 6) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 7 || index === 8 || index === 9) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              <div className="page-section__contacts__input-block">
                <label>{address[address.length - 1].name}</label>
                <input
                  onFocus={setDefaultBorder}
                  type="text"
                  placeholder={address[address.length - 1].name}
                  defaultValue={address[address.length - 1].value}
                  onChange={(e) =>
                    handleChangeAddress(address.length - 1, e.target.value, setAddress)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section__contacts-btn">
          <button
            className="section-btn-gradient"
            onClick={() => {
              saveContacts();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminContacts;

import { AxiosInstance } from 'axios';

interface AboutUsService {
  getAllSliderImages: () => Promise<any>;
  createImage: (image: File) => Promise<any>;
  deleteImage: (id: string) => Promise<any>;
}

const createAboutUsService = (instance: AxiosInstance): AboutUsService => ({
  async deleteImage(id: string) {
    try {
      const response = await instance.delete(`/AboutUs/remove?id=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post('/AboutUs/create', formData, {
        headers,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAllSliderImages() {
    try {
      const responce = await instance.get('/AboutUs/getAll');
      return responce;
    } catch (error) {
      return error;
    }
  },
});

export default createAboutUsService;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StaffView } from '../../types/types';

interface SliderImage {
  id: string;
  url: string;
}

interface Testimonial {
  id: string;
  message: string;
}

interface InfoBlock {
  id: string;
  title: string;
}

interface Partner {
  id: string;
  name: string;
}

interface ShowCaseItem {
  id: string;
  title: string;
}

interface StaticHomeState {
  staff: StaffView[];
  staffToEdit: StaffView | null;
  aboutUsSliderImages: SliderImage[];
  testimonials: Testimonial[];
  homeVideoUrl: string;
  infoBloks: InfoBlock[];
  companyParters: Partner[];
  companyParterIdToEdit: string | null;
  showCase: ShowCaseItem[];
}

const initialState: StaticHomeState = {
  staff: [],
  staffToEdit: null,
  aboutUsSliderImages: [],
  testimonials: [],
  homeVideoUrl: '',
  infoBloks: [],
  companyParters: [],
  companyParterIdToEdit: null,
  showCase: [],
};

const StaticHomeSlice = createSlice({
  name: 'homeStatic',
  initialState,
  reducers: {
    setStaffToEdit: (state, action: PayloadAction<StaffView | null>) => {
      state.staffToEdit = action.payload;
    },
    addToSliderImages: (state, action: PayloadAction<SliderImage>) => {
      state.aboutUsSliderImages.push(action.payload);
    },
    deleteSliderImageById: (state, action: PayloadAction<string>) => {
      state.aboutUsSliderImages = state.aboutUsSliderImages.filter(
        (item) => item.id !== action.payload,
      );
    },
    setPartnerIdToEdit: (state, action: PayloadAction<string | null>) => {
      state.companyParterIdToEdit = action.payload;
    },
  },
});

export const { setStaffToEdit, addToSliderImages, deleteSliderImageById, setPartnerIdToEdit } =
  StaticHomeSlice.actions;
export default StaticHomeSlice.reducer;

import React, { ChangeEvent } from 'react';
import './ContactForm.scss';

interface InputComponentProps {
  type?: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const InputComponent: React.FC<InputComponentProps> = ({
  type = 'text',
  placeholder,
  onChange,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className="content-form__input"
      onChange={onChange}
    />
  );
};

export default InputComponent;

import React from 'react';

interface XLinkIconProps {
  width?: string | number;
  height?: string | number;
  viewBox?: string;
}

const XLinkIcon: React.FC<XLinkIconProps> = ({ width, height, viewBox }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.193848L7.70067 11.4438L0.163102 20.1938H1.8331L8.43822 12.5212L13.6903 20.1938H20L11.9547 8.44118L19.0542 0.193848H17.3893L11.2161 7.36079L6.30969 0.193848H0ZM1.99415 1.24648H5.75678L18.0058 19.1412H14.2432L1.99415 1.24648Z"
      fill="white"
    />
  </svg>
);

export default XLinkIcon;

import React, { useEffect, useRef } from 'react';

interface CustomShapeImageProps {
  src: string;
  width: number;
  height: number;
  cutoutWidth: number; // Ширина выреза
  cutoutHeight: number; // Высота выреза
  radius: number;
}

const CustomShapeImage: React.FC<CustomShapeImageProps> = ({
  src,
  width,
  height,
  cutoutWidth,
  cutoutHeight,
  radius,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const image = new Image();

    image.src = src;

    image.onload = () => {
      // Очищаем canvas
      context.clearRect(0, 0, width, height);

      context.beginPath();
      // Верхний левый угол
      context.moveTo(radius, 0);
      context.arcTo(0, 0, 0, radius, radius);

      // Левый нижний угол
      context.lineTo(0, height - radius);
      context.arcTo(0, height, radius, height, radius);

      // Переход к внутреннему вырезу
      context.lineTo(width - cutoutWidth - radius, height);
      context.arcTo(width - cutoutWidth, height, width - cutoutWidth, height - radius, radius);

      // Внутренний угол выреза
      context.lineTo(width - cutoutWidth, height - cutoutHeight + radius);
      context.arcTo(
        width - cutoutWidth,
        height - cutoutHeight,
        width - cutoutWidth + radius,
        height - cutoutHeight,
        radius,
      );

      // Правая сторона
      context.lineTo(width - radius, height - cutoutHeight);
      context.arcTo(width, height - cutoutHeight, width, height - cutoutHeight - radius, radius);

      // Верхний правый угол
      context.lineTo(width, radius);
      context.arcTo(width, 0, width - radius, 0, radius);

      context.closePath();
      context.clip();

      // Рисуем изображение
      context.drawImage(image, 0, 0, width, height);
    };
  }, [src, width, height, cutoutWidth, cutoutHeight, radius]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default CustomShapeImage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IHostingLocation } from '../../types/types';

export interface HostingLocationsState {
  hostingToEdit: IHostingLocation | null;
  hostingToDelete: IHostingLocation | null;
}

const initialState: HostingLocationsState = {
  hostingToEdit: null,
  hostingToDelete: null,
};

const HostingSlice = createSlice({
  name: 'hosting',
  initialState,
  reducers: {
    setHostingToEdit: (state, action: PayloadAction<IHostingLocation | null>) => {
      state.hostingToEdit = action.payload;
    },
    hostingToDelete: (state, action: PayloadAction<IHostingLocation | null>) => {
      state.hostingToDelete = action.payload;
    },
  },
});

export const { setHostingToEdit, hostingToDelete } = HostingSlice.actions;
export default HostingSlice.reducer;

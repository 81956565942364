import React from 'react';

const StarIcon: React.FC = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1534 1.94767C11.8365 0.305088 14.1635 0.305085 14.8466 1.94767L16.7629 6.55497C17.0509 7.24744 17.7022 7.72058 18.4497 7.78051L23.4237 8.17927C25.197 8.32144 25.9161 10.5345 24.565 11.6918L20.7754 14.938C20.2058 15.4259 19.957 16.1915 20.131 16.921L21.2888 21.7747C21.7016 23.5052 19.8191 24.8729 18.3009 23.9456L14.0425 21.3446C13.4025 20.9536 12.5975 20.9536 11.9575 21.3446L7.69908 23.9456C6.18088 24.8729 4.29838 23.5052 4.71115 21.7747L5.86895 16.921C6.04297 16.1915 5.79422 15.4259 5.22465 14.938L1.43501 11.6918C0.083936 10.5345 0.802986 8.32144 2.57629 8.17927L7.55026 7.78051C8.29784 7.72058 8.94906 7.24744 9.23707 6.55497L11.1534 1.94767Z"
      fill="#FBC230"
    />
  </svg>
);

export default StarIcon;

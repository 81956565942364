import React from 'react';

const LogoIcon: React.FC = () => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_135_1416)">
      <path
        d="M16.1221 17.2935L15.2442 15.692L2.71044 15.6877L10.4793 1.52531L9.47007 0L0 17.2999H1.82616L1.82647 17.2993L16.1221 17.2935Z"
        fill="#25A8CF"
      />
      <path
        d="M4.80847 14.7361L6.63463 14.7199L13.0023 3.15059L20.72 17.0667H22.5041L12.8633 0L4.80847 14.7361Z"
        fill="#25A8CF"
      />
      <path
        d="M2.59674 19.9933L3.47462 18.3921L17.9221 18.3878L11.9757 7.35191L12.9849 5.8266L20.6325 20H18.8063L18.806 19.9994L2.59674 19.9933Z"
        fill="#25A8CF"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_1416">
        <rect width="22.5041" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;

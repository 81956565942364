import { adminInstance } from '../../../general/services/main/axiosInstances';

export const ProductService = {
  // GET
  async getAllProducts(search: string, pageSize: number, page: number) {
    try {
      const response = await adminInstance.get(
        `/Product/all?Name=${encodeURIComponent(search)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getEditProduct(id: string) {
    try {
      const response = await adminInstance.get(`Product/byIdAdmin?ProductId=${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getXLSXProducts() {
    try {
      const headers: any = {
        'Content-Type': 'blob',
        responseType: 'arraybuffer',
      };
      const response = await adminInstance.get(`/Product/xlsxProducts`, headers);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // GET
  async getXLSXProductsPrice() {
    try {
      const headers: any = {
        'Content-Type': 'blob',
        responseType: 'arraybuffer',
      };
      const response = await adminInstance.get(`/Product/xlsxPrices`, headers);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postProduct(data: any) {
    try {
      const response = await adminInstance.post(`/Product`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postAdditionalCharacteristic(data: any) {
    try {
      const response = await adminInstance.post(`/Characteristics/additional`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async updateImagesOredr(data: any) {
    try {
      const response = await adminInstance.put(`/Product/updateImagesOrder`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postImagesProduct(id: string, data: any) {
    try {
      const response = await adminInstance.post(`/Product/addImages?ProductId=${id}`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postXLSXProducts(data: any) {
    try {
      const headers: any = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await adminInstance.post(`/Product/xlsxProducts`, data, headers);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // POST
  async postCopyProducts(data: any) {
    try {
      const response = await adminInstance.post(`/Product/copyProduct`, {
        productId: data,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putXLSXProductsPrice(data: any) {
    try {
      const headers: any = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await adminInstance.put(`/Product/xlsxPrices`, data, headers);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putProductMainInformation(data: any) {
    try {
      const response = await adminInstance.put(`/Product`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putProductMarket(data: any) {
    try {
      const response = await adminInstance.put(`/Product/market`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putProductMainMiningCharacteristics(data: any) {
    try {
      const response = await adminInstance.put(`/Characteristics/mainMining`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // PUT
  async putProductCharacteristics(data: any) {
    try {
      const response = await adminInstance.put(`/Characteristics/additional`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  // PUT
  async putProductMainGeneralOfProduct(data: any) {
    try {
      const response = await adminInstance.put(`/Characteristics/updateMainGeneralOfProduct`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteProductCharacteristics(id: string) {
    try {
      const response = await adminInstance.delete(`/Characteristics/additional`, {
        data: { id: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteProduct(id: string) {
    try {
      const response = await adminInstance.delete(`/Product`, {
        data: { id: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  // DELETE
  async deleteProductImg(id: string) {
    try {
      const response = await adminInstance.delete(`/Product/removeImage`, {
        data: { imageId: id },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

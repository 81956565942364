import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const types = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
} as const;

type ErrorType = (typeof types)[keyof typeof types];

interface ErrorState {
  type: ErrorType;
  e: {
    n: string;
    r:
      | string
      | {
          d?: Record<string, any>;
          s: string[];
        };
  };
}

const initialState: ErrorState = {
  type: types.ERROR,
  e: { n: '', r: '' },
};

const ErrorSlice = createSlice({
  name: 'e6',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<ErrorType>) => {
      state.type = action.payload;
    },
    setInfo: (state, action: PayloadAction<{ n: string; r: any }>) => {
      const lowerize = (obj: Record<string, any>) =>
        Object.keys(obj).reduce(
          (acc, k) => {
            acc[k.toLowerCase()] = obj[k];
            return acc;
          },
          {} as Record<string, any>,
        );

      const { r, n } = action.payload;

      if (r !== '') {
        state.e = {
          n: n,
          r: {
            d: r.d && lowerize(r.d),
            s: (r.s instanceof Error ? r.s.message : r.s).split('\n'),
          },
        };
        return;
      }

      state.e = action.payload;
    },
    clearInfo: (state) => {
      state.e = { n: '', r: '' };
    },
  },
});

export const { setInfo, clearInfo, setType } = ErrorSlice.actions;
export default ErrorSlice.reducer;

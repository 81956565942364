import './Review.scss';

import React from 'react';

import ArrowLink from '../../../general/assets/svg/ArrowLink';
import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import StarIcon from '../../../general/assets/svg/StarIcon';

interface ReviewProps {
  image: string;
  name: string;
  country: string;
  rating: number;
  text: string;
}

const Review: React.FC<ReviewProps> = ({ image, name, country, rating, text }) => {
  const numberRating = parseInt(rating.toString(), 10);
  const arrayStars = Array.from({ length: numberRating }, (value, index) => index);

  return (
    <div className="review-container">
      <div className="review-container__content">
        <div className="review-container__stars">
          {arrayStars.map((index_star) => (
            <React.Fragment key={index_star}>
              <StarIcon />
            </React.Fragment>
          ))}
        </div>
        <p className="review-container__text">{text}</p>
      </div>
      <div className="review-container__user">
        <div className="review-container__user__username">
          <div className="review-container__user__username__avatar">
            <img src={image} alt="" />
          </div>
          <div className="review-container__user__username__name">{name}</div>
        </div>
        <div className="review-container__user__placement">
          <div className="review-container__user__placement__country">
            <LocationMarkerIcon fill="white" />
            <span>{country}</span>
          </div>
          <div className="review-container__user__placement__arrow">
            <ArrowLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;

import React from 'react';

const MailIcon: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1254_988)">
      <path
        d="M23.4067 4.67981H2.40674V19.9498H23.4067V4.67981Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2.40674 4.67981L12.9067 15.1798L23.4067 4.67981"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0467 12.3198L2.40674 19.9598"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.4067 19.9598L15.7667 12.3198"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.4067 4.67981H2.40674V19.9498H23.4067V4.67981Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1254_988">
        <rect width="24" height="24" fill="white" transform="translate(0.906738 0.319824)" />
      </clipPath>
    </defs>
  </svg>
);

export default MailIcon;

import * as Yup from 'yup';

interface TransactionStableCoinFormValues {
  reciept: string;
}

export const validationSchemaStableCoin = Yup.object({
  reciept: Yup.string()
    .matches(
      /^(https:\/\/[^\s]+|(0x)?[a-fA-F0-9]{64})$/,
      'Transaction ID / Link must be a valid hash or URL',
    )
    .required('Transaction ID / Link is required'),
});

export const initialValuesStableCoin: TransactionStableCoinFormValues = {
  reciept: '',
};

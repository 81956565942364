import React from 'react';

const PreShipmentTestingIcon: React.FC = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.2067 3.92578H11.4867L3.84668 17.2858V45.9258H45.8467V17.2858L38.2067 3.92578Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M15.3066 30.6458V40.2058" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M10.5264 35.4258L15.3064 30.6458L20.0664 35.4258"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M24.8467 3.92578V24.9258" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M3.84668 17.2859H45.8467" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default PreShipmentTestingIcon;
